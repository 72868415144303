import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingServiceService {

  constructor() { }

  private loader: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  private customLoader: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  private customLoaderValue: BehaviorSubject<number> = new BehaviorSubject(0);

  getLoader(): Observable<Boolean> {
    return this.loader.asObservable();
  }

  setLoader(load: Boolean) {
    this.loader.next(load);
  }

  getCustomLoader(): Observable<Boolean> {
    return this.customLoader.asObservable();
  }

  setCustomLoader(load: Boolean) {
    this.customLoader.next(load);
  }

  getCustomLoaderValue(): Observable<number> {
    return this.customLoaderValue.asObservable();
  }

  setCustomLoaderValue(load: number) {
    this.customLoaderValue.next(load);
  }
}
