import {
    NgModule,
  } from '@angular/core';
  import { CommonModule } from '@angular/common';
  
  import { ScrollingModule } from '@angular/cdk/scrolling';
  import { FormsModule, ReactiveFormsModule } from '@angular/forms';
  import { MatAutocompleteModule } from '@angular/material/autocomplete';
  import { MatFormFieldModule } from '@angular/material/form-field';
  import { MatIconModule } from '@angular/material/icon';
  import { MatSelectModule } from '@angular/material/select';
  import { MatInputModule } from '@angular/material/input';
  
  import { TranslateModule } from 'ng2-translate';
  import { TwCoreUIFormModule } from 'tw-core-ui';
  import { MatTooltipModule } from '@angular/material/tooltip';
  import { SpinnerSVGComponent } from '../spinner/spinner.component';
  
  @NgModule({
    declarations: [
      SpinnerSVGComponent,
    ],
    imports: [
      CommonModule,
      MatFormFieldModule,
      MatSelectModule,
      TwCoreUIFormModule,
      MatInputModule,
      FormsModule,
      ReactiveFormsModule,
      ScrollingModule,
      MatIconModule,
      MatSelectModule,
      MatFormFieldModule,
      TranslateModule.forRoot(),
      MatAutocompleteModule,
      MatTooltipModule,
    ],
    exports:[SpinnerSVGComponent]
  })
  export class SpinnerModule {}
  