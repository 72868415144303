import { Component, ViewChild, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { DatePickerDirective, IDatePickerDirectiveConfig } from 'ng2-date-picker';
// Satish: bad practice...please use specific import instead of all.
import { IDoesFilterPassParams, IFilterParams, RowNode, IAfterGuiAttachedParams } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import * as moment_ from 'moment-timezone';
import { CustomDatePicker } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { TwCoreUIUtilService } from 'tw-core-ui';
@Component({
  selector: 'app-custom-date-filter',
  templateUrl: './custom-date-filter.component.html',
  styleUrls: ['./custom-date-filter.component.less']

})
export class CustomDateFilterComponent implements IFilterAngularComp {
  @ViewChild('fromDatePicker') fromDatePicker: DatePickerDirective;
  @ViewChild('toDatePicker') toDatePicker: DatePickerDirective;
  currentMoment = moment_;

  public params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text = '';
  public customFilterObj;
  public fromDate: any;
  public toDate: any;
  public browserType;
  public gridHeaders = [];
  public lastClickTime = new Date().getTime();
  public doubleClicked = false;
  public toDateMin: any;
  public tominDate: any;
  frommaxDate: any;
  public fromDatePickerFilter: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'from_date',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'fromFilterDate',
    formControlName: 'fromFilterDate',
    isDisabled: false,
    isWeekendsDisabled: false
  };
  public toDatePickerFilter: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'to_date',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'toFilterDate',
    formControlName: 'toFilterDate',
    isDisabled: false,
    isWeekendsDisabled: false
  };
  toFilterDate: any;
  fromFilterDate: any;
  isfromDate: Boolean = false;
  istoDate: Boolean = false;
  clearFromDate = '';
  cleartoDate = '';
  showEndDate: Boolean = false;
  to_date;
  from_date;
  fromflag: Boolean = false;
  toflag: Boolean = false;
  datePickerConfig: IDatePickerDirectiveConfig = {
    format: 'DD MMM YYYY',
    weekDayFormat: 'dd',
    drops: 'down',
    dayBtnFormat: 'D',
    disableKeypress: true
  };
  selectedTimeZone = "Australia/Victoria";
  timeZoneList = [{ "name": "Australia/ACT", "stateCode": "ACT" }, { "name": "Australia/Adelaide", "stateCode": "SA" }, { "name": "Australia/NSW", "stateCode": "NSW" }, { "name": "Australia/North", "stateCode": "NT" }, { "name": "Australia/Queensland", "stateCode": "QLD" }, { "name": "Australia/Tasmania", "stateCode": "TAS" }, { "name": "Australia/Victoria", "stateCode": "VIC" }, { "name": "Australia/West", "stateCode": "WA" }, { "name": "Australia/Victoria", "stateCode": "NAT" }];
  fromDatePickerConfig = this.datePickerConfig;
  toDatePickerConfig = this.datePickerConfig;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    // Satish: this is incorrectly handled
    this.retainDateFilterValues(event);
  }

  retainDateFilterValues(event) {
    let clickedComponent = event.target;
    let insideFilter = false;
    let insideDatePicker = false;
    const startDateHeader = (document.getElementsByClassName('dropdown-menu dropdown-menu-datepicker show'));
    const endDateHeader = (document.getElementsByClassName('dropdown-menu dropdown-menu-datepicker show'));
    const openedFilterID = this.params.colDef.colId;
    const agColumnMenuVisible = document.getElementsByClassName('ag-column-menu-visible') as HTMLCollectionOf<HTMLElement>;
    this.browserType = this.detectIE();
    if (clickedComponent.className.search('btn-light') !== -1) {
      insideDatePicker = true;
    } else {
      do {
        if ((clickedComponent === this.elementRef.nativeElement) ||
          (clickedComponent === startDateHeader) ||
          (clickedComponent === endDateHeader)) {
          insideFilter = true;
        } else if (clickedComponent.className !== undefined) {
          if ((clickedComponent.className.search('btn-light') !== -1) ||
            (clickedComponent.className.search('ngb-dp-month d-block') !== -1)) {
            insideDatePicker = true;
          }
        }
        clickedComponent = clickedComponent.parentNode;
      } while (clickedComponent);
    }
    if ((this.browserType === 'IE11') || (this.browserType === 'Edge')) {
      if ((!insideFilter) && (!insideDatePicker)) {
        this.checkDoubleClickIE();
        setTimeout(() => {
          if (!this.doubleClicked) {
            this.clearFilter(event);
          }
        }, 0);
        if (agColumnMenuVisible.length !== 0) {
          const onScreenFilterID = agColumnMenuVisible[0].attributes['col-id'].nodeValue;
          if (openedFilterID === onScreenFilterID) {
            setTimeout(() => {
              this.adjustFilterPopupPosition(event);
            }, 0);
          }
        }
      } else if ((!insideFilter) && (insideDatePicker)) {
        if (agColumnMenuVisible.length !== 0) {
          const onScreenFilterID = agColumnMenuVisible[0].attributes['col-id'].nodeValue;
          if (openedFilterID === onScreenFilterID) {
            const agIconMenu = document.getElementsByClassName('ag-icon-menu') as HTMLCollectionOf<HTMLElement>;
            // clicking two times is adding back 'ag-column-menu-visible' class
            agIconMenu[this.gridHeaders.indexOf(this.params.colDef.colId.toString())].click();
            agIconMenu[this.gridHeaders.indexOf(this.params.colDef.colId.toString())].click();
          }
        }
      }
    } else {
      if ((!insideFilter) && (!insideDatePicker)) {
        this.clearFilter(event);
        if (agColumnMenuVisible.length !== 0) {
          const onScreenFilterID = agColumnMenuVisible[0].attributes['col-id'].nodeValue;
          if (openedFilterID === onScreenFilterID) {
            setTimeout(() => {
              this.adjustFilterPopupPosition(event);
            }, 0);
          }
        }
      }
    }
  }

  checkDoubleClickIE() {
    const currentTime = new Date().getTime();
    if ((currentTime - this.lastClickTime) <= 600) {
      this.doubleClicked = true;
    } else {
      this.doubleClicked = false;
    }
    this.lastClickTime = currentTime;
  }

  constructor(
    private elementRef: ElementRef,
    private util: TwCoreUIUtilService,
    private viewContainerRef: ViewContainerRef) { }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.customFilterObj = params.colDef;
    this.valueGetter = params.valueGetter;

    const agHeaderCell = document.getElementsByClassName('ag-header-cell') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < agHeaderCell.length; i++) {
      this.gridHeaders.push(agHeaderCell[i].attributes['col-id'].nodeValue);
    }
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params?: IDoesFilterPassParams): boolean {
    const dates = this.text.split('-');
    const dataDate = params.data[this.params.colDef.field]?.split(',');
    let colDate = dataDate;
    let tempDaylightDate = new Date(dates[0]);
    tempDaylightDate.setMonth(0);
    tempDaylightDate.setDate(1);
    this.timeZoneList.forEach(element => {
      let abbr = this.currentMoment().tz(element.name).format('z');
      let abbrDaylight = this.currentMoment(tempDaylightDate).tz(element.name).format('z');
      if (colDate && colDate.includes(abbr)) {
        colDate = colDate.replace(abbr, '');
      } else if (colDate && colDate.includes(abbrDaylight)) {
        colDate = colDate.replace(abbrDaylight, '');
      }
    });
    return this.compareDates(
      new Date(colDate),
      new Date(dates[0]),
      new Date(dates[1])
    ) ? this.valueGetter(params.node) : false;
  }

  compareDates(d1, d2, d3) {
    return d1 >= d2 && d3 >= d1 ? true : false;
  }

  onApplyFilters() {
    let focusableElement;
    focusableElement = document.getElementById('download_icon');
    if (focusableElement) {
      focusableElement.focus();
    }

    this.text = '';
    this.text = this.clearFromDate + ' - ' + this.cleartoDate;
    this.params.filterChangedCallback();
    document.getElementById('qa-banner').click();
    this.viewContainerRef
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainerRef.element.nativeElement);
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  clearFilter(flag) {
    const filterActive = this.isFilterActive();
    if (flag !== 'applyFilter' && !filterActive) {
      this.setModel('');
      this.fromDate = null;
      this.toDate = null;
      if (!this.fromFilterDate) {
        if (this.fromflag) {
          this.fromFilterDate = '';
        } else {
          this.fromFilterDate = null;
        }
        this.fromflag = !this.fromflag;
      }
      if (!this.toFilterDate) {
        if (this.toflag) {
          this.toFilterDate = '';
        } else {
          this.toFilterDate = null;
        }
        this.toflag = !this.toflag;
      }
    } else if (flag !== 'applyFilter' && filterActive) {
      const dates = this.text.split('-');
      this.fromDate = this.currentMoment(dates[0]);
      this.toDate = this.currentMoment(dates[1]);
    }
  }

  closeFilter(flag) {
    const disableDate = document.getElementById('tocustomFilterDate');
    this.clearFilter(flag);
    this.closedByKeyPress(flag);
    document.getElementById('qa-banner').click();
    this.viewContainerRef
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainerRef.element.nativeElement);
    if (!this.fromFilterDate) {
      disableDate.classList.add('disabledDate');
      if (this.fromflag) {
        this.fromFilterDate = '';
      } else {
        this.fromFilterDate = null;
      }
      this.fromflag = !this.fromflag;
    }
    if (!this.toFilterDate) {
      if (this.toflag) {
        this.toFilterDate = '';
      } else {
        this.toFilterDate = null;
      }
      this.toflag = !this.toflag;
    }
  }

  closedByKeyPress(flag) {
    if (flag === 'keypress') {
      const query = this.customFilterObj.colId;
      const element = (<HTMLElement>document.querySelector(`div[col-id=${query}] span.ag-icon.ag-icon-menu`));
      element.focus();
    }
  }

  onChangeFromDate($event) {
    const enableDate = document.getElementById('tocustomFilterDate');
    const fromDateSelected = $event;
    this.fromDate = $event.date;
    if (this.fromDate) {
      enableDate.classList.remove('disabledDate');
      this.isfromDate = true;
      const year = '';
      const month = '';
      const day = '';
      this.fromDate = this.fromDate.replace(/\//g, '-');
      this.fromDate = this.fromDate.split('-').reverse().join('-');
      this.fromDate = this.currentMoment(this.fromDate).format('DD MMM YYYY');
      this.clearFromDate = this.fromDate;
      this.fromDate = new Date(this.fromDate);
      const setMaxTodate = this.fromDate;
      this.toDatePickerConfig.max = this.currentMoment(setMaxTodate).toString();
      this.tominDate = {
        year: setMaxTodate.getFullYear(),
        month: setMaxTodate.getMonth() + 1,
        day: setMaxTodate.getDate()
      };
    }
  }

  onChangetoDate($event) {
    const toDateSelected = $event;
    this.toDate = $event.date;
    if (this.toDate) {
      this.istoDate = true;
      const year = '';
      const month = '';
      const day = '';
      const clearDate = this.toDate;
      this.toDate = this.toDate.replace(/\//g, '-');
      this.toDate = this.toDate.split('-').reverse().join('-');
      const time = '23:59:59';
      this.toDate = this.currentMoment(this.toDate + ' ' + time).format('DD MMM YYYY HH:mm:ss.s');
      this.cleartoDate = this.toDate;
      this.toDate = new Date(this.toDate);
      const setMinTodate = this.toDate;
      this.toDatePickerConfig.max = this.currentMoment(setMinTodate).toString();
      this.tominDate = {
        year: setMinTodate.getFullYear(),
        month: setMinTodate.getMonth() + 1,
        day: setMinTodate.getDate()
      };
      this.frommaxDate = {
        year: setMinTodate.getFullYear(),
        month: setMinTodate.getMonth() + 1,
        day: setMinTodate.getDate()
      };
    }
  }

  adjustFilterPopupPosition(event) {
    const browser = this.browserType;
    const agColumnMenuVisible = document.getElementsByClassName('ag-column-menu-visible') as HTMLCollectionOf<HTMLElement>;
    if (agColumnMenuVisible.length !== 0) {
      const onScreenFilterID = agColumnMenuVisible[0].attributes['col-id'].nodeValue;
      if (((this.gridHeaders.indexOf(onScreenFilterID)) + 1) === this.gridHeaders.length) {
        const customFilter = document.getElementsByClassName('custom-filter') as HTMLCollectionOf<HTMLElement>;
        const customArrowUp = document.getElementsByClassName('custom-arrow-up') as HTMLCollectionOf<HTMLElement>;
        if ((customFilter.length !== 0) && (customArrowUp.length !== 0)) {
          customFilter[0].style.left = '-125px';
          customFilter[0].style.top = '30px';
          customArrowUp[0].style.transform = 'translateX(815%) translateY(116%)';
        }
      } else {
        const customFilter = document.getElementsByClassName('custom-filter') as HTMLCollectionOf<HTMLElement>;
        const customArrowUp = document.getElementsByClassName('custom-arrow-up') as HTMLCollectionOf<HTMLElement>;
        if ((customFilter.length !== 0) && (customArrowUp.length !== 0)) {
          customFilter[0].style.left = '-147px';
          customFilter[0].style.top = '30px';
          customArrowUp[0].style.transform = 'translateX(-35%) translateY(116%)';
        }
      }
    }
  }

  detectIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return 'IE10';
    }
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return 'IE11';
    }
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return 'Edge';
    }
    // other browser
    return 'NotIE';
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.util.bringFocusToElement(window.event, 'from_date', false);
    this.istoDate = false;
  }
}
