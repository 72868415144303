import { Component, OnInit } from '@angular/core';
import { Title} from '@angular/platform-browser';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.less']
})
export class LoadingSpinnerComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Amplitel - Homepage');
   }

   public counter = 0;
   ngOnInit() {
     setInterval(() => {
       this.counter++;
       if (this.counter % 2 === 0) {
         this.flipSVG();
       } else {
         this.unflipSVG();
       }
     }, 500);
   }
   flipSVG() {
     if ((<HTMLElement>document.querySelector('#red-icon'))) {
       (<HTMLElement>document.querySelector('#red-icon')).style.fill = 'black';
     }
     if ((<HTMLElement>document.querySelector('#black-icon'))) {
       (<HTMLElement>document.querySelector('#black-icon')).style.fill = '#DC031B';
     }
   }
   unflipSVG() {
     if ((<HTMLElement>document.querySelector('#red-icon'))) {
       (<HTMLElement>document.querySelector('#red-icon')).style.fill = '#DC031B';
     }
     if ((<HTMLElement>document.querySelector('#black-icon'))) {
       (<HTMLElement>document.querySelector('#black-icon')).style.fill = 'black';
     }
   }
}
