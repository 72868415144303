import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { userManagerObj } from 'src/environments/environment';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.less']
})
export class LandingPageComponent implements OnInit {
 
  amsNonAccessableRoles = ['enquiryplusamsuser','ampliteladmin','ampliteloperation','billviewuser','amplitelbilladmin','amplitelother'];
  constructor(private router: Router,
    private titleService: Title,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
    this.titleService.setTitle('Amplitel - HomePage');
    const roles = (this.commonService.getroleDetails()).roleList;
    if (roles.length > 0) {
      if (!roles.some(item=>this.amsNonAccessableRoles.includes(item))) {
        this.router.navigateByUrl('/site-locator');
      }
    }
  }
  private userObj: any = userManagerObj;
  private env: any = environment;
  public logoutUrl = this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri
  public enquiryTopics = ['General Enquiries', 'Tower Ordering System Enquiries'];
  public userName = sessionStorage.getItem('contactName');
  public userEmail = sessionStorage.getItem('email');
  public isEnquirySelected = false;
  public roles;
  ngOnInit(): void {
    this.sharedService.focusOnElement('header_logo');
    window.scroll(0, 0);
    this.roles = (this.commonService.getroleDetails()).roleList;
  }

  focusToAMS() {
    this.sharedService.focusOnElement('tower-ams');
  }
  towercoAMS() {
    this.router.navigate([]).then(() => { window.open(this.env.amsUrl, '_blank'); });
  }
}