<div class="page-bg">
  <div class="row enquiry-section">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
      <!-- <div class="nav-header-portal">{{'GETINTOUCH'|translate}}</div> -->
    </div>
    <div
      class="col-xs-12 col-sm-12 col-md-8 col-lg-8"
      style="display: flex; justify-content: space-between"
    >
      <h1 class="head1" id="enquiryTopic">New Enquiry</h1>
      <span class="enquiry-form-mandatory-lbl"
        >Fields marked with <span class="aesterisk">*</span> are mandatory
      </span>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2"></div>
  </div>
  <div class="row enquiry-section form-section">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <hr class="line-6" />
      <div style="display: flex; align-items: center">
        <img
          class="images"
          src="../../assets/images/computer-image.png"
          alt=""
        />
        <span class="enquiry-form-mandatory-lbl">
          {{ "needHelp" | translate }}
        </span>
      </div>
      <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
        <div
          class="row"
          *ngIf="
            result !== 'buildSuit' &&
            this.result !== 'deploymentServices' &&
            (type === 'Towers' ||
              type === 'Poles' ||
              type === 'Landbanks' ||
              type === 'Exchanges' ||
              type === 'Rooftops')
          "
        >
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields display-grid"
          >
            <label for="siteRefId" class="" id="siteId"
              >Amplitel Site Reference</label
            >

            <input
              *ngIf="
                type === 'Towers' || type === 'Landbanks' || type === 'Rooftops'
              "
              type="text"
              formControlName="siteId"
              [(ngModel)]="siteId"
              name="siteId"
              aria-label="Amplitel Site Reference for Towers, Landbanks and Rooftops"
              disabled
            />
            <input
              *ngIf="type === 'Poles'"
              type="text"
              formControlName="siteId"
              [(ngModel)]="poleId"
              name="Poles"
              aria-label="Amplitel Site Reference for Poles"
              disabled
            />
            <input
              *ngIf="type === 'Exchanges' || (type === 'Landbanks' && !siteId)"
              type="text"
              formControlName="siteId"
              [(ngModel)]="addressId"
              name="Exchanges"
              aria-label="Amplitel Site Reference for Exchanges"
              disabled
            />
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields display-grid"
          >
            <label for="siteAddress" class="" id="siteAddress"
              >Site Address</label
            >

            <input
              type="text"
              formControlName="siteAddress"
              [(ngModel)]="siteAddress"
              name="Site Address"
              aria-label="Site Address"
              disabled
            />
          </div>
        </div>

        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
          >
            <label
              for="enquiryTopic"
              class="enquiry-form-lbl asteriskdropdown"
              id="enqTypeID"
              >Enquiry Type</label
            >
            <app-cui-searchable-dropdown
              #businessTypes
              [formControlName]="'businessTypes'"
              class="user-dropDown"
              name="businessTypes"
              [items]="categories"
              required
              (ngModelChange)="categoryChanged($event)"
              [model]="CategoryModel"
              [placeholder]="'Please select'"
              [labelName]="'Category Name(s)'"
            ></app-cui-searchable-dropdown>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
          >
            <label
              for="enquirySubTopic"
              class="enquiry-form-lbl asteriskdropdown"
              id="enqSubTypeID"
              >Enquiry Sub-Type</label
            >
            <app-cui-searchable-dropdown
              #subCategoryTypes
              [ngClass]="{ disabledropDown: subcategories.length == 0 }"
              [formControlName]="'subCategoryTypes'"
              name="subCategoryTypes"
              [items]="subcategories"
              required
              [model]="SubCategoryModel"
              class="user-dropDown"
              (ngModelChange)="subCategoryChanged($event)"
              [placeholder]="'Please select'"
              [labelName]="'SubCategory Name(s)'"
              [disabled]="SubCategoryModel.isDisabled"
            ></app-cui-searchable-dropdown>
          </div>
        </div>

        <div *ngIf="showMsg" class="rectangle">
          <div style="display: flex; align-items: center">
            <img alt="error" src="../../assets/images/icon-material-info.svg" />
            <p class="rectangle-text">
              {{ fileuploadObj.fileUploadLabelAuthoring.siteAccess }}
            </p>
          </div>
          <div style="margin-left: 44px">
            <span
              ><img
                src="../../assets/images/Icon-feather-external-link.svg"
                alt=""
            /></span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              class="rectangle-url"
              href="https://www.telstra.com.au/forms/facilities-maintenance"
              >Raise a maintenance request</a
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 enquiry-form-fields">
            <label for="shortDesc" class="enquiry-form-lbl"
              >Short Description</label
            >
            <textarea
              formControlName="shortDesc"
              [attr.aria-label]="getAriaLabel()"
              name="shortDesc"
              class="form-text-area"
              id="shortDesc"
              rows="2"
              [placeholder]="'enterShortDesc' | translate"
              [ngClass]="
                !enquiryForm.get('shortDesc').valid &&
                enquiryForm.get('shortDesc').touched
                  ? 'invalid-form-control'
                  : ''
              "
              (blur)="blurredOut($event, enquiryForm, 'shortDesc')"
              tabindex="0"
              maxlength="160"
              required
              (ngModelChange)="changeInValue($event, enquiryForm, 'shortDesc')"
            ></textarea>
            <div>
              <span style="float: right"
                >{{
                  enquiryForm.get("shortDesc").value
                    ? enquiryForm.get("shortDesc").value.length
                    : 0
                }}
                / 160</span
              >
              <span
                *ngIf="enquiryForm.get('shortDesc').value?.length >= 160"
                aria-live="assertive"
                role="alert"
                class="error-msg-div"
                >{{ "querycharshortLimit" | translate }}</span
              >
            </div>
            <div
              class="error-msg-div"
              [attr.tabindex]="
                !enquiryForm.get('shortDesc').valid &&
                enquiryForm.get('shortDesc').touched
                  ? '0'
                  : '-1'
              "
              id="error-msg-id"
              role="alert"
            >
              <div
                class="error-msg"
                *ngIf="
                  !enquiryForm.get('shortDesc').valid &&
                  enquiryForm.get('shortDesc').touched
                "
              >
                <img alt="error" src="../../assets/images/warning.png" />
                {{ "enterValidShortdetails" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 enquiry-form-fields" style="margin-bottom: 30px">
            <label for="enquiryDetails" class="enquiry-form-lbl">{{
              "writeQuery" | translate
            }}</label>
            <textarea
              formControlName="enquiryDetails"
              [attr.aria-label]="getAriaLabels()"
              name="enquiryDetails"
              class="form-text-area"
              id="enquiryDetails"
              rows="3"
              [placeholder]="'enterEnquirydetails' | translate"
              [ngClass]="
                !enquiryForm.get('enquiryDetails').valid &&
                enquiryForm.get('enquiryDetails').touched
                  ? 'invalid-form-control'
                  : ''
              "
              (blur)="blurredOut($event, enquiryForm, 'enquiryDetails')"
              tabindex="0"
              maxlength="10000"
              required
              (ngModelChange)="
                changeInValue($event, enquiryForm, 'enquiryDetails')
              "
            ></textarea>
            <div>
              <span
                *ngIf="enquiryForm.get('enquiryDetails').value?.length >= 10000"
                aria-live="assertive"
                role="alert"
                class="error-msg-div"
                >{{ "querycharLimit" | translate }}</span
              >
              <span
                [ngStyle]="{
                  'margin-left':
                    enquiryForm.get('enquiryDetails').value?.length >= 10000
                      ? '20%'
                      : '50%'
                }"
              >
                <img src="../../assets/images/icon-resizable.svg" alt="" />
                Click and drag for more text area</span
              >
              <span style="float: right"
                >{{
                  enquiryForm.get("enquiryDetails").value
                    ? enquiryForm.get("enquiryDetails").value.length
                    : 0
                }}
                / 10000</span
              >
            </div>
            <div
              class="error-msg-div"
              [attr.tabindex]="
                !enquiryForm.get('enquiryDetails').valid &&
                enquiryForm.get('enquiryDetails').touched
                  ? '0'
                  : '-1'
              "
              id="error-msg-id"
              role="alert"
            >
              <div
                class="error-msg"
                *ngIf="
                  !enquiryForm.get('enquiryDetails').valid &&
                  enquiryForm.get('enquiryDetails').touched
                "
              >
                <img alt="error" src="../../assets/images/warning.png" />
                {{ "enterValidEnquirydetails" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          id="upload-section"
          *ngIf="fileuploadObj.fileUploadLabelAuthoring"
          class="file-uploads-section section-padding"
        >
          <label class="description-header">{{
            fileuploadObj.fileUploadLabelAuthoring.fileuploadHeader
          }}</label>
          <span class="file-number"
            >{{ fileuploadObj.attachmentObj.length }}/10 Files</span
          >
          <div class="file-uploads-area row">
            <div
              droppable="true"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
              class="file-uploads-browse col-12"
              [ngStyle]="{
                'background-color': filedropFlag == true ? '#717375' : '#FFFFFF'
              }"
            >
              <img
                class="upload-icon"
                src="../../assets/images/icon-upload.svg"
                alt="Upload icon"
              />
              <p class="file-uploads-drag-text">{{ fileuploadDragText }}</p>
              <p class="file-uploads-drag-details">
                {{ fileuploadObj.fileUploadLabelAuthoring.fileInfo }}
              </p>
              <button
                type="button"
                class="link-btn"
                (click)="fileUploadInput.click()"
              >
                {{
                  fileuploadObj.fileUploadLabelAuthoring
                    .fileuploadBrowseLinkText
                }}
              </button>
              <p class="file-uploads-drag-details">
                {{
                  fileuploadObj.fileUploadLabelAuthoring
                    .fileuploadDragTextViewTicket
                }}
              </p>
              <input
                #fileUploadInput
                id="browseFileCreateTicket"
                multiple
                type="file"
                hidden
                (change)="uploadFile($event.target.files)"
                title="attachment"
              />
            </div>

            <div class="file-uploads-attachments col-12">
              <span
                id="noFilesCreateTicket"
                class="no-files-attached"
                [ngClass]="{ hidden: fileuploadObj.attachmentObj.length !== 0 }"
                >{{
                  fileuploadObj.fileUploadLabelAuthoring.fileuploadNoFileText
                }}
              </span>
              <div class="uploads-class">
                <div
                  id="filesListCreateTicket"
                  class="file-container file-attached-view"
                  [ngClass]="{
                    'file-attached-bg': filedropFlag,
                    'file-attached': fileuploadObj.attachmentObj.length !== 0,
                    hidden: fileuploadObj.attachmentObj.length === 0
                  }"
                  *ngFor="let val of fileuploadObj.attachmentObj"
                >
                  <div class="row file-upload-container">
                    <div class="row section-left">
                      <div class="col lblHeader-container">
                        <span
                          *ngIf="
                            val.validationError === '' &&
                            !val.canShowProgressBar
                          "
                          class="link-btn"
                        >
                          <span class="attached-file-name">{{
                            val.attachmentDetails.fileName
                          }}</span>
                        </span>
                        <span
                          *ngIf="
                            val.validationError !== '' || val.canShowProgressBar
                          "
                          class="attached-file-name"
                          [ngClass]="{ lblHeader: val.validationError !== '' }"
                          >{{ val.attachmentDetails.fileName }}
                        </span>
                      </div>
                      <div
                        class="col progressbar-container"
                        *ngIf="
                          val.validationError === '' && val.canShowProgressBar
                        "
                      >
                        <div class="fileUploadProgressbar">
                          <app-cui-progress-bar
                            [min]="0"
                            [max]="100"
                            [model]="progressBarModel"
                            [isDone]="val.isUploadDone !== ''"
                          >
                          </app-cui-progress-bar>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sidebar">
                    <i
                      class="icon-icon-ui-cross icon-file-cross"
                      tabindex="0"
                      aria-label="Delete Attachment Button"
                      (keydown)="keyEnterEvent($event, val)"
                      (click)="clearFolderSelection(val)"
                    >
                    </i>
                  </div>
                </div>
              </div>
              <div class="validation-container" *ngIf="validError">
                <span role="alert" class="file-upload-validation-error">
                  <img
                    style="margin-right: 6px"
                    alt="error"
                    src="../../assets/images/warning.png"
                  />{{ validError }}</span
                >
              </div>
              <button
                *ngIf="fileuploadObj.attachmentObj.length !== 0"
                class="red-secondary-btn"
                (click)="clearAllAttachments()"
                type="button"
                tabindex="0"
              >
                <i class="icon-icon-ui-cross icon-xs"></i>Clear all files
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <hr class="line-1" />
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
          >
            <label class="contact-header">Contact Details</label> <br />
            <label class="Logged-by mb-3">Logged by</label>
            <div class="contacts">
              <div>
                <span class="spt">{{ author }}</span>
              </div>
              <!-- <div><span class="spt">JW</span></div> -->

              <div>
                <span class="contact-names">{{ primaryName }}</span
                ><br />
                <span class="contact-email">{{ primaryMail }}</span>
                <!-- <span class="contact-names">John Wick</span><br> -->
                <!-- <span class="contact-email">Johnwick@abc.com</span> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
          >
            <app-cui-checkbox
              class="contactCheckbox contactCheckbox-focus"
              [automationId]="'alternatecontact'"
              [name]="'altContactChecked'"
              (checkboxInstance)="checkboxInstance($event)"
              (checkboxChange)="selectCheckbox($event)"
              [disable]="false"
              [checked]="toggleAlternatecheck"
              [model]="altContact"
            >
            </app-cui-checkbox>
            <span class="alternate-contact-heading"
              >Would you like to add an Alternate contact?</span
            >
          </div>
        </div>

        <div *ngIf="toggleAlternatecheck">
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
            >
              <app-cui-textfield
                [(model)]="ContactNameModel"
                [parentFormGroup]="enquiryForm"
                (blurred)="blurredOut($event, enquiryForm, 'contactName')"
                (input)="changeInValue($event, enquiryForm, 'contactName')"
              >
              </app-cui-textfield>
              <div
                class="error-msg-div"
                *ngIf="!enquiryForm.valid"
                [attr.tabindex]="
                  !enquiryForm.get('contactName').valid &&
                  enquiryForm.get('contactName').touched
                    ? '0'
                    : '-1'
                "
                id="error-msg-id"
                role="alert"
              >
                <div
                  class="error-msg"
                  *ngIf="
                    !enquiryForm.get('contactName').valid &&
                    enquiryForm.get('contactName').touched
                  "
                >
                  <img alt="error" src="../../assets/images/warning.png" />{{
                    "nameFormat" | translate
                  }}
                </div>
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
            >
              <app-cui-textfield
                [(model)]="emailModel"
                [parentFormGroup]="enquiryForm"
                (blurred)="blurredOut($event, enquiryForm, 'contactEmail')"
                (input)="changeInValue($event, enquiryForm, 'contactEmail')"
              >
              </app-cui-textfield>
              <div
                class="error-msg-div"
                *ngIf="!enquiryForm.valid"
                [attr.tabindex]="
                  !enquiryForm.get('contactEmail').valid &&
                  enquiryForm.get('contactEmail').touched
                    ? '0'
                    : '-1'
                "
                id="error-msg-id"
                role="alert"
              >
                <div
                  class="error-msg"
                  *ngIf="
                    !enquiryForm.get('contactEmail').valid &&
                    enquiryForm.get('contactEmail').touched
                  "
                >
                  <img alt="error" src="../../assets/images/warning.png" />
                  {{ "emailFormat" | translate }} <br />
                  email@example.com
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 enquiry-form-fields"
            >
              <app-cui-textfield
                [(model)]="ContactNumberModel"
                [parentFormGroup]="enquiryForm"
                (blurred)="blurredOut($event, enquiryForm, 'contactMobile')"
                (input)="changeInValue($event, enquiryForm, 'contactMobile')"
              >
              </app-cui-textfield>
              <div
                class="error-msg-div"
                *ngIf="!enquiryForm.valid"
                [attr.tabindex]="
                  !enquiryForm.get('contactMobile').valid &&
                  enquiryForm.get('contactMobile').touched
                    ? '0'
                    : '-1'
                "
                id="error-msg-id"
                role="alert"
              >
                <div
                  class="error-msg"
                  *ngIf="
                    !enquiryForm.get('contactMobile').valid &&
                    enquiryForm.get('contactMobile').touched
                  "
                >
                  <img alt="error" src="../../assets/images/warning.png" />{{
                    "australiaNumber" | translate
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-div">
          <button
            type="submit"
            id="submit-btn"
            tabindex="0"
            class="red-primary-btn enquiry-submit-btn"
            [disabled]="!enquiryForm.valid"
          >
            {{ "Submit" | translate }}
          </button>
          <button
            class="red-secondary-btn"
            aria-label="Cancel opens in a model overlay"
            (click)="cancel()"
            (keydown.Enter)="cancelTab()"
            type="button"
            tabindex="0"
          >
            <i class="icon-icon-ui-cross icon-xs"></i>{{ "Cancel" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-container *ngIf="isModalOpen">
  <div
    class="modal"
    id="exampleModal"
    aria-label="Cancel Enquiry"
    role="dialog"
    aria-modal="true"
    [ngClass]="{ profileModal: isModalOpen }"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="container p-0">
          <div class="row m-0 p-0">
            <div class="closeBtn">
              <button
                tabindex="0"
                (click)="closeModal()"
                id="closeDiclaimer"
                class="close closebtn"
                aria-label="Close"
                role="button"
              >
                <i class="icon-icon-ui-cross icon-xs closeModel"></i>
              </button>
            </div>
            <!-- <div> -->
            <!-- <span id='modalDescription' style="visibility: hidden;">Cancel Enquiry open in a new modal overlay.</span> -->
            <h2 id="cancel-enquiry-header" class="Cancel-Enquiry">
              {{ "cancelEnquiry" | translate }}
            </h2>
            <p class="message">{{ "cancelEnquiryMsg" | translate }}</p>
            <div class="btns">
              <button
                type="button"
                id="cancel-enquiry-yes-btn"
                tabindex="0"
                (click)="homePage()"
                class="red-primary-btn acknowledge-submit-btn"
              >
                YES
              </button>
              <button
                tabindex="0"
                id="cancelDisclaimer"
                class="red-secondary-btn cancelButton"
                (click)="closeModal()"
                role="button"
              >
                NO
              </button>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
