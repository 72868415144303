import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  focusOnElement(id: string) {
    let focusableElement = document.getElementById(id);
    setTimeout(() => {
      if (focusableElement) {
        focusableElement.focus();
      }
    }, 10);
  }

  trapFocusInModal(firstEl, secEle) {
    let firstFocusableElement;
    let lastFocusableElement;
    setTimeout(() => {
      firstFocusableElement = document.getElementById(firstEl); // get first element to be focused inside modal
      lastFocusableElement = document.getElementById(secEle);
    }, 10);
    document.addEventListener('keydown', function (e) {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();// add focus for the last focusable element
          e.preventDefault();
        }
      } else { // if tab key is pressed
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus();// add focus for the first focusable element
          e.preventDefault();
        }
      }
    });
    if (firstFocusableElement) {
      firstFocusableElement.setAttribute("focus", "true");
    }
  }
}
