<div class="footer">
    <div class="row">
        <div class="col-3 footer-svg" >
            <!-- <img src = "../../assets/images/revised-logo.png" height="40x" width="200px" alt="Amplitel"/> -->
        </div>
        <div class="col-3"></div>
        <div class="col-4 footer-links">
            <div>
                <span>
                  <a class="footer-nav-links" tabindex="0" rel="noopener noreferrer" target="_blank"  role="link" href="https://www.amplitel.com.au/privacy-policy">{{'privacyPolicy'|translate}}</a>  
                | <a class="footer-nav-links" tabindex="0" rel="noopener noreferrer" target="_blank"  role="link" href="https://www.amplitel.com.au/terms-use">{{'termsOfuse'|translate}}</a>
                | <a class="footer-nav-links" tabindex="0" rel="noopener noreferrer" target="_blank"  role="link" href="https://www.amplitel.com.au/documentation">{{'Documentation'|translate}}</a>    
            </span>
            </div>              
            <div>{{copyRight}}</div>
        </div>
    </div>
</div>
