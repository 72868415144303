import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyNotificationService, IModalData } from '../company-notification.service';

@Component({
  selector: 'app-alert-box-admin-notification-pref',
  templateUrl: './alert-box-admin-notification-pref.component.html',
  styleUrls: ['./alert-box-admin-notification-pref.component.less']
})
export class AlertBoxAdminNotificationPrefComponent implements OnInit {

  public data: IModalData;
  constructor(private activeModal: NgbActiveModal, private notificationService: CompanyNotificationService) { }

  ngOnInit(): void {
    this.data = this.notificationService.getModalData();
  }

  close(result) {
    this.activeModal.close(result);
  }

}
