//This file can be replaced during build by using the `fileReplacements` array.
//`ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
//The list of file replacements can be found in `angular.json`.

export const env = {
  production: true,
  //  apiPoint: 'https://prod-towerco-portal-service.massl.pr.sdppcf.com/',
  apiPoint: 'https://tapi.telstra.com/presentation/v1/towerco-portal/',
  amsUrl: 'https://ams.amplitel.com.au/TowercoAMS',
  aemEndPoint: './assets/labelAuthoring/',
  contactName: '',
  contactNameUI: '',
  contactBusinessPhone: '',
  email: '',
  contactFirstName: '',
  contactLastName: '',
  CIDN: ''
};

const userObj = {
  userInfoEndPoint: 'https://myid.telstra.com/identity/idp/userinfo.openid',
  authority: 'https://myid.telstra.com/identity',
  client_id: 'tower-portal',
  response_type: 'code',
  scope: 'openid profile username tw',
  filterProtocolClaims: false,
  loadUserInfo: false,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://portal.amplitel.com.au/silent-refresh.html',
  redirect_uri: 'https://portal.amplitel.com.au/',
  post_logout_redirect_uri: 'https://ams.amplitel.com.au/TowercoAMS/authentication/logout',
  unauthorized_access_uri: 'https://portal.amplitel.com.au/unauthorised-access.html'
};
window['environment'] = window['environment'] ? window['environment'] : env;
window['userManagerObj'] = window['userManagerObj'] ? window['userManagerObj'] : userObj;
export const environment = window['environment'] || env;
export const userManagerObj = window['userManagerObj'] || userObj;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.