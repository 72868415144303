import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomersModule } from "../customers/customers.module";
import { BillingRoutingModule } from './billing-routing.module';
import { BillingHomeComponent } from './billing-home/billing-home.component';
import { BillingService } from "./billing.service";

// import { CustomerService } from '../customers/customer.service';
import { GridFilterModule } from '../grid-filter/grid-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { TwCoreUIFormModule, SpinnerModule } from 'tw-core-ui';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastContainerModule, ToastrModule, ToastrService } from 'ngx-toastr';
import { FiletypeRendererUploadsListingComponent } from './filetype-renderer-uploads-listing/filetype-renderer-uploads-listing.component';
import { MyUploadsActionsRendererComponent } from './uploads-action-items-renderer/uploads-action-items-renderer.component';

@NgModule({
  declarations: [BillingHomeComponent, FiletypeRendererUploadsListingComponent, 
   MyUploadsActionsRendererComponent],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SpinnerModule,
    TwCoreUIFormModule,
    ReactiveFormsModule,
    FormsModule,
    GridFilterModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    NgbTooltipModule,
    MatTooltipModule,
    CustomersModule,
    AgGridModule.withComponents([      
      FiletypeRendererUploadsListingComponent,
      MyUploadsActionsRendererComponent,
      CustomTextFilterComponent,
      CheckboxFilterComponent,
      CustomDateFilterComponent
    ]),
  ],providers:[
    DatePipe, ToastrService,BillingService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports:[BillingHomeComponent]
})
export class BillingModule { }
