import { Injectable } from '@angular/core';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';

@Injectable({
  providedIn: 'root'
})
export class TicketSummaryService {
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private env: any = window['environment'];
  ticketSummaryObj: any;
  public ticketObj: Object = null;
  serviceDetailsData: any;
  
  private apiUrl = {
    ticketSummary: 'ticket-summary',
    createTicket:'service-management/tickets',
    ticketDetails: 'ticketDetails',
    attachment: 'attachments',
    categoryUri :'service-management/ticket-categories',
  };

  getServiceDetailsData() {
    return this.serviceDetailsData;
  }

  setServiceDetailsData(_data) {
    this.serviceDetailsData = _data;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getTicketSummaryDetails() {
    return new Promise((resolve, reject) => {
      if (!this.ticketSummaryObj) {
        this.http.get(this.env['aemEndPoint'] + this.apiUrl.ticketSummary).pipe(catchError((error: any): any => {
          reject(error);
          return observableThrowError(error.json().error || 'Server error');
        })).subscribe((res: any) => {
          this.ticketSummaryObj = res.data.content;
          resolve(this.ticketSummaryObj);
        });
      } else {
        resolve(this.ticketSummaryObj);
      }
    });
  }
  
  public getTicketOptions() {    
    return new Promise((resolve, reject) => {
      this.http.get(this.env.aemEndPoint + this.apiUrl.ticketDetails).pipe(catchError((error: any): any => {
        reject(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe((callResult: any) => {
        this.ticketObj = callResult;
        resolve(this.ticketObj);
      });
    });
  }
  
  public getCategories() {
    let url = this.env['apiPoint'] + this.apiUrl.categoryUri;
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get(url, httpOptions).pipe(catchError((error: any): any => {
        reject(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe((res) => {
        if (res) {
          resolve(res['result']);
        }
      });
    });
  }

  createSingleAttachment(upload:any,sysTicketId:string) {  
    let formData: FormData = new FormData();

    for (let a of upload) {
      formData.append('file', a);
    }
    const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'multipart/form-data; boundary=----------287032381131322',
          'Accept': '*/*',
          'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
        })
      };
    return new Promise((resolve, reject) => {
      this.http.post(this.env['apiPoint']+this.apiUrl.createTicket+`/${sysTicketId}/`+this.apiUrl.attachment,formData,httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.changeSpinner(false);
        }, err => {
          // this.changeSpinner(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public createService(payload) {
    this.sendSpinnerFlag.next(true);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = this.env.apiPoint + this.apiUrl.createTicket;
    return new Promise((resolve, reject) => {
      this.http.post<any>(url,payload, httpOptions)
        .subscribe((res:any) => {
          this.setServiceDetailsData(res.result);
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.setServiceDetailsData(null);
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getServiceDetails(ticketId) {
    this.sendSpinnerFlag.next(true);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = this.env.apiPoint + this.apiUrl.createTicket + `/${ticketId}`;
    
    return new Promise((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.setServiceDetailsData(res.result);
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.setServiceDetailsData(null);
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public updateNotes(payload,ticketId) {
    this.sendSpinnerFlag.next(true);
    const _httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = this.env.apiPoint +'service-management/tickets/' + ticketId;
    return new Promise((resolve, reject) => {
        this.http.put(url, payload, _httpOptions).subscribe((res: any) => {
          this.setServiceDetailsData(res.result);
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.setServiceDetailsData(null);
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

}


