<div class="summarypage-bg page-bg" [attr.aria-hidden]="isModalOpen || isDisagreeModalOpen ? true : false">
    <h1 *ngIf="serviceDetails" id="ticketSum" class='ticket-summary'>
      Enquiry ID <span *ngIf="ticketId">{{ticketId}}</span>     
    </h1>
    <div class="container-fluid page-container diagnostics-tests">
    <div *ngIf="aemLabelAuthoring && serviceDetails" class="wrapper">
        <div class="row service-details">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 userNameWidth">
              <ul class="breadcrumb" aria-label="breadcrumb navigation landmark">
                <li tabindex="0" role="home" class="active" (keypress)="navigate('/home')"><a class="decoration" (click)="navigate('/home')">{{aemLabelAuthoring.pageHeader.home}}
                        &nbsp;</a><span class="nav-icon">></span></li>&nbsp;&nbsp;
                <li tabindex="0" role="my enquiries" (keypress)="navigate('/enquiries/myenquiries')"><a class="decoration" (click)="navigate('/enquiries/myenquiries')"> {{aemLabelAuthoring.pageHeader.myTickets}}
                        &nbsp;</a><span class="nav-icon">></span></li>&nbsp;&nbsp;
                <li tabindex="0" role="enquiries summary" aria-current="page">{{aemLabelAuthoring.pageHeader.ticketSummary}}</li>
              </ul>
                <div class="row mt-3">
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7" *ngIf="state" ngSwitch="{{state | lowercase}}">
                        <div class="row">                          
                            <div class="status-containerDetails" *ngSwitchCase="'new'">
                              <img src="assets/images/icon-new.png" alt="New Enquiry icon">
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">New Enquiry has been created</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'open'">
                              <img src="assets/images/icon-open.svg" class="spriteNew"/>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry has been opened</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'in progress'">
                              <img src="assets/images/icon-in-progress.png" class="spriteNew"/>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry is Inprogress</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'resolved'">
                              <span class="twssp-sprite thumbs-up sprite-m"></span>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry has been Resolved</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'closed'">
                              <img src="assets/images/icon-closed.svg" class="spriteNew"/>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry has been Closed</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'awaiting info'">
                              <img src="assets/images/icon-awaiting-info.png" class="spriteNew"/>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry is Awaiting-Info</span>
                            </div>
                            <div class="status-container" *ngSwitchCase="'cancelled'">
                              <img src="assets/images/icon-cancalled.svg" class="spriteNew"/>
                              <span class="status-textHis">{{state | uppercase}}</span><br>
                              <span class="statusText">The Enquiry has been cancelled</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2" [ngClass]="{'alignClose':!statusIsResolved}">
                        <button type="button" id="closeEnquiryBtn" [disabled]="statusIsClosed" class="close-ticket-button" (click)="closeTicket()"
                        [attr.aria-label]="statusIsResolved === true ? 'Agree and Close opens in a model overlay' : aemLabelAuthoring.pageHeader.closeTicket + 'opens in a model overlay'">
                        {{statusIsResolved ? 'AGREE & CLOSE' : aemLabelAuthoring.pageHeader.closeTicket }}</button>
                    </div> 
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2" *ngIf="statusIsResolved">
                        <button type="button" id="disagreeBtn" [disabled]="statusIsClosed" class="disagree-ticket-button"
                          (click)="disagreeCloseTicket()"
                          aria-label="Disagree? Tell us Why opens in a model overlay">Disagree? Tell us Why</button>   
                    </div>
                </div>
                <!-- <div class="col-lg-3 estimated-date" *ngIf="estimatedResolutionDate">
                    <div>{{estimatedResolutionDate}}</div>
                    <div>{{aemLabelAuthoring.pageHeader.estimated}}</div>
                    <div *ngIf="serviceDetails.serviceRestoration.slaDescription" class="sla">{{serviceDetails.serviceRestoration.slaDescription}}</div>
                </div> -->
            </div>
        <hr class="line-1">
        <div class="row col-12">
            <div class="col-lg-4 vl">                
              <h2 class="important-text-heading m-0">Enquiry Summary</h2>                
              <div class="col-12 typeEnq">
                <div class="col-6">
                  <label class="labelName">{{aemLabelAuthoring.form.ticketType}}</label>
                  <p class="overtext">{{enquiryType}}</p>                          
                </div>                   
                <div class="col-6">
                  <label class="labelName">{{aemLabelAuthoring.form.ticketSubType}}</label>
                  <p class="overtext">{{enquirySubtype}}</p>                       
                </div>
              </div>
              <div class="col-12 shortdesp">
                <div class="col-8">
                  <label class="labelName">Short description</label>
                  <p class="overtext">{{shortdesp}}</p>
                </div>
              </div>
              <div class="col-12 detailedDesp">
                <div class="col-10 containerdec" [class.see]="see">
                  <label class="labelName">{{aemLabelAuthoring.form.detailedDesc}}</label>
                  <p class="overtext">{{ticketDetails}}</p>
                </div>
                <div class="col-2 showmoredec">
                  <button tabindex="0" class="readmore secondary-btn" (click)="see = !see">{{ see ? 'Read less': 'Read More' }}</button>
                </div>
              </div>
              <div class="col-12 typeEnq1">
                <div class="col-6">
                  <label class="labelName">Logged Date</label>
                  <p class="overtext">{{createdDate}} AEST</p>                          
                </div>                    
                <div class="col-6">
                  <label class="labelName">Last Updated</label>
                  <p class="overtext">{{updatedDate}} AEST</p>                       
                </div>
              </div>
              <div class="col-12 Logged mt-3">
                <div class="col-8">
                  <label class="labelName">Logged By</label>
                  <p class="overtext">{{loggedby}}</p>
                </div>
              </div>
            </div>
            <!-- <div class="col-1 vl"></div> -->
            <div class="col-lg-8 v2">    
                <div>
                    <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
                          <label for="comment" class="enquiry-form-lbl">{{aemLabelAuthoring.form.comment}}</label>
                          <textarea formControlName="comment" 
                           [attr.aria-label]="getAriaLabel()"
                           name="comment" class="form-text-area" id="comment"
                           rows="3" [placeholder]="aemLabelAuthoring.form.textAreaPlaceHolder" 
                           [ngClass]="!commentForm.get('comment').valid && 
                           commentForm.get('comment').touched ? 'invalid-form-control' : ''"
                           tabindex="0" maxlength="1000" required></textarea>
                          <span class="commentLength">{{commentForm.get('comment').value ? commentForm.get('comment').value.length : 0}} / 1000</span>                          
                          <!-- <span *ngIf="commentForm.get('comment').value.length >= 1000" 
                            aria-live="assertive" role="alert" class="error-msg-div">Maximum character limit of 1000 reached</span> -->
                          <div class="error-msg-div" [attr.tabindex]="!commentForm.valid? '0' : '-1'" id="error-msg-id" role="alert">
                            <div class="error-msg" *ngIf="!commentForm.get('comment').valid && commentForm.get('comment').touched">
                              <img alt="error" src="../../../assets/images/warning.png"/>{{'comments'|translate}} 
                            </div>
                          </div>
                      
                        <div class="texts" style="color:#E62121" *ngIf="attachBoolean">
                          <img src="../../../assets/images/icon-upload.svg" alt="Upload Icon"> {{aemLabelAuthoring.form.attach}}
                        </div>
                        <div id="upload-section" *ngIf="fileuploadObj.fileUploadLabelAuthoring" class="file-uploads-section section-padding">
                            <ng-template #bandwidthTipContent>
                              <app-cui-tooltip-content [model]="bandwidthTooltipContentModel"></app-cui-tooltip-content>
                            </ng-template>
                            <app-cui-info-icon 
                                [content]= "bandwidthTooltipContentModel.content"
                                [ngbTooltip]="bandwidthTipContent"  
                                automationType="anchor" 
                                automationId="anchorAddOrderDateInfo"  
                                placement="right"
                                #tooltipCustRd="ngbTooltip"
                                (focusin)= "tooltipCustRd.open()"
                                (focusout)= "tooltipCustRd.close()">
                            </app-cui-info-icon>
                            
                            <button type="button" class="link-btn" (click)="browseFolder()" [disabled]="statusIsClosed">                               
                              <img src="../../../assets/images/Icon-metro-attachment.svg" class="attachment-icon" alt="Attachment Icon"> 
                                Attachments
                            </button>
                            <span class="file-number">{{fileuploadObj.attachmentObj.length}}/10 Files</span>
                          
                            <div class="file-uploads-area row">
                              <input #fileUploadInput id="browseFileCreateTicket" multiple
                                    type="file" class="hidden" (input)="onChangeFile($event.target.files)" title="attachment"/>                      
                              <div class="file-uploads-attachments col-12">
                                <span id="noFilesCreateTicket" class="no-files-attached" 
                                    [ngClass]="{'hidden': fileuploadObj.attachmentObj.length !== 0}">{{fileuploadObj.fileUploadLabelAuthoring.fileuploadNoFileText}}
                                </span>
                                <div class="uploads-class">
                                  <div id="filesListCreateTicket" class="file-container file-attached-view"
                                      [ngClass]="{'file-attached-bg': filedropFlag , 'file-attached':fileuploadObj.attachmentObj.length !== 0, 'hidden': fileuploadObj.attachmentObj.length === 0 }" 
                                      *ngFor="let val of fileuploadObj.attachmentObj">
                                    <div class="row file-upload-container">
                                      <div class="row section-left">
                                        <div class="col lblHeader-container">
                                          <span *ngIf="(val.validationError === '') && (!val.canShowProgressBar)" class="link-btn">
                                            <span class="attached-file-name">{{val.attachmentDetails.fileName}}</span>
                                          </span>
                                          <span *ngIf="(val.validationError !== '') || (val.canShowProgressBar)" class="attached-file-name" 
                                              [ngClass]="{'lblHeader': (val.validationError !== '')}">{{val.attachmentDetails.fileName}}
                                          </span>
                                        </div>
                                        <div class="col progressbar-container"
                                          *ngIf="(val.validationError === '') && (val.canShowProgressBar)">
                                          <div class="fileUploadProgressbar">
                                            <app-cui-progress-bar [min]="0" [max]="100" [model]="progressBarModel"
                                              [isDone]="val.isUploadDone !== ''">
                                            </app-cui-progress-bar>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div class="row validation-container" *ngIf="(val.validationError !== '')">
                                        <span role="alert" class="file-upload-validation-error">{{ val.validationError }}</span>
                                      </div> -->
                                    </div>
                                    <div class="sidebar">
                                      <i class="icon-icon-ui-cross icon-file-cross" tabindex="0" aria-label="Delete Attachment Button" 
                                        (keydown)="keyEnterEvent($event, val)" (click)="clearFolderSelection(val)">
                                      </i>
                                    </div>
                                  </div>
                                </div>
                                <div class="validation-container" *ngIf="validError">
                                    <span role="alert" class="file-upload-validation-error">
                                    <img style="margin-right: 6px;" alt="error" src="../../../assets/images/warning.png"/>{{validError}}</span>
                                </div>
                                <button *ngIf="fileuploadObj.attachmentObj.length !== 0" class="red-secondary-btn" (click)="clearAllAttachments()" type="button" tabindex="0">
                                    <i class="icon-icon-ui-cross icon-xs"></i>Clear all files</button>  
                              </div>
                            </div>
                          </div>
                        <button type="submit" class="contactSubmit" [disabled]="(!commentForm.valid && fileuploadObj.attachmentObj.length==0) || statusIsClosed">SUBMIT</button>
                    </form>
                </div>
                <div class="tabContainer">
                    <mat-tab-group>
                      <mat-tab label="History" class="my-custom-class">
                        <!-- <ng-template mat-tab-label class="my-custom-class" aria-label="none">
                            <span tabindex="0" aria-label="history" class="my-custom-class">History</span>
                        </ng-template> -->
                        <ul id="hisdisplay">
                          <li *ngFor="let item of historyDet; let i = index">
                            <div class="row historydetails" role="alert">   
                              <div class="col-1 image-section">
                                <img *ngIf="historyDet[i].type == 'Agent Notes'" class="logo" src="../../../assets/images/amplitel-logo.svg" alt="">
                                <div *ngIf="historyDet[i].type == 'User Notes'"><span class="sp">{{historyDet[i].author}}</span></div>
                              </div>                       
                              <div class="col-2 dateTime">{{historyDet[i].date}}<span> AEST</span></div>                             
                              <div class="col-9 textContent" [class.showComment]="show">
                                  <p>{{historyDet[i].comment}}</p>                   
                              </div>
                              <!-- <div class="col-2 showmore">
                                  <button class="readmore secondary-btn" (click)="show = !show">{{ show ? 'Read less': 'Read More' }}</button>
                              </div> -->                              
                            </div>                        
                          </li>
                        </ul>                           
                      </mat-tab>
                        
                    <mat-tab label="Alternate contact" class="my-custom-class">
                        <!-- <ng-template  mat-tab-label class="my-custom-class" aria-label="none">
                            <span tabindex="0" aria-label="alternate contact" class="my-custom-class">Alternate Contact</span>
                        </ng-template> -->
                        <div id="alternate1" class="row alternatecontact"> 
                          <form [formGroup]="contactForm">
                            <div class="row col-12">
                              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 enquiry-form-fields" role="alert">
                                <div *ngIf="altName && !EditContactName">
                                  <label class="checkLbl">Name</label> <br>
                                  <span id="show">{{altName}}</span>
                                </div>
                               
                                <app-cui-textfield *ngIf="EditContactName || !altName" [(model)]="ContactNameModel" [parentFormGroup]="contactForm">
                                </app-cui-textfield>
                                <div class="error-msg-div" *ngIf="!contactForm.valid" [attr.tabindex]="!contactForm.get('contactName').valid &&
                                  contactForm.get('contactName').touched? '0' : '-1'" id="error-msg-id" role="alert">
                                  <div class="error-msg" *ngIf="!contactForm.get('contactName').valid &&
                                      contactForm.get('contactName').touched">
                                      <img alt="error" src="../../../assets/images/warning.png"/>{{'nameFormat'|translate}} 
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 enquiry-form-fields" role="alert">
                                <div *ngIf="altEmail && !EditContactEmail">
                                  <label class="checkLbl">Email Address</label> <br>
                                  <span id="show">{{altEmail}}</span>
                                </div>
                               
                                <app-cui-textfield  *ngIf="EditContactEmail || !altEmail" [(model)]="emailModel" [parentFormGroup]="contactForm">
                                </app-cui-textfield>
                                <div class="error-msg-div" *ngIf="!contactForm.valid" [attr.tabindex]="!contactForm.get('contactEmail').valid &&
                                  contactForm.get('contactEmail').touched? '0' : '-1'" id="error-msg-id" role="alert">
                                    <div class="error-msg" *ngIf="!contactForm.get('contactEmail').valid &&
                                        contactForm.get('contactEmail').touched">
                                        <img alt="error" src="../../../assets/images/warning.png"/>
                                        {{'emailFormat'|translate}} <br> email@example.com
                                    </div>
                                </div>
                              </div>
                              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 enquiry-form-fields" role="alert">
                                <div *ngIf="altNumber && !EditContactNumber">
                                  <label class="checkLbl">Contact Number</label> <br>
                                  <span id="show">{{altNumber}}</span>
                                </div>
                               
                                <app-cui-textfield *ngIf="EditContactNumber || !altNumber" [(model)]="ContactNumberModel" [parentFormGroup]="contactForm">
                                </app-cui-textfield>
                                <div class="error-msg-div" *ngIf="!contactForm.valid" [attr.tabindex]="!contactForm.get('contactMobile').valid &&
                                  contactForm.get('contactMobile').touched? '0' : '-1'" id="error-msg-id" role="alert">
                                  <div class="error-msg" *ngIf="!contactForm.get('contactMobile').valid &&
                                    contactForm.get('contactMobile').touched">
                                    <img alt="error" src="../../../assets/images/warning.png"/>{{'australiaNumber'|translate}}
                                  </div>
                                </div>
                              </div>                           
                             <div class="inline">
                              <button type="submit" id="contactsubmit-btn" tabindex="0" (click)="contactSubmit()" class="red-primary-btn enquiry-submit-btn" 
                                [disabled]="!contactForm.valid || statusIsClosed">{{aemLabelAuthoring.form.submit}}</button>&nbsp; &nbsp;
                               
                                <button class="red-secondary-btn summarybtn" (click)="editcontactfunct()"
                                [disabled]="EditContactName || !altName || statusIsClosed" [ngClass]="{'disableicon':EditContactName || !altName ||statusIsClosed}" type="button" tabindex="0">
                                <i class="icon-icon-register icon-xs"></i>Edit</button> 
                                <!-- <button tabindex="0" class="red-primary-btn enquiry-submit-btn" (click)="canceleditcontact()" [disabled]="!editclicked || disablecancel"
                               >Cancel</button>   -->
                               <button  class="red-secondary-btn summarybtn" (click)="canceleditcontact()"
                               [disabled]="!editclicked || disablecancel"  [ngClass]="{'disableicon':!editclicked || disablecancel}" type="button" tabindex="0">
                               <i class="icon-icon-ui-cross icon-xs" [ngClass]="{'disableicon':!editclicked || disablecancel}"></i>{{'Cancel'|translate}}</button> </div>
                              </div>                           
                          </form>
                        </div> 
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

<ng-container *ngIf="isModalOpen">
    <div class="modal" id="exampleModal"
    [attr.aria-label]="statusIsResolved === true ? 'Agree and Close Enquiry' : 'Close Enquiry'" role="dialog"
     aria-modal="true" [ngClass]="{'profileModal':isModalOpen}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="container p-0">
            <div class="row m-0 p-0">
              <div class="closeBtn">
                <button tabindex="0" (click)="closeModal()" (keydown.Enter)="closeModal()" id="closeEnquiry" 
                class="close closebtn" aria-label="Close" role="button">
                  <i class="icon-icon-ui-cross icon-sm"></i>
                </button>
              </div>     
                <h2 *ngIf="!statusIsResolved" class="Cancel-Enquiry">{{aemLabelAuthoring.form.close}}</h2>
                <h2 *ngIf="statusIsResolved" class="Cancel-Enquiry">Agree and Close</h2>
                <p class="message">{{aemLabelAuthoring.form.closeTicketDesc}}</p>                
                <form [formGroup]="closeTicketForm" (ngSubmit)="onClosureTicketSubmit()">
                    <div>
                        <!-- <label for="closure" class="enquiry-form-lbl">{{aemLabelAuthoring.form.comment}}</label> -->
                        <textarea formControlName="closure"  
                         [attr.aria-label]="getAriaLabel()"
                         name="closure" class="form-text-area" id="closure"
                         rows="3" [placeholder]="closuresubTitle" 
                         [ngClass]="!closeTicketForm.get('closure').valid && 
                         closeTicketForm.get('closure').touched ? 'invalid-form-control' : ''"
                         tabindex="0" maxlength="300" required
                         ></textarea>
                          <span class="commentLength">{{closeTicketForm.get('closure').value ? closeTicketForm.get('closure').value.length : 0}} / 300</span>
                          <span *ngIf="closeTicketForm.get('closure').value && closeTicketForm.get('closure').value.length >= 300" 
                            aria-live="assertive" role="alert" class="error-msg-div">{{'charLimit'|translate}}</span>
                      
                        <div class="error-msg-div" [attr.tabindex]="!closeTicketForm.valid? '0' : '-1'" id="popup-error-msg-id" role="alert">
                          <div class="error-msg" *ngIf="(!closeTicketForm.get('closure').valid && closeTicketForm.get('closure').touched) || formSubmitted">
                            <img alt="error" src="../../../assets/images/warning.png"/>{{'comments'|translate}}
                          </div>
                        </div>
                    </div>
                    <div class="btns">
                      <button tabindex="0" id="cancelDisclaimer" class="red-secondary-btn" (click)="closeModal()" 
                        role="button"><i class="icon-icon-ui-cross icon-xs"></i>{{aemLabelAuthoring.form.cancel}}
                      </button>
                      <button  type="submit" id="submit-btn" tabindex="0"
                        class="red-primary-btn acknowledge-submit-btn">{{aemLabelAuthoring.form.submit}}</button>
                    </div>  
                </form>                
            </div>
          </div>
        </div>
      </div>
      </div>
  </ng-container>

  <ng-container *ngIf="isDisagreeModalOpen">
    <div class="modal" id="Modal" aria-label="Disagree? Tell us Why?" 
    role="dialog"
    aria-modal="true" [ngClass]="{'profileModal':isDisagreeModalOpen}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="container p-0">
            <div class="row m-0 p-0">
              <div class="closeBtn">
                <button tabindex="0" (click)="disagreeCloseModal()" id="closeDiclaimer" 
                  class="close closebtn" aria-label="Close" role="button">
                  <i class="icon-icon-ui-cross icon-sm"></i>
                </button>
              </div>
                <h2 class="Cancel-Enquiry">Disagree? Tell us Why?</h2>
                <p class="message">{{aemLabelAuthoring.form.disagreeTicketDesc}}</p>
                <form [formGroup]="reopenForm" (ngSubmit)="onDisagreeSubmit()">
                <div>
                  <!-- <label for="disagree" class="enquiry-form-lbl">{{aemLabelAuthoring.form.comment}}</label> -->
                  <textarea formControlName="disagree" [attr.aria-label]="getAriaLabel()"
                   name="disagree" class="form-text-area" id="disagree"
                   rows="3" [placeholder]="closuresubTitle" 
                   [ngClass]="!reopenForm.get('disagree').valid && 
                   reopenForm.get('disagree').touched ? 'invalid-form-control' : ''"
                   tabindex="0" maxlength="300" required
                   ></textarea>
                    <span class="commentLength">{{reopenForm.get('disagree').value ? reopenForm.get('disagree').value.length : 0}} / 300</span>
                    <!-- <span *ngIf="reopenForm.get('disagree').value.length >= 300" 
                      aria-live="assertive" role="alert" class="error-msg-div">Maximum character limit of 300 reached</span> -->
                
                  <div class="error-msg-div" [attr.tabindex]="!reopenForm.valid? '0' : '-1'" id="disagree-error-msg-id" role="alert">
                    <div class="error-msg" *ngIf="(!reopenForm.get('disagree').valid && reopenForm.get('disagree').touched) || disagreeFormSubmitted">
                      <img alt="error" src="../../../assets/images/warning.png"/>{{'comments'|translate}}
                    </div>
                  </div>
                </div>
                <div class="btns">
                    <button tabindex="0" id="cancelDisclaimer" class="red-secondary-btn"
                    (click)="disagreeCloseModal()" role="button">
                    <i class="icon-icon-ui-cross icon-xs"></i>{{aemLabelAuthoring.form.cancel}}</button>
                    <button  type="submit" id="disagreesubmit-btn" tabindex="0" 
                    class="red-primary-btn acknowledge-submit-btn mb-2">
                    {{aemLabelAuthoring.form.submit}}</button>
                </div>  
              </form>                
            </div>
          </div>        
        </div>
      </div>
      </div>
  </ng-container>