import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../services/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { userManagerObj } from 'src/environments/environment';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.less']
})
export class TermsAndConditionsComponent implements OnInit {
  private env: any = environment;
  focusableElement: HTMLElement;
  isFirstLogin = false;
  public termsForm: FormGroup;
  private userObj: any = userManagerObj;

  public logoutUrl = this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri

  constructor(private router: Router, fb: FormBuilder, private titleService: Title, private sharedService: SharedService, private commonService: CommonService) {
    {
      this.titleService.setTitle('Amplitel - Terms and conditions');
    }
  }
  ngOnInit(): void {
    this.sharedService.focusOnElement('header_logo');
  }

  onAcknowledge() {
    sessionStorage.setItem('isLoginFlag', 'true');
    this.commonService.setTermsAndCondSubject.next(true);
    // this.router.navigateByUrl('/site-locator')

  }
  cancel() {
    window.location.href = this.logoutUrl;
    sessionStorage.clear();
    sessionStorage.removeItem('isLoginFlag');  }

  cancelTab() {
    this.sharedService.focusOnElement('header_logo');
  }

}
