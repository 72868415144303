import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { HamburgerMenuModule, FooterModule, HeaderModule, BannerModule, SpinnerModule, TwCoreUIFormModule, CanDeactivateGuard, AuthGuardService, AuthService } from 'tw-core-ui';
// import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { MyUserComponent } from './my-user/my-user.component';
import { UsersService } from './users.service';
import { GridFilterModule } from '../grid-filter/grid-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilityService } from '../services/utility.service';
import { CustomersModule } from '../customers/customers.module';
import { NgxSelectModule } from 'ngx-select-ex';

@NgModule({
  declarations: [UsersComponent, CreateUserComponent, ModifyUserComponent, MyUserComponent],
  imports: [
    CommonModule,
    //  UsersRoutingModule,
    CustomersModule,
    CommonModule,
    TwCoreUIFormModule,
    NgxSelectModule,
    ReactiveFormsModule,
    GridFilterModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    AgGridModule.withComponents([
      CustomTextFilterComponent,
      CheckboxFilterComponent,
      CustomDateFilterComponent
    ])
  ],
  providers: [UsersService, DatePipe, ToastrService, UtilityService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class UsersModule { }
