<div class="page-bg">
    <div class="row logout-section">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div
            class="nav-header-portal">{{'customerPortal'|translate}}</div>
        </div>
        <div class="col-1">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <h1>{{'Logout'|translate}}</h1>
            <div class="logout-content" role="status" aria-live="polite">
               {{'logoutConfirmmsg'|translate}}<br>
               {{'confirmLogout'|translate}}
            </div>
        </div>
    </div>
    <div class="row logout-section btn-container">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
        </div>
        <div class="col-1">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div>
            <button class="logout-btn"
            id="logout-btn"
            (click)="logout()"
            tabindex="0">
            {{'logoutbtn'|translate}}</button>
             </div>
             <div class="cancel-btn">
                <button class="red-secondary-btn"               
                (click)="cancel()"
                (keydown.Enter)="cancelTab()"
                tabindex="0">
                 {{'cancelBtn'|translate}}</button>
             </div>
        </div>
        
    </div>
</div>
