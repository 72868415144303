import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { AddPreferenceData } from './notification.model';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class CompanyNotificationService {
  private env: any = window['environment'];
  public notificationPreferenceData: any;
  public notificationData: INotificationData;
  gridLabelAuthoringDataObj: AddPreferenceData;
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private notificationUri = {
    getNotificationModel: 'company-notification',
    addPreference: 'customer-notification-preferences/modify',
    //deletePreference: 'customer-notification-preferences',
    getNotification: 'customer-notification-preferences'
  };
  public addPreferenceFormData: any = { 'page': '', 'data': '' };
  public modalData: IModalData = {
    'type': '',
    'heading': '',
    'msg': '',
    'removeBtnAlert': '',
    'cancel': ''
  };
  // private apiUrl = {
  //   tickets: 'wholesale-tickets',
  //   users: 'users',
  //   ticketCount:'wholesale-tickets/ticketCount'
  // };
  invokeEvent: Subject<any> = new Subject();
  constructor(private http: HttpClient, private authService: AuthService) { }

  getModalData(): IModalData {
    return this.modalData;
  }

  setModalData(_data: IModalData) {
    this.modalData = _data ? _data : undefined;
  }

  getSelectedNotificationsData(): INotificationData {
    return this.notificationData;
  }

  setSelectedNotificationsData(_data: INotificationData) {
    this.notificationData = _data ? _data : undefined;
  }

  callAddReferenceMethod() {
    this.invokeEvent.next()
  }

  public getProfileDetails() {
    const profile = {
      'contactEmail': this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
    };
    return profile;
  }

  public getNotificationsData(params?) {
    this.sendSpinnerFlag.next(true);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let queryString = ''; 
    if (params && params.cidn && params.customerLevel) {
      queryString =  '?customerLevel=' + params.customerLevel + '&cidn=' + params.cidn;
    } else if (params && params.customerLevel){
       queryString = '?customerLevel=' + params.customerLevel;
    }
    // if (params.loggedInEmail) {
    //   queryString = queryString + '&loggedInEmail=' + params.loggedInEmail;
    // }
    const url = this.env.apiPoint + this.notificationUri.getNotification + queryString;
    return new Promise((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          this.notificationPreferenceData = res['listdata'] ? res['listdata'] : [];
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          this.notificationPreferenceData = [];
          reject(err);
          //  return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCompanyNotificationModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        this.sendSpinnerFlag.next(true);
        const workListUrl = this.env.aemEndPoint + this.notificationUri.getNotificationModel;
        this.http.get<any>(workListUrl)
          .subscribe(
            res => {
              this.sendSpinnerFlag.next(false);
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.sendSpinnerFlag.next(false);
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        this.sendSpinnerFlag.next(false);
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public addPreference(params, cidn, _update, customerLevel, remove?) {
    this.sendSpinnerFlag.next(true);
    let payLoad = {
      "data": [
        {
          "cidn": cidn,
          "contactnumber": params.contactnumber ? params.contactnumber : ''
        }]
    };
    if (!remove) {
      // if (customerLevel === 'Y') {
        //const preference = params.notificationPreferences ? params.notificationPreferences.toLowerCase() : '';
        //let innerObj = {};
        let innerObj = {
            "assurance": {
              "notificationtype": encodeURI(params.notificationType.trim()),
              "sms": "N",
              "email": "Y",
              "customerlevel": customerLevel,
              "assuranceupdate_email": encodeURI(params.emailAddress.trim()),
			        "assuranceresolved_email": encodeURI(params.emailAddress.trim()),
			        "documentupdate_email": encodeURI(params.emailAddress.trim()),
              //"billingnotification_email": encodeURI(params.emailAddress.trim()),
              "operation": _update ? "update" : "create",
              "channel": "sms"
            }
          } 
        payLoad.data[0] = Object.assign(payLoad.data[0], innerObj);
    } else {
      let innerObj = {
        "assurance": {
          "notificationtype": "Billing",
          "sms": "",
          "email": "",
          "customerlevel": customerLevel,
          "assuranceupdate_email": "",
			    "assuranceresolved_email": "",
			    "documentupdate_email": "",
          "operation":"",
          "channel": ""
        }
      }
      payLoad.data[0] = Object.assign(payLoad.data[0], innerObj);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + this.notificationUri.addPreference, payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
}

export interface INotificationData {
  cidn: string;
  businessName: string;
  notificationtype: string;
  assuranceresolved_email: string;
  edit: string;
}

export interface IModalData {
  type: string;
  heading: string;
  msg: string;
  removeBtnAlert: string;
  cancel: string;
}