<div class="status-selector" ngSwitch="{{status | lowercase}}">
  <div class="status-container" *ngSwitchCase="'cancelled'">
    <img src="assets/images/icon-cancalled.svg" class="spriteNew"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'open'">
    <img src="assets/images/icon-open.svg" class="spriteNew"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'in progress'">
    <img src="assets/images/icon-in-progress.png" class="spriteNew"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'new'">
    <img src="assets/images/icon-new.png" class="spriteNew" alt="Status icon"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'resolved'">
    <span class="twssp-sprite sprite-s status-background-resolved"></span>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'closed'">
    <img src="assets/images/icon-closed.svg" class="spriteNew"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchCase="'awaiting info'">
    <img src="assets/images/icon-awaiting-info.png" class="spriteNew"/>
    <span class="status-text">{{status}}</span>
  </div>
  <div class="status-container" *ngSwitchDefault>
    <span class="status-background-icon"></span>
    <span class="status-text">{{status}}</span>
  </div>
</div>
