<div class="page-bg">
    <div class="row success-section" role="status" aria-live="polite">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <img class="success-img" alt="Amplitel Enquiry Success"
            src="../../assets/images/SuccessImage.png" />
            <h1>Thank you.</h1>
            <div class="success-content">
               {{'enquierSucessmsg'|translate}}
            </div>
        </div>
    </div>
</div>