<div class="header">
  <div class="emergency-contact">
    <p>To report any incident or emergency relating to an Amplitel site, please call 1800 955 655 immediately</p>
  </div>
  <div class="row header-row">
    <div class="col-md-12  banner-icon">
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 banner-icon">
      <button
        role="link"
        tabindex="0" class="skip-to-content-link" 
        (keydown.Enter)="focusToMainContent()"
        (keydown.Tab)="keydownTab($event)"
        (focus)="closeAdmin()"
        (click)="focusToMainContent()">
        Skip to main content
    </button>  
    <div class="col-md-8" (click)="navigateToHome()"
    id="header_logo" role="link"
    (focus)="closeAdmin()"
    (keydown.Enter)="router.navigateByUrl('/')"
    tabindex="0" alt="Amplitel home page"></div>
          </div>
    </div> -->
      <div class="col-md-2" (click)="navigateToHome()" class="header_logo" role="link"
        (keydown.Enter)="router.navigateByUrl('/site-locator')" tabindex="0" alt="Amplitel home page"><span class="sr-only">Amplitel home page</span></div>


      <!-- <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 dropdown" 
        >
    </div> -->
      <!-- <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1 logout-txt"
    >
</div> -->

<!-- <div class="col-xs-12 col-sm-12 col-md-8 logout-txt dropdown">
  <button class="secondary-btn ticket-btn" id="siteLocator" routerLink="/site-locator" routerLinkActive="active"
    tabindex="0">
    Site Locator</button>
  <button class="secondary-btn ticket-btn" id="siteAccess" routerLink="/site-access" tabindex="0">
    Site Access</button>
    -->
  
  <!-- <div class="admin-container-div"  (mouseleave)="closeService()">
    <button class="secondary-btn admin-btn customer-btn"  (mouseover)="openServiceDropdown()"
      routerLink="/services" role="button" attr.aria-label={{adminlabel}} tabindex="0" aria-haspopup="true"
      aria-expanded="true" (click)="selectModal('')">
      Service Offerings <img src="../../../assets/images/select-arrow.png" id="dd-arrow" alt=""
        class="dropdown-arrow" /></button>
    <div class="dropdown-content service" (mouseleave)="closeService()">
      <a href="#" (click)="selectModal('Colocation')" routerLink="/services">
        Colocation</a>
      <a href="#" (click)="selectModal('deploymentServices')" routerLink="/services">
        Deployment Services</a>
      <a href="#" (click)="selectModal('structuralServices')" routerLink="/services">Structural Services</a>
      <a href="#" (click)="selectModal('buildSuit')" routerLink="/services">Build to Suit </a>

    </div>
  </div> -->
  <!-- <button aria-label="my orders" routerLink="/my-orders" class="secondary-btn profile-btn" id="myOrders">
    My Orders</button> -->
    <!--
  <button class="secondary-btn" id="customerList" tabindex="0" routerLink="enquiries/myenquiries">My
    Enquiries</button>
  <button class="secondary-btn" id="customerList"   tabindex="0" routerLink="/AMS">AMS</button>
  <div class="admin-container-div"    (mouseleave)="closeAdmin()" >
    <button class="secondary-btn admin-btn" (mouseover)="openAdminDropdown()"
      *ngIf="(roles.includes('ampliteladmin') || 
      roles.includes('customeradmin'))" role="button" attr.aria-label={{adminlabel}} tabindex="0"
      aria-haspopup="true" aria-expanded="true">
      ADMIN <img src="../../../assets/images/select-arrow.png" id="dd-arrow" alt="" /></button>
    
    <div class="dropdown-content admin-dropdown" (mouseleave)="closeAdmin()" >
      <a href="#" routerLink="/manageCustomers/createCustomer">
        Create a customer</a>
      <a href="#" routerLink="/manageCustomers/customers">
        Customers</a>
      <a href="#" routerLink="/manageUsers/createUser">Create a user</a>
      <a href="#" routerLink="/manageUsers/myUser">My users</a>
      <a href="#" routerLink="/companynotifications">Company notifications</a>
    </div>
  </div>
  <button class="secondary-btn logout-btn" id="customerList" tabindex="0" routerLink="/logout">Logout</button>

</div> -->

      <div class="col-xs-12 col-sm-12 col-md-8 logout-txt dropdown header-height" *ngIf="isTerms();else emptyDiv">
        <ng-container *ngFor="let item of menuFilterRoleList">
          <button class="secondary-btn ticket-btn" id="siteLocator" (focus)="closeServiceDD()" [routerLink]="item?.href" routerLinkActive="active"
            tabindex="0" *ngIf="!item?.noUrl" attr.aria-label="{{item?.parentName}}">
            {{item?.parentName}}</button>

          <div class="admin-container-div"   (mouseleave)="closeServiceDD()" *ngIf="item?.noUrl">
            <button class="secondary-btn admin-btn customer-btn" (focus)="openServiceDropdownDD(item?.parentName)" (mouseover)="openServiceDropdownDD(item?.parentName)"
               role="button" attr.aria-label={{item?.parentName}} tabindex="0" aria-haspopup="true" 
              aria-expanded="true" (click)="selectModal('')">
              {{item?.parentName}} <img src="../../../assets/images/select-arrow.png" (focus)="openServiceDropdownDD(item?.parentName)"  id="dd-arrow" alt=""
                class="dropdown-arrow" /></button>
            <div class="dropdown-content service" (mouseleave)="closeService()" [ngStyle]="{'display': activeMenu == item?.parentName ? 'block' : 'none' }">
              <ng-container *ngFor="let childItem of item.childMenus; let i= index" (focusout)="closeServiceDD()">
                
                <a  [routerLink]="childItem?.href" [routerLinkActive]="['active-sub']" 
                [routerLinkActiveOptions]="{exact: true}"
                #rla="routerLinkActive"
                  (keydown.Enter)="closeServiceDD()" [queryParams]="childItem?.queryParams" (click)="selectModal(childItem?.queryParams?.tab)">
                  {{childItem?.name}}</a>
              </ng-container>
              
            </div>
          </div>
        </ng-container>
        <button *ngIf="menuFilterRoleList.length > 0" class="secondary-btn" id="customerList" routerLinkActive="active" tabindex="0" (focus)="closeServiceDD()" routerLink="/faq" aria-label="FAQ">FAQ</button>
        
        <button *ngIf="menuFilterRoleList.length > 0" class="secondary-btn" id="customerList" routerLinkActive="active" tabindex="0" routerLink="/logout" aria-label="Logout">Logout</button>

        <!--<button *ngIf="menuFilterRoleList.length == 0" class="secondary-btn logout-btn"></button> For margin purpose-->

      </div>

      <ng-template #emptyDiv>
      <div class="col-xs-12 col-sm-12 col-md-8 logout-txt dropdown header-height"></div>
      </ng-template>

      <div class="col-xs-12 col-sm-12 col-md-2 ">
        <ul class="side-menu pad-left-0">
          <li class="customer-portal customer-portal-section c-context-menu">
            <b> Customer Portal</b>
            <b *ngIf="selectedBusinessName">{{selectedBusinessName | slice:0:20}}</b>
          </li>
          <!-- <li><img src="../../assets/images/bell.png"/></li> -->
          <li *ngIf="isTerms()" (click)="viewProfile()" (keypress)="viewProfile()" tabindex="0" role="button"><img src="../../assets/images/profile-user.png" alt="User profile picture" /></li>
        </ul>
        <!-- <button class="btn btn-transparent"
      id="submit-enquiry-btn"
        routerLink="/enquiry"
        tabindex="0" role="button">
        Customer Portal</button> -->
      </div>
    </div>
  </div>
</div>
<!-- </header> -->


<ng-container *ngIf="aemLabelAuthoring">
  <div class="modal" id="exampleModal" role="dialog" aria-label="Profile" aria-modal="true" [ngClass]="{'profileModal':isModalOpen}">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="container p-0">
          <div class="row m-0 p-0">
            <div class="closeBtn">
              <button tabindex="0" (click)="closeModal()" id="uni" class="close closebtn" aria-label="Close" role="button">
                <i class="icon-icon-ui-cross icon-xs closeModel"></i>
              </button>
            </div>
            <div>
              <label class="profile-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
              <h3 class="profileName">{{profile.contactName}}</h3>
              <label class="email">{{profile.contactEmail}}</label>
              <!-- <p class="m-0 icon-icon-email" aria-label="email">{{profile.contactEmail}}</p> -->
              <div class="row m-0" [hidden]="!contactNumber" class="icon-icon-email">
               <app-cui-textfield [(model)]="phoneModel" class="phoneInput icon-icon-mobile"
                  [parentFormGroup]="myProfileForm" (blurred)="onBlurEvent()">
                </app-cui-textfield>
                <span>
                  <span id="descriptionEdit" aria-hidden="true">On clicking this you will be able to edit phone number</span>
                  <img src="/assets/images/edit-btn.png" class="editNumber" aria-describedby="descriptionEdit" aria-label="Edit Phone Number" role="button" tabindex="0" (keydown.enter)="toggleEdit()" (click)="toggleEdit()" alt="Edit">
                 </span>
                <span class="ok-btn">
                <button tabindex="0" id="chg" role="button" aria-label="change" [disabled]="!myProfileForm.valid || !myProfileForm.touched" class="primary-btn changeBtn"
                    (click)="onSubmit()">{{aemLabelAuthoring.labels.submit}}</button>
                  </span>
              </div>
              <div role="alert" aria-live="polite" class="warning">
                <p class="waring-msg" *ngIf="flag">
                  <i aria-hidden="true" class="warning-icon icon-xs"></i>{{aemLabelAuthoring.form.phoneNumberAlert}}</p>
              </div> 
              <div class="row available-test custom-table m-0" id="availableTestSection">
                <!-- <form [formGroup]="myProfileForm" class="form-width" id="myProfile" #myProfileUpdate="ngForm"> -->
                  <table class="table table-borderless" aria-label="Profile Information" *ngIf="rowData.length > 0">
                    <tr><th *ngFor="let col of tableCol">{{col}}</th></tr>
                    <tr *ngFor="let row of rowData">
                      <td> {{row.business}} </td>
                      <!-- <td> {{row.app}} </td> -->
                      <td> {{row.role}} </td>
                    </tr>
                  </table> 
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</ng-container>




<!-- <div class="col-xs-12 col-sm-12 col-md-8 logout-txt dropdown">
  <button class="secondary-btn ticket-btn" id="siteLocator" routerLink="/site-locator" routerLinkActive="active"
    tabindex="0">
    Site Locator</button>
  <button class="secondary-btn ticket-btn" id="siteAccess" routerLink="/site-access" tabindex="0">
    Site Access</button>
  
  <div class="admin-container-div"  (mouseleave)="closeService()">
    <button class="secondary-btn admin-btn customer-btn"  (mouseover)="openServiceDropdown()"
      routerLink="/services" role="button" attr.aria-label={{adminlabel}} tabindex="0" aria-haspopup="true"
      aria-expanded="true" (click)="selectModal('')">
      Service Offerings <img src="../../../assets/images/select-arrow.png" id="dd-arrow" alt=""
        class="dropdown-arrow" /></button>
    <div class="dropdown-content service" (mouseleave)="closeService()">
      <a href="#" (click)="selectModal('Colocation')" routerLink="/services">
        Colocation</a>
      <a href="#" (click)="selectModal('deploymentServices')" routerLink="/services">
        Deployment Services</a>
      <a href="#" (click)="selectModal('structuralServices')" routerLink="/services">Structural Services</a>
      <a href="#" (click)="selectModal('buildSuit')" routerLink="/services">Build to Suit </a>

    </div>
  </div>
  <button aria-label="my orders" routerLink="/my-orders" class="secondary-btn profile-btn" id="myOrders">
    My Orders</button>
  <button class="secondary-btn" id="customerList" tabindex="0" routerLink="enquiries/myenquiries">My
    Enquiries</button>
  <button class="secondary-btn" id="customerList" tabindex="0" routerLink="/AMS">AMS</button>
  <div class="admin-container-div"  (mouseleave)="closeAdmin()" >
    <button class="secondary-btn admin-btn" (mouseover)="openAdminDropdown()"
      *ngIf="(roles.includes('ampliteladmin') || 
      roles.includes('customeradmin'))" role="button" attr.aria-label={{adminlabel}} tabindex="0"
      aria-haspopup="true" aria-expanded="true">
      ADMIN <img src="../../../assets/images/select-arrow.png" id="dd-arrow" alt="" /></button>
    
    <div class="dropdown-content admin-dropdown" (mouseleave)="closeAdmin()" >
      <a href="#" routerLink="/manageCustomers/createCustomer">
        Create a customer</a>
      <a href="#" routerLink="/manageCustomers/customers">
        Customers</a>
      <a href="#" routerLink="/manageUsers/createUser">Create a user</a>
      <a href="#" routerLink="/manageUsers/myUser">My users</a>
      <a href="#" routerLink="/companynotifications">Company notifications</a>
    </div>
  </div>
  <button class="secondary-btn logout-btn" id="customerList" tabindex="0" routerLink="/logout">Logout</button>

</div> -->
