<div class="modal-header" *ngIf="data">
    <h3 class="modal-title" id="modal-title"><strong>{{data?.heading}}</strong></h3>
    <button type="button" class="close closebtn" aria-label="Close" (click)="close('')"><i class="icon-icon-ui-cross icon-xs closeicon"></i></button>
</div>
<div class="modal-body">
    <p>{{data?.msg}}</p>
</div>
<div class="modal-footer alert-btn">
    <button type="button" class="red-primary-btn xl-btn margin-5" (click)="close(data.type)">{{data?.removeBtnAlert}}</button>
    <button type="button" class="red-secondary-btn xl-btn margin-5" (click)="close('')">{{data?.cancel}}</button>
</div>