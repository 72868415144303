import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomDatePicker, CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
// import { CreateCustomerData } from '../customer.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { UtilityService } from '../../services/utility.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { UsersService } from 'src/app/users/users.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { DatePickerDirective } from 'ng2-date-picker';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-modify-customer',
  templateUrl: './modify-customer.component.html',
  styleUrls: ['./modify-customer.component.less']
})
export class ModifyCustomerComponent implements OnInit, OnDestroy {
  customerData: any;
  options: GlobalConfig;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public modifyCustomerLabels: any;
  // CreateCustomerData;
  public statusItems = [];

  public associatedCompanyData = [];
  isAsteriskRequired: boolean = false;
  isAsteriskRequiredVendor: boolean = false;
  optionsVendorServiceType: any[] = [];
  searchfromclearDate: any;
  searchtoclearDate: any;
  currentDate;
  dateFormat = 'dd/mm/yyyy';
  today = new Date();
  CurrentDate = this.today;
  toDatecount = 0;
  fromDatecount = 0;
  fromDate;
  toDate;
  endDate;
  searchfromDate: any = '';
  startDate;
  searchToDateFlag = false;
  todayformattedDate = (new Intl.DateTimeFormat('en-US').format(this.CurrentDate));
  formattedDate = {
    date: this.todayformattedDate
  };
  frommaxDate: any;
  istoDate: Boolean = false;
  fromflag: Boolean = false;
  public tominDate: any;
  moment = moment;
  tomaxDate: any;
  maxDate;
  searchtoDate: any = '';
  vendorField: boolean = false;
  public isAddAssociatedVisible: boolean = false;
  businessNames = [];
  setAMSAppNo: any;
  companyNameDropdown = [];
  public tableColAssociated: Array<string> = [];
  public associatedForDropdown = [
    "AMS",
    "SE",
    "AMS, SE"
  ]
  public amsAppOption = [
    "Yes",
    "No"
  ]


  customerRegex = /^[^,`=~#!<>+@]+$/;
  // cidnRegex = /^[0-9]+$/;
  cidnRegex = /^[0-9]{10}$/g
  abnRegex = /^\d{11}$/;
  msoIdRegex = /^[^,`=~#!<>+@]+$/;
  public startDatePicker: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'modifyVendorValidFrom',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: true,
    name: 'modifyVendorValidFrom',
    formControlName: 'modifyVendorValidFrom',
    isDisabled: false,
    isWeekendsDisabled: false,
  };

  public completeDatePicker: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'toDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: true,
    name: 'toDate',
    formControlName: 'modifyVendorValidTo',
    isDisabled: false,
    isWeekendsDisabled: false,
  };
  public modifyBusinessNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyBusinessName',
    tabindex: '',
    maxlength: '50',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifyBusiness',
    formControlName: 'modifyBusiness',
    isDisabled: false,
    showValidationIcon: true
  };
  public modifyCidnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessCidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessCidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyCidn',
    tabindex: '',
    maxlength: '10',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifyCidn',
    formControlName: 'modifyCidn',
    isDisabled: false,
    showValidationIcon: true
  };

  public modifySpidModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessCidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessCidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifySpid',
    tabindex: '',
    maxlength: '5',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifySpid',
    formControlName: 'modifySpid',
    isDisabled: false,
    showValidationIcon: true
  };
  public modifyAbnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessAbn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessAbn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyAbn',
    tabindex: '',
    maxlength: '11',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'modifyAbn',
    formControlName: 'modifyAbn',
    isDisabled: false,
    showValidationIcon: false
  };
  public modifyMsoIdModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessMsoid',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessMsoid',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyMsoid',
    tabindex: '',
    maxlength: '15',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'modifyMsoid',
    formControlName: 'modifyMsoid',
    isDisabled: false,
    showValidationIcon: false
  };
  public modifyVendorStatusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Vendor Status Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public modifyVendorSiteAccessModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Vendor Site Access Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public statusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public modifyParentCompanyModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Parent Company Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public modifyCompanyTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Company Type Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public modifyCustomerTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Customer Type Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public associatedCompanyDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'Associated Company Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };
  public amsAppAccessDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'AMS App Access Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };
  public modifyCustomerForm: FormGroup;

 
  vendorServiceType = ['SAR Vendor', 'EB Vendor', 'DG Vendor', 'Maintenance Vendor', 'TSSR Vendor', 'Construction Vendor', 'Project Partner'];
  @ViewChild('fromDatePicker') fromDatePicker: DatePickerDirective;
  @ViewChild('toDatePicker') toDatePicker: DatePickerDirective;
  parentCompany = ['First Suitable Solution and Stop', 'All Solutions'];
  companyType = ['Asset Owner', 'Infrastructure Owner', 'Licensee', 'Software Provider', 'Vendor'];
  customerType = ['MNO', 'Non-MNO'];
  vendorSiteAccess = ['Accredited', 'Banned from site', 'Unaccredited'];
  public vendorStatus = [
    "Active",
    "Inactive"
  ];
  filterCompany = [...this.companyType];
  

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder,
    private customerService: CustomerService, private toastr: ToastrService,
    private utility: UtilityService, private titleService: Title,
    private sharedService: SharedService,
    private userService: UsersService,
    private loader: LoadingServiceService,
    private commonService: CommonService,

  ) {
    this.titleService.setTitle('Amplitel Modify a Customer');
  }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.options = this.toastr.toastrConfig;
    this.getLabels();
    const CUSTOMER_DATA = this.customerService.getCreateCustomerData();
    let vendorServiceSelectedValue;
    let companyTypeSelectedValue;
    this.customerData = CUSTOMER_DATA;
    if (this.customerData) {
      this.modifyBusinessNameModel.inputText = this.customerData ? this.customerData.businessName : '';
      this.modifyCidnModel.inputText = this.customerData ? this.customerData.cidn : '';
      this.modifySpidModel.inputText = this.customerData.spid && this.customerData.spid.toLowerCase() !== 'empty' ? this.customerData.spid : '';
      this.modifyAbnModel.inputText = this.customerData ? this.customerData.abn : '';
      this.modifyMsoIdModel.inputText = this.customerData ? this.customerData.mso_id : '';
      if(typeof this.customerData.vendorServiceTypes === 'string'){
        vendorServiceSelectedValue = this.customerData.vendorServiceTypes.split(',').map(item =>item.trim());
      }else{
        vendorServiceSelectedValue = this.customerData ? this.customerData.vendorServiceTypes : '';
      }

      if(typeof this.customerData.companyType === 'string'){
        companyTypeSelectedValue = this.customerData.companyType.split(',').map(item =>item.trim());
      }else{
        companyTypeSelectedValue = this.customerData ? this.customerData.companyType : '';
      }
      
      // this.completeDatePicker = this.customerData ? this.customerData.vendorValidTo : '',
      this.associatedCompanyData = this.customerData ? this.customerData.associatedCompanies : [];

      // tslint:disable-next-line: max-line-length tslint:disable-next-line: radix
      const NO_OF_USERS = this.customerData.no_of_users ? parseInt(this.customerData.no_of_users) ? parseInt(this.customerData.no_of_users) : 0 : 0;
      this.modifyCidnModel.isDisabled = true;

      if (NO_OF_USERS) {
        this.modifyBusinessNameModel.isDisabled = true;
        // this.modifyCidnModel.isDisabled = true;
        if (this.customerData.spid && (this.customerData.spid !== '' && this.customerData.spid !== null && this.customerData.spid.toLowerCase() !== 'empty')) {
          this.modifySpidModel.isDisabled = true;
        }
      }
      this.modifyCustomerForm = this.fb.group({
        modifyBusiness: [this.modifyBusinessNameModel.inputText, [Validators.required, Validators.pattern(this.customerRegex)]],
        modifyCidn: ["", [Validators.required, Validators.pattern(this.cidnRegex)]],
        modifySpid: ['00', [Validators.required, Validators.pattern(this.utility.getShortNumberRegEx())]],
        modifyStatus: [this.customerData.status, [Validators.required]],
        modifyAbn: ['', [Validators.pattern(this.abnRegex)]],
        modifyMsoid: ['', [Validators.pattern(this.msoIdRegex)]],
        modifyParentCompany: [this.customerData.parentCompany],
        modifyCompanyType: [companyTypeSelectedValue],
        modifyCustomerType: [this.customerData.customerType],
        modifyVendorStatus: [this.customerData.vendorStatus],
        modifyVendorValidFrom: [this.customerData.vendorValidFrom],
        modifyVendorValidTo: [this.customerData.vendorValidTo],
        modifyVendorSiteAccess: [this.customerData.vendorSiteAccessStatus],
        modifyVendorServiceType: [vendorServiceSelectedValue],
        companyId: [undefined],
        associatedFor: [undefined],
        allowAMSAppAccess: [undefined],
      });
      this.modifyCustomerForm.updateValueAndValidity();
    } else {
      this.router.navigate(['manageCustomers/createCustomer']);
    }
    this.setBusinessNameDd();
    this.parentCompanyCondition(this.modifyCustomerForm?.get('modifyParentCompany')?.value);
    this.modifyCustomerForm?.get('modifyParentCompany')?.valueChanges.subscribe(option => {
      this.parentCompanyCondition(option)
    })
    this.modifyCustomerForm?.get('modifyVendorServiceType')?.value;
    //this.companyTypeCondition(this.modifyCustomerForm.get('modifyCompanyType')?.value);

    // this.modifyCustomerForm.get('modifyCompanyType')?.valueChanges.subscribe(value => {
    //   this.companyTypeCondition(value)
    // })

    const date = new Date(this.customerData?.vendorValidFrom);
    console.log(this.minMaxformatter(date))
    this.searchfromclearDate = this.minMaxformatter(date)

    const enddate = new Date(this.customerData?.vendorValidTo);
    console.log(this.minMaxformatter(enddate))
    this.searchtoclearDate = this.minMaxformatter(enddate)


  }

  // companyTypeCondition(value) {
  //   if (value === 'Asset Owner') {
  //     this.modifyCustomerForm.get('modifyCustomerType')?.setValidators([Validators.required]);
  //     this.isAsteriskRequired = true;
  //     this.isAsteriskRequiredVendor = false;
  //   } else if (value === 'Vendor') {
  //     this.vendorField = true;
  //     this.modifyCustomerForm.get('modifyVendorStatus')?.setValidators([Validators.required]);
  //     this.modifyCustomerForm.get('modifyVendorValidFrom')?.setValidators([Validators.required]);
  //     this.modifyCustomerForm.get('modifyVendorValidTo')?.setValidators([Validators.required]);
  //     this.modifyCustomerForm.get('modifyVendorSiteAccess')?.setValidators([Validators.required]);
  //     this.modifyCustomerForm.get('modifyVendorServiceType')?.setValidators([Validators.required]);
  //     this.isAsteriskRequiredVendor = true;
  //     this.isAsteriskRequired = false;
  //   }
  //   else {
  //     this.modifyCustomerForm.get('modifyCustomerType')?.clearValidators();
  //     this.isAsteriskRequired = false;
  //     this.isAsteriskRequiredVendor = false;
  //     this.vendorField = false;
  //   }
  //   this.modifyCustomerForm.get('modifyCustomerType')?.updateValueAndValidity();
  // }

  parentCompanyCondition(option){
    if (option === 'Amplitel') {
      this.filterCompany = this.companyType.filter(type => type !== 'Vendor');
      if (this.modifyCustomerForm?.get('modifyCompanyType')?.value === 'Vendor') {
        this.modifyCustomerForm?.get('modifyCompanyType')?.setValue('');
      }
    }
    else {
      this.filterCompany = [...this.companyType];
    }
  }

  getLabels() {
    this.customerService.getModifyCustomerModel().then((data: any) => {
      this.modifyCustomerLabels = data;
      this.statusItems = this.modifyCustomerLabels.form.statusItems;
      this.modifyBusinessNameModel.headerText = this.modifyCustomerLabels.form.businessName;
      this.modifyBusinessNameModel.placeholderCaption = this.modifyCustomerLabels.form.businessNamePlaceholder;
      this.modifyBusinessNameModel.validationMessage = this.modifyCustomerLabels.form.businessNameValidationMessage;
      this.modifyBusinessNameModel.requiredText = this.modifyCustomerLabels.form.businessNameRequiredMessage;
      this.modifyCidnModel.headerText = this.modifyCustomerLabels.form.cidn;
      this.modifyCidnModel.placeholderCaption = this.modifyCustomerLabels.form.cidnPlaceholder;
      this.modifyCidnModel.validationMessage = this.modifyCustomerLabels.form.cidnValidationMessage;
      this.modifyCidnModel.requiredText = this.modifyCustomerLabels.form.cidnRequiredMessage;
      this.modifySpidModel.headerText = this.modifyCustomerLabels.form.spid;
      this.modifySpidModel.placeholderCaption = this.modifyCustomerLabels.form.spidPlaceholder;
      this.modifySpidModel.validationMessage = this.modifyCustomerLabels.form.spidValidationMessage;
      this.modifySpidModel.requiredText = this.modifyCustomerLabels.form.spidRequiredMessage;
      this.modifyAbnModel.headerText = this.modifyCustomerLabels.form.abn;
      this.modifyAbnModel.placeholderCaption = this.modifyCustomerLabels.form.abnPlaceholder;
      this.modifyAbnModel.validationMessage = this.modifyCustomerLabels.form.anbValidationMessage;
      this.modifyMsoIdModel.headerText = this.modifyCustomerLabels.form.msoid;
      this.modifyMsoIdModel.placeholderCaption = this.modifyCustomerLabels.form.msoidPlaceholder;
      this.modifyMsoIdModel.validationMessage = this.modifyCustomerLabels.form.msoidValidationMessage;
      this.tableColAssociated = this.modifyCustomerLabels.form.associatedTableHeader;


    }).catch((error) => {
    });
  }

  onSubmit(): any {
    const associatedEntry = this.associatedCompanyData.map(item => ({
      companyId: item.companyId,
      associatedFor: item.associatedFor.toLowerCase(),
      allowAMSAppAccess: item.allowAMSAppAccess,
    }))
    let errMsgs: string = 'Unable to create/update the customer account in Enquiry System, please contact Level-1 support team';
    const DATA = this.modifyCustomerForm.value;
    this.customerService.modifyCustomer(DATA, this.customerData.cidn, associatedEntry).then((response) => {
      this.customerService.createCustomerFormData = this.modifyCustomerForm.value;
      this.customerService.createCustomerFormData['page'] = 'Modify';
      this.router.navigate(['manageCustomers/customers']);
    }).catch((err) => {
      if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
        this.showToasterMessage(this.modifyCustomerLabels.form.errMsg, '', this.options, 'error');
      } else {
        const msg = err.error.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      }
    });
  }

  cancelCreate() {
    this.router.navigate(['manageCustomers/customers']);
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.autoDismiss = true;
    this.options.timeOut = 10000;
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  ngOnDestroy() {
    // this.toastr.clear();
  }

  openAssociatedBoxModel() {
    this.isAddAssociatedVisible = true;
  }
  addAssociatedCompany() {
    if (this.associatedCompanyData.length === 0) {
      this.addedAssociateData();
      return;
    }

    const isAlreadyAdded = this.associatedCompanyData.some(item => {
      return item.companyName == this.modifyCustomerForm.value.companyId
    });

    if (!isAlreadyAdded) {
      this.addedAssociateData();
    } else {
      this.showToasterMessage(
        this.modifyCustomerLabels.form.multipleBusinessMessage,
        '',
        this.options,
        'error'
      );
    }
  }

  addedAssociateData() {
    let data = this.businessNames.filter(x=>{
      return x['Business name']==this.modifyCustomerForm.value.companyId;
    });
    let cidn = data.length?data[0].CIDN:'';
    this.associatedCompanyData.push({
      companyName: this.modifyCustomerForm.value.companyId,
      companyId: cidn,
      associatedFor: this.modifyCustomerForm.value.associatedFor,
      allowAMSAppAccess: this.modifyCustomerForm.value.allowAMSAppAccess,
    });
    this.closeBox();
  }


  removeAssociateRow(index) {
    this.associatedCompanyData.splice(index, 1);
  }


  isAssociatedSaveBtnDisabled() {
    return !(this.modifyCustomerForm.get('companyId')?.value && this.modifyCustomerForm.get('associatedFor')?.value && this.modifyCustomerForm.get('allowAMSAppAccess')?.value)
  }

  closeBox() {
    this.isAddAssociatedVisible = false;
    this.modifyCustomerForm.controls['companyId'].reset();
    this.modifyCustomerForm.controls['associatedFor'].reset();
    this.modifyCustomerForm.controls['allowAMSAppAccess'].reset();
  }

  setBusinessNameDd() {
    this.loader.setLoader(true)
    //const params = { infraco: 'false' };
    this.userService
      .getAllBusinessNamesForAdmin('').subscribe(res => {
        const businessRoleData: any = res.data;
        console.log(businessRoleData)
        this.businessNames = businessRoleData.BusinessNameDetails;
        this.businessNames.forEach((element) => {
          this.companyNameDropdown.push(element['Business name']);
        });
        this.loader.setLoader(false)
      });


  }

  onAssociatedCompanyChanged(value) {
    if (value === 'SE') {
      this.modifyCustomerForm.value.allowAMSAppAccess = 'No';
      this.setAMSAppNo = 'No';
      this.amsAppAccessDropDownModel.isDisabled = true;
    } else {
      this.modifyCustomerForm.value.allowAMSAppAccess = '';
      this.setAMSAppNo = '';
      this.amsAppAccessDropDownModel.isDisabled = false;
    }
  }

  getCurrentDateTimeInAEST() {
    const timeZone = 'Australia/Sydney';
    const now = moment.tz(timeZone);
    const day = now.date();
    const month = now.month() + 1;
    const year = now.year();
    return { year, month, day };
  }

  minMaxformatter(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,// as getMonth() count starts from 0
      day: date.getDate()
    };
  }


  onToDateChange($event) {
    this.searchToDateFlag = true;
    this.toDate = $event.date;
    // Configure highlighting
    if (this.toDate) {
      this.toDate = this.toDate.replace(/\//g, '-');
      this.toDate = this.toDate.split('-').reverse().join('-');
      this.searchtoDate = this.moment(this.toDate).isValid();
      if (this.toDatecount < 1) {
        this.searchtoDate = '';
      }
      this.toDatecount++;
      this.toDate = this.moment(this.toDate).format('DD-MMM-YYYY');
      // this.toDate = new Date(this.toDate);
      const setMinFromdate = this.toDate;
      if (this.toDatecount > 1 && (this.searchToDateFlag) && this.searchtoDate) {
        this.searchtoDate = this.moment(this.toDate).format('DD/MM/YYYY');
      }
      if (setMinFromdate < this.today && setMinFromdate.getFullYear() !== this.today.getFullYear()) {
        this.frommaxDate = {
          year: setMinFromdate.getFullYear(),
          month: setMinFromdate.getMonth() + 1,
          day: setMinFromdate.getDate() - 1
        };
      } else if (setMinFromdate < this.today && setMinFromdate.getFullYear() === this.today.getFullYear()) {
        this.frommaxDate = {
          year: setMinFromdate.getFullYear(),
          month: setMinFromdate.getMonth() + 1,
          day: setMinFromdate.getDate()
        };
      } else {
        this.frommaxDate = {
          year: this.today.getFullYear(),
          month: this.today.getMonth() + 1,
          day: this.today.getDate()
        };
      }
    }

    let parseDate = new Date(this.toDate)
    const payloadToDate = this.moment(parseDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss');
    console.log(payloadToDate, 'payload')
    this.modifyCustomerForm.get('modifyVendorValidTo')?.setValue(payloadToDate);

  }

  onFromDateChange($event) {
    this.fromDate = $event.date;
    this.searchfromDate = this.moment(this.fromDate, 'DD/MM/YYYY').format('DD MMM YYYY');
    let date = new Date(this.searchfromDate);
    this.tominDate = this.minMaxformatter(date);
    let dateAdded = new Date(date.setDate(date.getDate() + 30));
    this.maxDate = this.minMaxformatter(dateAdded);
    console.log(this.maxDate, dateAdded)
    this.fromDate = this.fromDate.replace(/\//g, '-');
    this.fromDate = this.fromDate.split('-').reverse().join('-');
    this.fromDate = this.moment(this.fromDate).format('DD-MMM-YYYY');
    this.searchtoclearDate = this.fromDate;
    this.searchtoDate = '';
    // this.completeDatePicker.isDisabled = false;
    let parseDate = new Date(this.fromDate)
    const payloadFromDate = this.moment(parseDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss');
    console.log(payloadFromDate, 'payload')
    this.modifyCustomerForm.get('modifyVendorValidFrom')?.setValue(payloadFromDate);
  }


  selectionChanged(event) {
    this.optionsVendorServiceType = event;
    console.log(event, 'type')
  }

  selectionChangedCompanyType(event) {
    const optionsCompanyType = event.map((item: any) => item.value);
    console.log(optionsCompanyType, 'company')

    //both
    if (optionsCompanyType.includes('Vendor') && optionsCompanyType.includes('Asset Owner')) {
      this.modifyCustomerForm?.get('modifyCustomerType')?.setValidators([Validators.required]);
      this.isAsteriskRequired = true;


      this.vendorField = true;
      this.modifyCustomerForm?.get('modifyVendorStatus')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorValidFrom')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorValidTo')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorSiteAccess')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorServiceType')?.setValidators([Validators.required]);
      this.isAsteriskRequiredVendor = true;
      

    }
    //only asset
    else if (optionsCompanyType.includes('Asset Owner')) {
      this.modifyCustomerForm?.get('modifyCustomerType')?.setValidators([Validators.required]);
      this.isAsteriskRequired = true;
      this.isAsteriskRequiredVendor = false;
      this.vendorField = false;
    }
    //only vendor
    else if (optionsCompanyType.includes('Vendor')) {
      this.vendorField = true;
      this.modifyCustomerForm?.get('modifyVendorStatus')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorValidFrom')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorValidTo')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorSiteAccess')?.setValidators([Validators.required]);
      this.modifyCustomerForm?.get('modifyVendorServiceType')?.setValidators([Validators.required]);
      this.isAsteriskRequiredVendor = true;
      this.isAsteriskRequired = false;
    }

    else {
      this.modifyCustomerForm?.get('modifyCustomerType')?.clearValidators();
      this.modifyCustomerForm?.get('modifyCustomerType')?.updateValueAndValidity();
      this.modifyCustomerForm?.get('modifyVendorStatus')?.clearValidators();
      this.modifyCustomerForm?.get('modifyVendorStatus')?.updateValueAndValidity();
      this.modifyCustomerForm?.get('modifyVendorValidFrom')?.clearValidators();
      this.modifyCustomerForm?.get('modifyVendorValidFrom')?.updateValueAndValidity();
      this.modifyCustomerForm?.get('modifyVendorValidTo')?.clearValidators();
      this.modifyCustomerForm?.get('modifyVendorValidTo')?.updateValueAndValidity();
      this.modifyCustomerForm?.get('modifyVendorSiteAccess')?.clearValidators();
      this.modifyCustomerForm?.get('modifyVendorSiteAccess')?.updateValueAndValidity();
      this.modifyCustomerForm?.get('modifyVendorServiceType')?.clearValidators();
      this.modifyCustomerForm?.get('modifyVendorServiceType')?.updateValueAndValidity();
      this.isAsteriskRequired = false;
      this.isAsteriskRequiredVendor = false;
      this.vendorField = false;
    }
  }


}
