import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
// import { CreateCustomerData } from './customer.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { DatePipe } from '@angular/common';
import { LoadingServiceService } from '../services/loading-service.service';
// import { GridData } from '../../../shared/models/common-model';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

export interface CreateCustomerDataPayload {
  businessName: string;
  cidn: string;
  spid: string;
  status: string;
  abn: number;
  mso_id: string;
  createdBy?: string;
  createdDate?: string;
  modifiedBy: string;
  modifiedDate: string;
  companyType: string;
  parentCompany: string;
  customerType?: string;
  vendorStatus?: string;
  vendorValidFrom?: string;
  vendorValidTo?: string;
  vendorSiteAccessStatus?: string;
  vendorServiceTypes?: string;
  associatedCompanies?: string[];
  existingCidn?:string;
}

@Injectable()
export class CustomerService {
  spinnerflag = false;
 // private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
 // spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  createCustomerData: any;
  public gridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  private env: any = window['environment'];
  public createUserLabelAuthoringDataObj: any;
  //CreateCustomerData;
  public modifyUserLabelAuthoringDataObj: any;
  // CreateCustomerData;
  public gridLabelAuthoringDataObj: any;
  public createCustomerFormData: any;

  private customerUri = {
    customer: 'users/customer',  // 'customer',
    getCreateCustomerModel: 'createCustomer',
    customerGridHeader: 'customer-grid-header',
    customerGridLabel: 'customer-authoring-data',
    customerGridData: 'customers'
  };
  private modifyCustomerUri = {
    getModifyCreateCustomer: 'modifyCustomer',
    modifyCustomer: 'users/customer'
  };
  constructor(private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private loader: LoadingServiceService) { }

  public setCreateCustomerData(data) {
    this.createCustomerData = data;
  }

  public getCreateCustomerData() {
    return this.createCustomerData;
  }

  public createCustomer(params, associated) {

    //  this.sendSpinnerFlag.next(true);
    this.loader.setLoader(true);
    let payLoad : {data: CreateCustomerDataPayload}={
      "data":{
        'businessName': encodeURIComponent(params.businessName.trim()),
        'cidn': encodeURIComponent(params.cidn.trim()),
        'spid': params.spid ? params.spid.trim() ? params.spid.trim() : '' : '',
        'status': params.status.trim(),
        'abn': parseInt(params.abn.trim()),
        'mso_id': params.msoid.trim(),
        'createdBy': this.authService.user ? this.authService.user.profile.username : window['environment']['email'],
        'createdDate': this.datePipe.transform(new Date(), 'dd MMM yyyy'),
        'modifiedBy': 'null',
        'modifiedDate': 'null',
        'companyType' : params.companyType?.join(', '),
        'parentCompany': params.parentCompany,
      }
    };

    if(params.companyType.includes('Asset Owner')){
      payLoad = {
        ...payLoad, 
        data: {
          ...payLoad["data"],
          'customerType': params.customer_type
        }
      };
    }
    
    if(params.companyType.includes('Vendor')){
      payLoad = {
        "data": {
          ...payLoad.data,
          'vendorStatus': params.vendor_status,
          'vendorValidFrom':params.vendorValidFrom,
          'vendorValidTo':params.vendorValidTo,
          'vendorSiteAccessStatus': params.vendorSiteAccessStatus,
          'vendorServiceTypes': params.vendorServiceType?.join(', '),
          'associatedCompanies': associated,
        }
      };
    }
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      // this.http.post<any>(this.env.apiPointOGW + this.customerUri.customer, payLoad, httpOptions)
      this.http.post<any>(this.env.apiPoint + this.customerUri.customer, payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          this.loader.setLoader(false);
          //  this.sendSpinnerFlag.next(false);
        }, err => {
          this.loader.setLoader(false);
          //  this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  public getCreateMyUserModel() {
    return new Promise((resolve, reject) => {
      if (!this.createUserLabelAuthoringDataObj) {
        // this.sendSpinnerFlag.next(true);
        this.loader.setLoader(true);
        const CREATE_CUSTOMER_URL = this.env.aemEndPoint + this.customerUri.getCreateCustomerModel;
        this.http.get<any>(CREATE_CUSTOMER_URL)
          .subscribe(
            res => {
              //  this.sendSpinnerFlag.next(false);
              this.loader.setLoader(false);
              this.createUserLabelAuthoringDataObj = res.data.content;
              resolve(this.createUserLabelAuthoringDataObj);
            },
            err => {
              // this.sendSpinnerFlag.next(false);
              this.loader.setLoader(false);
              this.createUserLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        // this.sendSpinnerFlag.next(false);
        this.loader.setLoader(false);
        resolve(this.createUserLabelAuthoringDataObj);
      }
    });
  }

  public getModifyCustomerModel() {
    return new Promise((resolve, reject) => {
      if (!this.modifyUserLabelAuthoringDataObj) {
        //  this.sendSpinnerFlag.next(true);
        this.loader.setLoader(true);
        const MODIFY_CUSTOMER_URL = this.env.aemEndPoint + this.modifyCustomerUri.getModifyCreateCustomer;
        this.http.get<any>(MODIFY_CUSTOMER_URL)
          .subscribe(
            res => {
              // this.sendSpinnerFlag.next(false);
              this.loader.setLoader(false);
              this.modifyUserLabelAuthoringDataObj = res.data.content;
              resolve(this.modifyUserLabelAuthoringDataObj);
            },
            err => {
              //  this.sendSpinnerFlag.next(false);
              this.loader.setLoader(false);
              this.modifyUserLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
      //  this.sendSpinnerFlag.next(false);
        this.loader.setLoader(false);
        resolve(this.modifyUserLabelAuthoringDataObj);
      }
    });
  }

  public modifyCustomer(params, existingCidn: string, associatedEntry:any) {
   // this.sendSpinnerFlag.next(true);
   this.loader.setLoader(true);


   let payLoad : {data: CreateCustomerDataPayload}={
    "data":{
      'businessName': encodeURIComponent(params.modifyBusiness.trim()),
      'cidn': encodeURIComponent(params.modifyCidn.trim()),
      'spid': params.modifySpid.trim(),
      'status': params.modifyStatus.trim(),
      'abn': parseInt(params.modifyAbn),
      'mso_id': params.modifyMsoid,
      'modifiedBy': this.authService.user ? this.authService.user.profile.username : window['environment']['email'],
      'modifiedDate': this.datePipe.transform(new Date(), 'dd MMM yyyy'),
      'existingCidn': existingCidn,
      'parentCompany': params.modifyParentCompany,
      'companyType' : params.modifyCompanyType?.join(', '),
    }
  };

  if(params.modifyCompanyType.includes('Asset Owner')){
    payLoad = {
      ...payLoad, 
      data: {
        ...payLoad["data"],
        'customerType': params.modifyCustomerType
      }
    };
  }
  
  if(params.modifyCompanyType.includes('Vendor')){
    payLoad = {
      "data": {
        ...payLoad.data,
        'vendorStatus': params.modifyVendorStatus,
        'vendorValidFrom':params.modifyVendorValidFrom,
        'vendorValidTo':params.modifyVendorValidTo,
        'vendorSiteAccessStatus': params.modifyVendorSiteAccess,
        'vendorServiceTypes': params.modifyVendorServiceType?.join(', '),
        'associatedCompanies': associatedEntry,
      }
    };
  }
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //   'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      // this.http.put<any>(this.env.apiPointOGW + this.customerUri.customer, payLoad, httpOptions)
      this.http.put<any>(this.env.apiPoint + this.customerUri.customer, payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          this.loader.setLoader(false);
        }, err => {
          this.loader.setLoader(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCustomerListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.customerUri.customerGridLabel;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.customerUri.customerGridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params) {
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    return new Promise<any>((resolve, reject) => {
      // this.http.get<any>(this.env.apiPointOGW + this.customerUri.customer, httpOptions)
      this.http.get<any>(this.env.apiPoint + this.customerUri.customer, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['wholesale-customers'];
          resolve(true);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCustomerData(val) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      // this.http.get<any>(this.env.apiPointOGW + this.customerUri.customer + '/' + val.cidn, httpOptions)
      this.http.get<any>(this.env.apiPoint + this.customerUri.customer + '/' + val.cidn, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
}
