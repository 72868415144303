import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";

@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [
   ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports:[
    LoadingSpinnerComponent
  ]
})
export class LoadingSpinnerModule { }
