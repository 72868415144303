import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'tw-core-ui';
import { CustomerListComponent } from './customers/customer-list/customer-list.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LogoutComponent } from './logout/logout.component';
import { RouteGuardService } from './services/route-guard.service';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { ModifyUserComponent } from './users/modify-user/modify-user.component';
import { MyUserComponent } from './users/my-user/my-user.component';
import { AddPreferenceComponent } from './company-notifications/add-preference/add-preference.component';
import { NotificationPreferencesComponent } from './company-notifications/notification-preferences/notification-preferences.component';
import { CreateCustomerComponent } from './customers/create-customer/create-customer.component';
import { ModifyCustomerComponent } from './customers/modify-customer/modify-customer.component';

import { BillingHomeComponent } from "./billing/billing-home/billing-home.component";
import { EnquirySuccessComponent } from './enquiry-success/enquiry-success.component';
import { EnquiryErrorComponent } from './enquiry-error/enquiry-error.component';
import { AcknowledgePageComponent } from './acknowledge-page/acknowledge-page.component';
import { WorklistComponent } from './tickets/worklist/worklist.component';
import { AccessInfoComponent } from './site-access/accessinfo.component';
import { TicketSummaryComponent } from './tickets/ticket-summary/ticket-summary.component';
import { environment } from './../environments/environment';
// import { SetDefaultBusinessComponent } from './set-default-business/set-default-business.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './faq/faq.component';

const env = window['environment'] || environment;
const auth = env.production ? [AuthGuardService] : [];

const routes: Routes = [
  { path: 'logout', canActivate: [...auth], component: LogoutComponent },
  { path: 'enquiry', canActivate: [...auth], component: EnquiryFormComponent },
  { path: 'acknowledge', canActivate: [...auth], component: AcknowledgePageComponent },
  { path: 'terms-and-conditions', canActivate: [...auth], component: TermsAndConditionsComponent },

  {
    path: 'success', component: EnquirySuccessComponent,
    canActivate: [RouteGuardService, ...auth], data: { path: 'success' }
  },
  {
    path: 'error', component: EnquiryErrorComponent,
    canActivate: [...auth], data: { path: 'error' }
  },
  {
    path: 'manageUsers/createUser',
    canActivate: [...auth, RouteGuardService], data: { path: 'createUser' },
    component: CreateUserComponent
  },
  {
    path: 'manageUsers/myUser',
    canActivate: [...auth, RouteGuardService], data: { path: 'myUser' },
    component: MyUserComponent
  },
  {
    path: 'manageUsers/modifyUsers',
    canActivate: [...auth, RouteGuardService], data: { path: 'modifyUsers' },
    component: ModifyUserComponent
  },
  {
    path: 'manageCustomers/createCustomer',
    canActivate: [...auth, RouteGuardService], data: { path: 'createCustomer' },
    component: CreateCustomerComponent
  },
  {
    path: 'manageCustomers/customers',
    canActivate: [...auth, RouteGuardService], data: { path: 'customers' },
    component: CustomerListComponent
  },
  {
    path: 'manageCustomers/modifyCustomers',
    canActivate: [...auth, RouteGuardService], data: { path: 'modifyCustomers' },
    component: ModifyCustomerComponent
  },
  {
    path: 'billing', canActivate: [...auth, RouteGuardService],
    data: { path: 'billing' }, component: BillingHomeComponent
  },
  {
    path: 'companynotifications', canActivate: [...auth, RouteGuardService],
    data: { path: 'companynotifications' }, component: NotificationPreferencesComponent
  },
  {
    path: 'company-notifications-add-preference', canActivate: [...auth, RouteGuardService],
    data: { path: 'company-notifications-add-preference' }, component: AddPreferenceComponent
  },
  {
    path: 'company-notifications-edit-preference', canActivate: [...auth, RouteGuardService],
    data: { path: 'company-notifications-edit-preference' }, component: AddPreferenceComponent
  },
  {
    path: 'enquiries/myenquiries', canActivate: [...auth], component: WorklistComponent
  },
  {
    path: 'site-access', canActivate: [...auth], component: AccessInfoComponent
  },
  {
    path: 'faq', canActivate: [...auth], component: FaqComponent
  },
  {
    path: 'site-locator',canActivate: [...auth], loadChildren: () => import('./site-locator/site-locator.module')
      .then(m => m.SiteLocatorModule)
  },
  {
    path: 'services',canActivate: [...auth], loadChildren: () => import('./services-page/services-page.module')
      .then(m => m.ServicesPageModule)
  },
  {
    path: 'my-orders/create-orders',canActivate: [...auth], loadChildren: () => import('./my-orders/my-orders.module')
      .then(m => m.MyOrdersModule)
  },
  {
    path: 'my-orders/track-orders',canActivate: [...auth], loadChildren: () => import('./track-order/track-order.module')
      .then(m => m.TrackOrderModule)
  },
  {
    path: 'my-orders/my-documents',canActivate: [...auth], loadChildren: () => import('./my-quotes/my-quotes.module')
      .then(m => m.MyQuotesModule)
  },
  {
    path: 'reports',canActivate: [...auth], loadChildren: () => import('./reports/reports.module')
      .then(m => m.ReportsModule)
  },
  {
    path: 'my-orders/products',canActivate: [...auth], loadChildren: () => import('./products/products.module')
      .then(m => m.ProductsModule)
  },
  { path: 'AMS',pathMatch:'full',component: LandingPageComponent, canActivate: [...auth]},
  { path: 'ticket-summary/:cidn/:ticketId', canActivate: [...auth], component: TicketSummaryComponent },
  // { path: 'set-default-business', canActivate: [...auth], component: SetDefaultBusinessComponent },
//   { path: '',  canActivate: [...auth], loadChildren: () => import('./site-locator/site-locator.module')
//   .then(m => m.SiteLocatorModule) 
// },
  // { path: '',  canActivate: [...auth], redirectTo:'/site-locator', pathMatch:'full'
  // },
  { path: '**', pathMatch: 'full',loadChildren: () => import('./site-locator/site-locator.module')
  .then(m => m.SiteLocatorModule), canActivate: [...auth] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //  onSameUrlNavigation: 'ignore',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
