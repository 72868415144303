
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { componentDestroyed } from 'ng2-rx-destroyable-component';
import { WorklistStatusRendererComponent } from '../worklist-status-renderer/worklist-status-renderer.component';
// import { GridData } from '../../../shared/models/common-model';
// import { WorkListJeopardyRendererComponent } from './work-list-jeopardy-renderer/work-list-jeopardy-renderer.component';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

@Injectable()
export class WorklistService {
  private env: any = window['environment'];
  private apiUrl = {
    gridHeader: 'worklist-grid-header',
    workListUri: 'worklist',
    tickets:'service-management/tickets'
  };
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  public worklistGridHeader: any = [];
  public worklistGridData: any = [];
  public gridHeaderDataObj: any = [];
  public worklistLabelAuthoring: any;
  public gridDataObj: any = [];
  payLoad: any;
  getPayload: any;

  constructor(private http: HttpClient, private authService: AuthService) {}

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.apiUrl.gridHeader)
        .subscribe(res => {
          res.headers.forEach((x: any) => {
            if (x.cellRendererFramework) {
              if (x.headerName === 'Status') {
                x.cellRendererFramework = WorklistStatusRendererComponent
              }
            }
          });
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params) {
    this.payLoad = params || {
      'data': {}
    };
    const cidn = this.payLoad['data'].cidn;
    const isClosedTicket=this.payLoad['data'].isClosedTicket;
  //   this.getPayload = '';
  //   if (this.payLoad['data'].hasOwnProperty('id')  && (!this.payLoad['data'].hasOwnProperty('fromLoggedDate')) &&
  //   (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
  //    this.getPayload = '?id=' + this.payLoad['data'].id;
  //  }

  //  if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('fromLoggedDate') &&
  //   (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
  //    this.getPayload = '?id=' + this.payLoad['data'].id + '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
  //  }

  //  if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate') &&
  //   (!this.payLoad['data'].hasOwnProperty('fromLoggedDate'))) {
  //    this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
  //  }

  //  if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate')  &&
  //   this.payLoad['data'].hasOwnProperty('fromLoggedDate')) {
  //    this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate +
  //    '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
  //  }

  //  if (this.payLoad['data'].hasOwnProperty('fromLoggedDate') && this.payLoad['data'].hasOwnProperty('toLoggedDate') &&
  //   (!this.payLoad['data'].hasOwnProperty('id'))) {
  //     this.getPayload = '?fromLoggedDate=' + this.payLoad['data'].fromLoggedDate + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
  //  }

  //  if (this.payLoad['data'].hasOwnProperty('closedTicket')) {
  //   this.getPayload = this.getPayload ? (this.getPayload + '&' + this.payLoad['data'].closedTicket) :
  //                     ('?' + this.payLoad['data'].closedTicket);
  // }
  
    this.payLoad = this.getPayload ? this.getPayload : '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };

    return new Promise<any>((resolve, reject) => {
      this.payLoad = '?cidn=' + cidn + '&isClosedTicket='+isClosedTicket;
      this.http.get<any>(this.env.apiPoint + this.apiUrl.tickets + this.payLoad ,httpOptions)
        .subscribe(res => {
          this.worklistGridData = res['result'];
          resolve(true);
        }, err => {
          this.worklistGridData = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });   
  }

  public getWorkListModel() {
    return new Promise((resolve, reject) => {
      if (!this.worklistLabelAuthoring) {
        const worklistUrl = this.env.aemEndPoint + this.apiUrl.workListUri;
        this.http.get<any>(worklistUrl)
      /*   .takeUntil(componentDestroyed(this)) */
        .subscribe(
          res => {
            this.worklistLabelAuthoring = res.data.content;
            resolve(this.worklistLabelAuthoring);
          },
          err => {
            this.worklistLabelAuthoring = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
        } else {
          resolve(this.worklistLabelAuthoring);
        }
    });
  }
}
