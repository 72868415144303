import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MutliSelectComponent } from './mutli-select/mutli-select.component';

import { NgxSelectModule } from 'ngx-select-ex';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from 'ng2-translate';
import { TwCoreUIFormModule } from 'tw-core-ui';


@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    NgxSelectModule,
    MatFormFieldModule,
    MatSelectModule,
    TwCoreUIFormModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    TranslateModule.forRoot(),
    MatAutocompleteModule,
    MatTooltipModule,
    
  ],
  // exports: [MutliSelectComponent],
  providers:[MutliSelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class SharedModule { }
