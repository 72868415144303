<div class="page-bg">
  <div class="container-fluid page-container create-customer" *ngIf="modifyCustomerLabels">
    <h1 class="page-header" id="modifyCustomerhead">{{modifyCustomerLabels.pageHeader.pageHeaderLabel}} {{customerData.businessName}}</h1>
    <div class="wrapper">
      <h2 class="create-customer-header" automationType="label" automationId="lblChoosePlan">
        {{modifyCustomerLabels.pageHeader.pageSubHeaderLabel}}
      </h2>
      <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
      <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
      <form [formGroup]="modifyCustomerForm" id="modifyCustomer" #modifyCustomerTicket="ngForm">
        <div class="form-group">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 userNameWidth">
              <app-cui-textfield [(model)]="modifyBusinessNameModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div">
              <app-cui-textfield [(model)]="modifyCidnModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="dropdown-contact-options col-xs-12 col-sm-4 col-lg-3 col-md-3">
              <label class="statusLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="modifyStatusHeader"
                attr.aria-label={{modifyCustomerLabels.form.statusModel}} role="tabpanel">
                {{modifyCustomerLabels.form.statusModel}}
              </label>
              <app-cui-searchable-dropdown name="modifyStatus" [formControlName]="'modifyStatus'" [model]="statusModel"
                [labelName]="modifyCustomerLabels.form.statusModel"
                [placeholder]="modifyCustomerLabels.form.statusPlaceholder" [isRequired]=true [items]=statusItems
                automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false id="statusDropdown"
                class="user-dropDown"
                [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.modifyStatus.invalid && modifyCustomerForm.controls.modifyStatus.touched )}"
                required>
              </app-cui-searchable-dropdown>
              <img src="../../../assets/images/select-arrow.png" 
              class="dropdown-arrow" alt=""/>
              <div class="row ml-0" aria-live="assertive">
                <span role="alert"
                  *ngIf="modifyCustomerForm.controls.modifyStatus.invalid && modifyCustomerForm.controls.modifyStatus.touched"
                  class="help-block errorMsg">
                  {{modifyCustomerLabels.form.statusRequiredMessage}}
                </span>
              </div>
              <span class="asterisk_input"> </span>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-xs-12 col-sm-2 col-lg-2 col-md-2 cidn-div spid">
              <app-cui-textfield [(model)]="modifySpidModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
          </div> -->
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="modifyAbnModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="modifyMsoIdModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back">Parent company</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="modifyParentCompany" formControlName="modifyParentCompany"
                [items]="companyNameDropdown" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="modifyParentCompanyModel" [labelName]="'Parent company'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back statusLbl checkLbl asteriskdropdown">Company type</div>
              <!-- <app-cui-searchable-dropdown #subCategoryTypes name="modifyCompanyType" formControlName="modifyCompanyType"
                [items]="filterCompany" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="modifyCompanyTypeModel" [labelName]="'Company type'"></app-cui-searchable-dropdown> -->
  
                <ngx-select [items]="filterCompany" [aria-haspopup]="listbox" [optionValueField]="modifyCompanyType" #selectg 
                [keepSelectedItems]="false" role="combobox" id="dropdownListId"
               aria-label="label for the select the role"   [aria-expanded]="false"
               [aria-controls]="'dropdownListId'" attr.aria-label="Role"
               tabIndex="0" [formControlName]="'modifyCompanyType'" [label]="'Role'" placeholder="Please select"
               [multiple]=" true" (selectionChanges)="selectionChangedCompanyType($event)"></ngx-select>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4 col-md-4 cidn-div div-min-width" *ngIf="isAsteriskRequired">
              <div class="step-back statusLbl checkLbl" [ngClass]="{'asteriskdropdown': isAsteriskRequired}">Customer type</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="modifyCustomerType" formControlName="modifyCustomerType"
                [items]="customerType" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="modifyCustomerTypeModel" [labelName]="'Customer type'"></app-cui-searchable-dropdown>
            </div>
          </div>
          <div class="row" *ngIf="vendorField">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Status</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="modifyVendorStatus" formControlName="modifyVendorStatus"
                [items]="vendorStatus" class="step-backdropdown" [placeholder]="'Select Vendor Status'"
                [model]="modifyVendorStatusModel" [labelName]="'Vendor Status'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Valid From</div>
              <div class="date-picker">
                <app-cui-datepicker class="datepicker_core" attr.aria-label="from date"
                [datePlaceholder]="startPlaceholder" [modelDate]="searchfromclearDate"
                [datePickerId]="'startDatePicker'" [maxDate]="currentDate"
                (dateSelected)="onFromDateChange($event)" [navigation]="false" [model]="startDatePicker">
              </app-cui-datepicker>
            </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Valid To</div>
              <div class="date-picker" >
                <app-cui-datepicker class="datepicker_core" [datePlaceholder]="startPlaceholder"
                attr.aria-label="to date" [datePickerId]="'completeDatePicker'" [minDate]="tominDate"
                (dateSelected)="onToDateChange($event)" [modelDate]="searchtoclearDate" 
                 [navigation]="false" [model]="completeDatePicker" >
              </app-cui-datepicker>
            </div>
            </div>
          </div>
          <div class="row" *ngIf="vendorField">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Site Access</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="modifyVendorSiteAccess" formControlName="modifyVendorSiteAccess"
                [items]="vendorSiteAccess" class="step-backdropdown" [placeholder]="'Select Vendor Site Access'"
                [model]="modifyVendorSiteAccessModel" [labelName]="'Vendor Site Access'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <label class="statusLbl checkLbl" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}" automationType="label" attr.aria-label="Role" for="dropdownListId">
                Vendor Service Type
              </label>
              <ngx-select [items]="vendorServiceType" [aria-haspopup]="listbox" [optionValueField]="modifyVendorServiceTypeModel" #selectg 
                   [keepSelectedItems]="false" role="combobox" id="dropdownListId"
                  aria-label="label for the select the role"   [aria-expanded]="false"
                  [aria-controls]="'dropdownListId'" attr.aria-label="Role"
                  tabIndex="0" [formControlName]="'modifyVendorServiceType'" [label]="'Role'" placeholder="Select Vendor Service Type"
                  [multiple]=" true" (selectionChanges)="selectionChanged($event)"></ngx-select>
            </div>
              <!--START HERE >>>>>>>>>>> ASSOCIATED COMPANY -->
           <table class="table" *ngIf="associatedCompanyData.length > 0">
            <caption class="sr-only">User role details</caption>
            <tr><th *ngFor="let col of tableColAssociated">{{col}}</th></tr>
            <tr *ngFor="let company of associatedCompanyData; let i = index">
                <td> {{company.companyName}} </td>
                <td> {{company.associatedFor}} </td>
                <td> {{company.allowAMSAppAccess}} </td>
                <td> <button type="button" tabindex="0"
                  class="secondary-btn xl-btn p-0 m-0 remove-btn"
                  (click)="removeAssociateRow(i)">
                  <span class="icon-icon-ui-cross" aria-hidden="true"></span>{{modifyCustomerLabels.form.removeText}}
              </button> </td>
            </tr>
        </table>                
        <div *ngIf="!isAddAssociatedVisible" class="addRole">
            <button type="button" id="addrole" class="secondary-btn addroleBtn m-0 p-0" (click)="openAssociatedBoxModel()">
                <app-cui-svg-icon aria-hidden="true" [icon]="'icon-circlePlus'" class="svg-icon">
                </app-cui-svg-icon>{{modifyCustomerLabels.form.addAssociatedCompany}}
            </button>
        </div>
        <div class="row borderBox" *ngIf="isAddAssociatedVisible">
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4">
                <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblbusinessName"
                    id="businessNameHeader" attr.aria-label={{modifyCustomerLabels.form.businessName}}>
                    {{modifyCustomerLabels.form.businessName}}
                </label>
                <app-cui-searchable-dropdown #companyId name="companyId"
                    [formControlName]="'companyId'" [model]="businessNameDropDownModel"
                    [labelName]="modifyCustomerLabels.form.businessName"
                    [placeholder]="modifyCustomerLabels.form.businessPlaceHolder" [isRequired]=true
                    [items]=companyNameDropdown automationType="dropdown" automationId="drpdwnbusinessType"
                    [noAutoComplete]=false id="companyId" class="user-dropDown dd-padding"
                    >
                </app-cui-searchable-dropdown>
            </div>
          
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="roleHeader"
                    attr.aria-label={{modifyCustomerLabels.form.associatedFor}}>
                    {{modifyCustomerLabels.form.associatedFor}}
                </label>
                <app-cui-searchable-dropdown name="associatedFor" [formControlName]="'associatedFor'"
                    [model]="associatedCompanyDropDownModel" [labelName]="modifyCustomerLabels.form.associatedFor"
                    [placeholder]="modifyCustomerLabels.form.associatedselectPlaceholder" [items]=associatedForDropdown (ngModelChange)='onAssociatedCompanyChanged($event)' [isRequired]=true
                    automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false 
                    class="user-dropDown dd-padding" 
                    >
                </app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
              <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="roleHeader"
                  attr.aria-label={{modifyCustomerLabels.form.allowAMSAppAccess}}>
                  {{modifyCustomerLabels.form.allowAMSAppAccess}}
              </label>
              <app-cui-searchable-dropdown name="allowAMSAppAccess" [formControlName]="'allowAMSAppAccess'"
                  [model]="amsAppAccessDropDownModel" [(ngModel)]="setAMSAppNo" [labelName]="modifyCustomerLabels.form.allowAMSAppAccess"
                  [placeholder]="modifyCustomerLabels.form.selectPlaceholder" [isRequired]=true [items]=amsAppOption
                  automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false 
                  class="user-dropDown dd-padding" [disabled]="amsAppAccessDropDownModel.isDisabled"
                  >
              </app-cui-searchable-dropdown>
          </div>
            <div class="button-container myuser-button-container col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <button type="button" class="red-primary-btn xl-btn mr-3 enquiry-submit-btn" 
                [disabled]="isAssociatedSaveBtnDisabled()" 
                [ngClass]="isAssociatedSaveBtnDisabled() ? 'primary-btn-disabled dis-btn':''" 
                (click)="addAssociatedCompany()">{{modifyCustomerLabels.form.save}}
                </button>
                <button type="button" class="secondary-btn xl-btn addroleCancel"
                    (click)="closeBox()">{{modifyCustomerLabels.form.cancel}}
              </button>
            </div>
        </div>
          <!--END HERE >>>>>>>> ASSOCIATED COMAPNY-->
          </div>
  
         
  
          <div class="row details-table" *ngIf="customerData">
            <table class="table">
              <caption class="sr-only">Customer Creation/Modification details</caption>
              <tr>
                <th>{{modifyCustomerLabels.form.created}}</th>
                <th>{{modifyCustomerLabels.form.createdBy}}</th>
                <th>{{modifyCustomerLabels.form.lastModifiedDate}}</th>
                <th>{{modifyCustomerLabels.form.lastModifiedBy}}</th>
              </tr>
              <tr>
                <td><span style="padding:0;"
                    *ngIf="customerData && customerData.createdDate && customerData.createdDate !=='null'">{{customerData.createdDate}}</span>
                </td>
                <td><span style="padding:0;"
                    *ngIf="customerData && customerData.createdBy && customerData.createdBy !== 'null'">{{customerData.createdBy}}</span>
                </td>
                <td><span style="padding:0;"
                    *ngIf="customerData && customerData.modifiedDate && customerData.modifiedDate !== 'null'">{{customerData.modifiedDate}}</span>
                </td>
                <td><span style="overflow-wrap:anywhere;padding:0;"
                    *ngIf="customerData && customerData.modifiedBy && customerData.modifiedBy !== 'null'">{{customerData.modifiedBy}}</span>
                </td>
              </tr>
            </table>
          </div>
  
          <div class="row">
            <!-- class="primary-btn xl-btn create-mobile-view-submit" -->
            <div class="button-container customer-button-container">
              <button type="button" [disabled]="!modifyCustomerForm.valid"
                 class="red-primary-btn enquiry-submit-btn"
                (click)="onSubmit()">{{modifyCustomerLabels.form.submit}}</button>
                <!-- class="secondary-btn xl-btn create-mobile-view-cancel" -->
              <br>
                <button type="button" class="red-secondary-btn"
                (click)="cancelCreate()">{{modifyCustomerLabels.form.cancel}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  </div>