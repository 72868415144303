<div class="page-bg">
  <div class="row content-row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <h1 class="page-header">{{'welcome'|translate}}
      </h1>
    </div>
  </div>
  <div class="row content-row">
    <div class="col-12 image-container">
      <div class="bg-image">
        <div class="page-content">{{'accessInfra'|translate}} <br> {{'largesttowerCpmy'|translate}}
          <br>{{'standBy'|translate}}
          <br>
          <button class="red-primary-btn ams-btn" id="tower-ams" *ngIf="(!roles.includes('enquiryonlyuser') || 
        roles.includes('enquiryplusamsuser') || 
        roles.includes('ampliteloperation') || 
        roles.includes('ampliteladmin') || 
        roles.includes('amplitelother'))"
        (click)="towercoAMS()"
        tabindex="0" role="button">
        TOWER ASSET MANAGEMENT SYSTEM</button>
        </div>
      </div>
  </div>
</div>