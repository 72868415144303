import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessScreenComponent } from './success-screen.component';
 ;



@NgModule({
  declarations: [
    SuccessScreenComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[SuccessScreenComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SuccessScreenModule { }
