import { GlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";

export function showToasterMessage(message: string, header: string, options: GlobalConfig, type: string,toastr:ToastrService,toastContainer?:ToastContainerDirective,positionClass?:string) {
   if(toastContainer)  toastr.overlayContainer = toastContainer;
    options.positionClass =  positionClass?positionClass:'toast-bottom-right-document';
    options.disableTimeOut = false;
    options.autoDismiss = false;
    options.enableHtml = true;
    options.closeButton = true;
    options.preventDuplicates = true;
    options.tapToDismiss = false;
    toastr.show(message, header === '' ? null : header, options, options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
}
export const cidnRegex =  /^[0-9]{10}$/g;