import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/customers/customer.service';
import { BillingService } from "../billing.service";
import { CustomAgGrid, CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import * as moment_ from 'moment';
import { forkJoin } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { UsersService } from 'src/app/users/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Title } from '@angular/platform-browser';
import { saveAs as importedSaveAs } from "file-saver";
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-billing-home',
  templateUrl: './billing-home.component.html',
  styleUrls: ['./billing-home.component.less']
})
export class BillingHomeComponent implements OnInit {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public gridConfig: GridOptions;
  public businessNames: any = [];
  public businessRoleData: any;
  public isSelected: boolean = false;
  public amplitelbilladmin: boolean = false;
  public isSearched: boolean = false;
  roles = [];
  cidnList = [];
  userRole: any;
  rowData;
  selectedCarrier: String = '';
  private columnDefs;
  gridInitialized: boolean;
  subscription: any;
  subscribedflag: Boolean = false;
  options: GlobalConfig;
  moment = moment_;
  roleList: Array<String> = [];
  type = ['success', 'error', 'info', 'warning'];
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness Name Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public searchForm: FormGroup;
  public aemLabelAuthoring: any;
  selectedCidn: any;

  constructor(private router: Router, private fb: FormBuilder,
    private toastr: ToastrService, private billingService: BillingService,
    private userService: UsersService, private commonService: CommonService,
    private titleService: Title,
    private sharedService: SharedService
    //  private dataSvc: CustomerService
  ) {
    this.titleService.setTitle('Amplitel Billing');
    this.userRole = (this.commonService.getroleDetails());
    this.roles = this.userRole.roleList;
    this.cidnList = this.userRole.cidnList;
    this.options = this.toastr.toastrConfig;
    this.getLabels();
  }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.searchForm = this.fb.group({
      businessTypes: [''],
    });
    this.searchForm.get('businessTypes').valueChanges.subscribe(val => {
      if (val) {
        this.isSelected = true;
      }
    });
    this.toastr.overlayContainer = this.toastContainer;
  }

  getLabels() {
    return new Promise((resolve, reject) => {

      if (!this.roles.includes('amplitelbilladmin')) {
        this.createGrid({ 'data': this.cidnList[0] });
      } else if (this.roles.includes('amplitelbilladmin')) {
        const params = { 'amplitel': this.amplitelbilladmin };
        this.userService.getBusinessNamesMyUser(params).then(res => {
          this.businessRoleData = res;
          this.businessRoleData.BusinessNameDetails.forEach(entry => {
            if (Object.keys(entry).length > 0) {
              this.businessNames.push(entry['Business name'].trim());
            }
          });
          if (this.businessNames.length > 0) {
            this.businessNames = this.businessNames.sort();
            this.gridInitialized = true;
          } else {
            this.router.navigateByUrl('/error');
          }
          resolve(true);
        }).catch(() => {
          reject(false);
        });
      }

      this.billingService.getBillingListModel().then(() => {
        this.aemLabelAuthoring = this.billingService.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  createGrid(payload) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      this.router.navigateByUrl('/error');
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.billingService.getGridHeader().then(() => {
        this.columnDefs = this.billingService.gridHeaderDataObj.headers;
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if (x.colId == 'grid-downloaded') {
              x.cellRendererParams.clicked = (field: any, type, selectedRows?) => {
                this.download(field);
              }
            }
          }
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  download(documentDetails) {
    const filename = documentDetails.fileId;
    const cidn = documentDetails.cidn;
    this.billingService.downloadFile(filename, cidn).then(res => {
      if (res) {
        importedSaveAs(res['body'], filename);
      }
    }).catch((err) => {
      if (err.status === 401) {
        this.router.navigateByUrl('/');
      } else
        this.router.navigateByUrl('/error');
    });
  }

  getFileType(filetype) {
    let blobtype;
    switch (filetype) {
      case 'pdf': blobtype = 'application/pdf'
        break;
      case 'csv': blobtype = 'text/csv';
        break;
    }
    return blobtype;
  }

  private createRowData(params) {
    const param = params || null;
    return new Promise((resolve, reject) => {
      this.billingService.getGridData(param).then(() => {
        this.rowData = this.billingService.gridDataObj;
        for (let i = 0; i < this.rowData.length; i++) {
          const selectedRow = this.rowData[i];
          for (const key in selectedRow) {
            if (!selectedRow[key] || selectedRow[key].toLowerCase() === 'null') {
              selectedRow[key] = '';
            }
          }
        }
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onFilterSelected(filteredData) {
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  searchBusinessData(form) {
    this.gridInitialized = false;
    this.isSearched = true;
    let cidnList = [];
    const businessNameList = this.businessRoleData.BusinessNameDetails;
    cidnList = businessNameList.filter(key =>
      key['Business name'] === form.form.value.businessTypes).map(key => key['CIDN']);
    this.createGrid({ 'data': cidnList[0] });

    this.selectedCarrier = form.form.value.businessTypes;
  }

  resetSearch() {
    this.searchForm.markAsUntouched();
    this.isSelected = false;
    this.isSearched = false;
    this.searchForm.reset();
    this.gridInitialized = false;
  }
}


