<div class="worklist page-bg">
    <div class="container-fluid page-container top-alignment customerList" *ngIf="aemLabelAuthoring">
        <h1 class="page-header" id="customerList">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>

        <label [hidden]="!isAmplitelAdmin" class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
            <div class="row businessName" [hidden]="!isAmplitelAdmin">
                <div class="col-xs-12 col-sm-9 col-md-5 col-lg-4">
                  <label class="checkLbl" automationType="label" automationId="lblrole" role="button"
                        id="businessNameHeader" [attr.aria-label]="aemLabelAuthoring.form.businessNameModel">
                        {{aemLabelAuthoring.form.businessNameModel}}
                  </label>
                  <app-cui-searchable-dropdown name="businessName" [model]="businessNameModel"
                        [labelName]="aemLabelAuthoring.form.businessNameModel"
                        [(ngModel)]="selectedBusinessName"
                        [placeholder]="aemLabelAuthoring.form.businessNamePlaceholder"
                        [items]="businessNameItems" id="businessName"
                        (ngModelChange)='modelValueChange($event)' class="user-dropDown">
                  </app-cui-searchable-dropdown>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  <button type="button" class="red-primary-btn xl-btn cancel-xl submit"
                    aria-label="Search for Business name" (click)="getGridData()" [disabled]="disableButton || agree">
                      {{aemLabelAuthoring.form.grid.search}}
                  </button>
                </div>
                <div class="col-lg-5 col-md-4"></div>
            </div>
        <!-- <div class="searchModel" *ngIf="gridConfig">
          <app-cui-search-box  [model]="searchModel" (searchEvent)="filterSearchInput($event)"
          (resetEvent)="resetGrid(null)" (keyup)="inputChange($event)">
         </app-cui-search-box>
        </div> -->
     
     
      <div class="row close-checkbox searchModel" *ngIf="gridConfig">
        <label class="ticketSearch ps-0">Search Enquiry</label>
        <div class="searchbyId">
          <span>
            <input type="text" class="searchfilter searchfilter-position " id="text"
            placeholder="Enter Enquiry ID/logged by/Enquiry Type/Status"
            [(ngModel)]="searchvalue" aria-label="Search Enquiry" tabindex="0" role="combobox" aria-expanded="false" aria-controls="text"/>
          </span>
          <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
            <button type="button" class="red-primary-btn xl-btn cancel-xl searchbox1" 
            aria-label="Search for Enquiry" (click)="quickSearch()">Search</button>
          </span>
          <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
            <button type="button" class="red-secondary-btn" (click)="clearsearch()">Reset</button>
          </span>
          <div class="ml-3 displayClosedsec">         
            <span><input type="checkbox" [(ngModel)]="agree" class="closecheck" id="closed" (change)="closeTickets($event)"></span>
            <span><label class="displayClosed" for="closed">Display Closed Enquiry</label></span>
          </div>
          <div class="downloadSection" (click)="downloadSummary()" *ngIf="rowData.length > 0 && gridInitialized">         
            <button tabindex="0" type="button" (keydown.Tab)="focusToRecords()" id="download_icon" class="download xl-btn p-0 m-0">
              <!-- <div  class="twssp-sprite svg-icon sprite-m icon-download"> </div>  -->
            <app-cui-svg-icon [icon]="'icon-download'" class="svg-icon"></app-cui-svg-icon>Download</button>
          </div>
        </div>       
      </div>

      <div class="grid-container" tabindex="-1">
        <div class="grid-container" *ngIf="gridInitialized;else spinner" tabindex="-1">
          <app-cui-ag-grid [model]="gridModel" (buttonClicked)="navigateToCreateTickets()" (filtersOnChanged)="onFilterSelected($event)" (selectedOnChanged)="onSelectionChanged($event)"
            (gridOnReady)='gridReady($event)' (focusOnTop)="focusOnTop($event)">
          </app-cui-ag-grid>
        </div>
      </div>
    <ng-template #spinner>
      <div class="grid-container spinner-overlay" *ngIf="!gridInitialized && isSearched">
        <div class="spin-container">
          <app-spinner-svg></app-spinner-svg>
        </div>
      </div>
    </ng-template>
 </div>
</div> 
  