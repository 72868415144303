<div class="page-bg">
  <div class="container-fluid page-container add-preference" *ngIf="aemLabelAuthoring">
    <h1 class="page-header" id="addPreferencehead">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
    <div class="wrapper">
      <h2 *ngIf="!update" automationType="label" automationId="lblChoosePlan">
        {{aemLabelAuthoring.pageHeader.pageSubHeaderAddPrefLabel}}
      </h2>
      <h2 *ngIf="update" automationType="label" automationId="lblChoosePlan">
        {{aemLabelAuthoring.pageHeader.pageSubHeaderEditPrefLabel}}
      </h2>
      <p>{{aemLabelAuthoring.pageHeader.pageSubHeadingContent}}</p>
      <div  aria-live="polite" role="alert"><span class="toastrContainer" toastContainer></span></div>
      <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
      <form [formGroup]="addPreferenceForm" id="addPreference" #addPreference="ngForm">
        <div class="form-group">
          <div class="row">
            <div class="dropdown-contact-options col-sm-6 col-lg-4 col-md-6 pb-2" [hidden]="!moreThanOneBusiness">
              <label class="checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="businessNameHeader"
                attr.aria-label={{aemLabelAuthoring.form.businessNameModel}}>
                {{aemLabelAuthoring.form.businessNameModel}}
              </label>
              <app-cui-searchable-dropdown name="businessName" aria-required="true" [formControlName]="'businessName'" [model]="businessNameModel"
                [labelName]="aemLabelAuthoring.form.businessNameModel" [placeholder]="aemLabelAuthoring.form.businessNamePlaceholder"
                [isRequired]=true [items]=businessNameItems automationType="dropdown" automationId="drpdwnroleType"
                [noAutoComplete]=false id="businessName"
                class="user-dropDown"
                [ngClass]="{'invalid-form-control': (addPreferenceForm.controls.businessName.invalid && addPreferenceForm.controls.businessName.touched )}">
              </app-cui-searchable-dropdown>
              <span class="asterisk_input"> </span>
              <div class="row ml-0" aria-live="assertive">
                <span role="alert"
                  *ngIf="addPreferenceForm.controls.businessName.invalid && addPreferenceForm.controls.businessName.touched"
                  class="help-block errorMsg">
                  {{aemLabelAuthoring.form.businessNameRequiredMessage}}
                </span>
              </div>
              <span class="asterisk_input"> </span>
            </div>
          <!-- </div>
          <div class="row"> -->
            <div class="col-sm-6 col-lg-4 col-md-4 pb-2 notificationType">
              <label class="checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="notificationTypeHeader"
                attr.aria-label={{aemLabelAuthoring.form.notificationTypeModel}}>
                {{aemLabelAuthoring.form.notificationTypeModel}}
              </label>
              <app-cui-searchable-dropdown role="combobox" aria-required="true" aria-label="Notification Type combo box collapsed Select Notification Type Required" name="notificationType" [formControlName]="'notificationType'"
                [model]="notificationTypeModel" [labelName]="aemLabelAuthoring.form.notificationTypeModel"
                [placeholder]="aemLabelAuthoring.form.notificationTypePlaceholder" [isRequired]=true
                [items]=notificationTypeItems automationType="dropdown" automationId="drpdwnroleType"
                [noAutoComplete]=false id="notificationTypeDropdown" class="user-dropDown"
                [ngClass]="{'invalid-form-control': (addPreferenceForm.controls.notificationType.invalid && addPreferenceForm.controls.notificationType.touched )}"
                required>
              </app-cui-searchable-dropdown>
              <div class="row ml-0" aria-live="assertive">
                <span role="alert"
                  *ngIf="addPreferenceForm.controls.notificationType.invalid && addPreferenceForm.controls.notificationType.touched"
                  class="help-block errorMsg">
                  {{aemLabelAuthoring.form.notificationTypeRequiredMessage}}
                </span>
              </div>
              <span class="asterisk_input"> </span>
            </div>
            <div class="col-sm-6 col-lg-4 col-md-6 pb-2 notification-email">
              <app-cui-textfield [(model)]="emailAddressModel" [parentFormGroup]="addPreferenceForm">
              </app-cui-textfield>
            </div>
            <!-- <div class="col-sm-4 col-lg-4 col-md-4 pb-2 notificationType"> -->
  
              <!-- <label class="statusLbl" automationType="label" automationId="lblrole" id="notificationPreferenceHeader"
                attr.aria-label={{aemLabelAuthoring.form.notificationPreferenceModel}}>
                {{aemLabelAuthoring.form.notificationPreferenceModel}}
              </label>
              <app-cui-searchable-dropdown name="notificationPreferences" [formControlName]="'notificationPreferences'"
                [model]="notificationPreferenceModel" [labelName]="aemLabelAuthoring.form.notificationPreferenceModel"
                [placeholder]="aemLabelAuthoring.form.notificationPreferencePlaceholder" [isRequired]=true
                [items]=notificationPreferenceItems automationType="dropdown" automationId="drpdwnroleType"
                [noAutoComplete]=false id="notificationPreferenceDropdown" class="user-dropDown"
                [ngClass]="{'invalid-form-control': (addPreferenceForm.controls.notificationPreferences.invalid && addPreferenceForm.controls.notificationPreferences.touched )}"
                required>
              </app-cui-searchable-dropdown>
              <div class="row ml-0" aria-live="assertive">
                <span role="alert"
                  *ngIf="addPreferenceForm.controls.notificationPreferences.invalid && addPreferenceForm.controls.notificationPreferences.touched"
                  class="help-block errorMsg">
                  {{aemLabelAuthoring.form.notificationPreferenceRequiredMessage}}
                </span>
              </div>
              <span class="asterisk_input"> </span> -->
            <!-- </div> -->
          </div>
          <div class="row">
            <div class="button-container preference-button-container">
              <!-- <button *ngIf="update && !editMode" type="button" class="secondary-btn xl-btn margin-5" (click)="editModeOn()">
                                        <img src="/assets/images/icon-edit.svg" style="margin-bottom: 3px;"> {{aemLabelAuthoring.form.edit}}</button> -->
              <button type="button" class="red-primary-btn xl-btn margin-5" [disabled]="addPreferenceForm.invalid"
                (click)="onSubmit()">{{aemLabelAuthoring.form.save}}</button>
              <button *ngIf="update" type="button" role="button"  class="red-secondary-btn ml-3 pt-2 pb-4" (click)="showModal('Remove?')">
                <i aria-hidden="true" class="icon-icon-ui-cross icon-xs"></i>{{aemLabelAuthoring.form.remove}}
              </button>
              <button type="button" class="red-secondary-btn margin-5 cancel"
                (click)="cancelCreate()">{{aemLabelAuthoring.form.cancel}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>