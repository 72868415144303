import { NgModule,NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule, TwCoreUIFormModule } from 'tw-core-ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TicketsRoutingModule } from './tickets-routing.module';
import { WorklistComponent } from './worklist/worklist.component';
import { WorklistService } from './services/worklist.service';
import { GridFilterModule } from '../grid-filter/grid-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketSummaryComponent } from './ticket-summary/ticket-summary.component';
import { CustomersModule } from '../customers/customers.module';
import { WorklistStatusRendererComponent } from './worklist-status-renderer/worklist-status-renderer.component';
import { TranslateModule } from 'ng2-translate';

@NgModule({
  declarations: [WorklistComponent, TicketSummaryComponent,WorklistStatusRendererComponent],  
  imports: [
    CommonModule,
    CustomersModule,
    TicketsRoutingModule,
    SpinnerModule,
    TwCoreUIFormModule,   
    GridFilterModule,
    AgGridModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    MatTooltipModule,
    MatRadioModule,
    MatListModule,
    MatProgressBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    NgbModule,
    AgGridModule.withComponents([      
      WorklistStatusRendererComponent
    ]),
    TranslateModule.forRoot()
  ],
  providers: [WorklistService],
  schemas: [NO_ERRORS_SCHEMA]
})
export class TicketsModule { }
