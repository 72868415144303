import { Injectable } from '@angular/core';
import { SiteDetailsPopupLabels } from '../site-locator/components/site-details-popup/site-details.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from 'tw-core-ui';
import { Router } from '@angular/router';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root',
})
export class SiteDetailsPopupService {
  private env: any = window['environment'];

  private siteDetailSubject =  new BehaviorSubject({});
  siteDetails$ = this.siteDetailSubject.asObservable();

  //siteDetailsData: any
  
  public siteDetailsAuthoringDataObj: SiteDetailsPopupLabels;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private commonService:CommonService) {
    //this.setAuthorizationHeaders();
  }

  setDetails(siteDetails:any){
    this.siteDetailSubject.next(siteDetails);
  }

  private siteUri = {
    siteDetailsPopupLabels: 'site-details-popup',
  };
 
  siteDetailUrl = 'site-details/digital-twin-token'
  l1ReportUrl = 'site-details/download-l1report'
  siteAccessDetails = "site-details/request/site-access-details"

  getDigitalTwinUrlDetails(siteReferenceId: string, structureReferenceId: string):Observable<any>{
    const digitalTwinURL = this.env.apiPoint + this.siteDetailUrl;
    const params =new HttpParams().set("siteReferenceId",siteReferenceId+'').set("structureReferenceId",structureReferenceId+'');
    // let cidn:string;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
        'cidn': this.commonService.getPrimaryValue().cidn?this.commonService.getPrimaryValue().cidn:(this.commonService.getroleDetails().adminCidns.length?this.commonService.getroleDetails().adminCidns[0]:null)
      }),
    };
    // cidn = this.commonService.getPrimaryValue().cidn;
    // if(!cidn) cidn=this.commonService.getroleDetails().adminCidns.length?this.commonService.getroleDetails().adminCidns[0]:null;
    // httpOptions.headers['cidn'] = cidn;
     return this.http.get(digitalTwinURL, {headers:httpOptions.headers,params}).map((res:any)=>{
      return res.data
    });
     
  }
 
  getPreliminaryReportDetails(siteReferenceId: string, primaryCidn: string):Observable<any>{
    const downloadReport = this.env.apiPoint + this.l1ReportUrl + "?siteRef=" + siteReferenceId;
     return this.http.get(downloadReport, {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
        'cidn':primaryCidn
      }),
      observe: 'response',
      responseType: 'blob'
    })     
  }

  public getSitePopupModel() {
    return new Promise((resolve, reject) => {
      if (!this.siteDetailsAuthoringDataObj) {
        this.http
          .get<any>(this.env.aemEndPoint + this.siteUri.siteDetailsPopupLabels)
          .subscribe(
            (res) => {
              this.siteDetailsAuthoringDataObj = res.data.content;
              resolve(this.siteDetailsAuthoringDataObj);
            },
            (err) => {
              this.siteDetailsAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.siteDetailsAuthoringDataObj);
      }
    });
  }


  getSiteAccessInformation(siteReferenceId: string):Observable<any>{
    const siteAccessDetailsURL = this.env.apiPoint + this.siteAccessDetails + `/${siteReferenceId}`;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
        'cidn': this.commonService.getPrimaryValue().cidn?this.commonService.getPrimaryValue().cidn:(this.commonService.getroleDetails().adminCidns.length?this.commonService.getroleDetails().adminCidns[0]:null)
      }),
    };
     return this.http.get(siteAccessDetailsURL, {headers:httpOptions.headers}).map((res:any)=>{
      return res.data
    });
     
  }
}
