<div class="page-bg">
  <div class="container-fluid page-container create-customer" *ngIf="aemLabelAuthoring">
    <h1 class="page-header" id="customerCreate">
      {{ aemLabelAuthoring.pageHeader.pageHeaderLabel }}
    </h1>
    <div class="wrapper">
      <h2 class="create-customer-header" automationType="label" automationId="lblChoosePlan">
        {{ aemLabelAuthoring.pageHeader.pageSubHeaderLabel }}
      </h2>
      <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
      <form [formGroup]="createCustomerForm" id="createCustomer" #createCustomerTicket="ngForm">
        <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are
          mandatory</label>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 userNameWidth">
              <app-cui-textfield [(model)]="businessNameModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div">
              <app-cui-textfield [(model)]="cidnModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="dropdown-contact-options col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <label class="statusLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole"
                id="statusHeader" attr.aria-label="Staus">
                {{ aemLabelAuthoring.form.statusModel }}
              </label>
              <app-cui-searchable-dropdown name="status" [formControlName]="'status'" [model]="statusModel"
                [labelName]="'status'" [placeholder]="'Select status'" [isRequired]="true" [items]="statusItems"
                automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]="false" id="statusDropdown"
                class="user-dropDown" [ngClass]="{
                  'invalid-form-control':
                    createCustomerForm.controls.status.invalid &&
                    createCustomerForm.controls.status.touched
                }" required>
              </app-cui-searchable-dropdown>
              <img src="../../../assets/images/select-arrow.png" class="dropdown-arrow" alt="" />
              <div class="row ml-0" aria-live="assertive">
                <span role="alert" *ngIf="
                    createCustomerForm.controls.status.invalid &&
                    createCustomerForm.controls.status.touched
                  " class="help-block errorMsg">
                  {{ aemLabelAuthoring.form.statusRequiredMessage }}
                </span>
              </div>
              <span class="asterisk_input"> </span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="abnModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="msoIdModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
          </div>

         
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back">Parent company</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="parentCompany" formControlName="parentCompany"
                [items]="companyNameDropdown" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="parentCompanyModel" [labelName]="'Parent company'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back statusLbl checkLbl asteriskdropdown">Company type</div>
              <!-- <app-cui-searchable-dropdown #subCategoryTypes name="companyType" formControlName="companyType"
                [items]="filterCompany" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="companyTypeModel" [labelName]="'Company type'"></app-cui-searchable-dropdown> -->
                <ngx-select [items]="filterCompany" [aria-haspopup]="listbox" [optionValueField]="rolesLabel" #selectg 
                [keepSelectedItems]="false" role="combobox" id="dropdownListId"
               aria-label="label for the select the role"   [aria-expanded]="false"
               [aria-controls]="'dropdownListId'" attr.aria-label="Role"
               tabIndex="0" [formControlName]="'companyType'" [label]="'Role'" placeholder="Please select"
               [multiple]=" true" (selectionChanges)="selectionChangedCompanyType($event)"></ngx-select>

            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4 col-md-4 cidn-div div-min-width" *ngIf="isAsteriskRequired">
              <div class="step-back statusLbl checkLbl" [ngClass]="{'asteriskdropdown': isAsteriskRequired}">Customer type</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="stepBackApproach" formControlName="customer_type"
                [items]="customerType" class="step-backdropdown" [placeholder]="'Please select'"
                [model]="customerTypeModel" [labelName]="'Customer type'"></app-cui-searchable-dropdown>
            </div>
          </div>
          <div class="row" *ngIf="vendorField">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Status</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="vendorStatus" formControlName="vendor_status"
                [items]="vendorStatus" class="step-backdropdown" [placeholder]="'Select Vendor Status'"
                [model]="vendorStatusModel" [labelName]="'Vendor Status'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Valid From</div>
              <div class="date-picker">
                <app-cui-datepicker class="datepicker_core" attr.aria-label="from date"
                [datePlaceholder]="startPlaceholder" [modelDate]="searchfromclearDate"
                [datePickerId]="'startDatePicker'" [maxDate]="currentDate"
                (dateSelected)="onFromDateChange($event)" [navigation]="false" [model]="startDatePicker">
              </app-cui-datepicker>
            </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Valid To</div>
              <div class="date-picker" >
                <app-cui-datepicker class="datepicker_core" [datePlaceholder]="startPlaceholder"
                attr.aria-label="to date" [datePickerId]="'completeDatePicker'" [minDate]="tominDate"
                (dateSelected)="onToDateChange($event)" [modelDate]="searchtoclearDate" 
                 [navigation]="false" [model]="completeDatePicker" >
              </app-cui-datepicker>
            </div>
            </div>
          </div>
          <div class="row" *ngIf="vendorField">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <div class="step-back" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}">Vendor Site Access</div>
              <app-cui-searchable-dropdown #subCategoryTypes name="vendorSiteAccess" formControlName="vendorSiteAccessStatus"
                [items]="vendorSiteAccess" class="step-backdropdown" [placeholder]="'Select Vendor Site Access'"
                [model]="vendorSiteAccessModel" [labelName]="'Vendor Site Access'"></app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div div-min-width">
              <label class="statusLbl checkLbl" [ngClass]="{'asteriskdropdown': isAsteriskRequiredVendor}" automationType="label" attr.aria-label="Role" for="dropdownListId">
                Vendor Service Type
              </label>
              <ngx-select [items]="vendorServiceType" [aria-haspopup]="listbox" [optionValueField]="rolesLabel" #selectg 
                   [keepSelectedItems]="false" role="combobox" id="dropdownListId"
                  aria-label="label for the select the role"   [aria-expanded]="false"
                  [aria-controls]="'dropdownListId'" attr.aria-label="Role"
                  tabIndex="0" [formControlName]="'vendorServiceType'" [label]="'Role'" placeholder="Select Vendor Service Type"
                  [multiple]=" true" (selectionChanges)="selectionChanged($event)"></ngx-select>
            </div>

             <!--START HERE >>>>>>>>>>> ASSOCIATED COMPANY -->
          <table class="table" *ngIf="associatedCompanyData.length > 0">
            <caption class="sr-only">User role details</caption>
            <tr><th *ngFor="let col of tableColAssociated">{{col}}</th></tr>
            <tr *ngFor="let company of associatedCompanyData; let i = index">
                <td> {{company.companyId}} </td>
                <td> {{company.associatedFor}} </td>
                <td> {{company.allowAMSAppAccess}} </td>
                <td> <button type="button" tabindex="0"
                  class="secondary-btn xl-btn p-0 m-0 remove-btn"
                  (click)="removeAssociateRow(i)">
                  <span class="icon-icon-ui-cross" aria-hidden="true"></span>{{aemLabelAuthoring.form.removeText}}
              </button> </td>
            </tr>
        </table>                
        <div *ngIf="!isAddAssociatedVisible" class="addRole">
            <button type="button" id="addrole" class="secondary-btn addroleBtn m-0 p-0" (click)="openAssociatedBoxModel()">
                <app-cui-svg-icon aria-hidden="true" [icon]="'icon-circlePlus'" class="svg-icon">
                </app-cui-svg-icon>{{aemLabelAuthoring.form.addAssociatedCompany}}
            </button>
        </div>
        <div class="row borderBox" *ngIf="isAddAssociatedVisible">
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4">
                <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblbusinessName"
                    id="businessNameHeader" attr.aria-label={{aemLabelAuthoring.form.businessName}}>
                    {{aemLabelAuthoring.form.businessName}}
                </label>
                <app-cui-searchable-dropdown #companyId name="companyId"
                    [formControlName]="'companyId'" [model]="businessNameDropDownModel"
                    [labelName]="aemLabelAuthoring.form.businessName"
                    [placeholder]="aemLabelAuthoring.form.businessPlaceHolder" [isRequired]=true
                    [items]=companyNameDropdown automationType="dropdown" automationId="drpdwnbusinessType"
                    [noAutoComplete]=false id="companyId" class="user-dropDown dd-padding"
                    >
                </app-cui-searchable-dropdown>
            </div>
          
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="roleHeader"
                    attr.aria-label={{aemLabelAuthoring.form.associatedFor}}>
                    {{aemLabelAuthoring.form.associatedFor}}
                </label>
                <app-cui-searchable-dropdown name="associatedFor" [formControlName]="'associatedFor'"
                    [model]="associatedCompanyDropDownModel" [labelName]="aemLabelAuthoring.form.associatedFor"
                    [placeholder]="aemLabelAuthoring.form.associatedselectPlaceholder" [items]=associatedForDropdown (ngModelChange)='onAssociatedCompanyChanged($event)' [isRequired]=true
                    automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false 
                    class="user-dropDown dd-padding" 
                    >
                </app-cui-searchable-dropdown>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
              <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="roleHeader"
                  attr.aria-label={{aemLabelAuthoring.form.allowAMSAppAccess}}>
                  {{aemLabelAuthoring.form.allowAMSAppAccess}}
              </label>
              <app-cui-searchable-dropdown name="allowAMSAppAccess" [formControlName]="'allowAMSAppAccess'"
                  [model]="amsAppAccessDropDownModel" [(ngModel)]="setAMSAppNo" [labelName]="aemLabelAuthoring.form.allowAMSAppAccess"
                  [placeholder]="aemLabelAuthoring.form.selectPlaceholder" [isRequired]=true [items]=amsAppOption
                  automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false 
                  class="user-dropDown dd-padding" [disabled]="amsAppAccessDropDownModel.isDisabled"
                  >
              </app-cui-searchable-dropdown>
          </div>
            <div class="button-container myuser-button-container col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <button type="button" class="red-primary-btn xl-btn mr-3 enquiry-submit-btn" 
                [disabled]="isAssociatedSaveBtnDisabled()" 
                [ngClass]="isAssociatedSaveBtnDisabled() ? 'primary-btn-disabled dis-btn':''" 
                (click)="addAssociatedCompany()">{{aemLabelAuthoring.form.save}}
                </button>
                <button type="button" class="secondary-btn xl-btn addroleCancel"
                    (click)="closeBox()">{{aemLabelAuthoring.form.cancel}}
              </button>
            </div>
        </div>
          <!--END HERE >>>>>>>> ASSOCIATED COMAPNY-->
            
          </div>
          <div>
            <button type="submit" id="submit-btn" tabindex="0" (click)="onSubmit()"
              class="red-primary-btn enquiry-submit-btn" [ngClass]="
                (!createCustomerForm.valid || (vendorField && associatedCompanyData.length === 0)) ? 'primary-btn-disabled dis-btn' : ''
              "  [disabled]="!createCustomerForm.valid || (vendorField && associatedCompanyData.length === 0)">
              {{ aemLabelAuthoring.form.submit }}
            </button>
          </div>
          <div class="btn-div">
            <button class="red-secondary-btn" (click)="cancelCreate()" type="button" tabindex="0">
              {{ aemLabelAuthoring.form.cancel }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>