<div class="page-bg">
    <div class="row error-section" role="status" aria-live="polite">
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 error-container">
            <img class="error-img" alt="Amplitel Enquiry Error"
            src="../../assets/images/cancel.png" />
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
            <h1>Oops!</h1>
            <div class="error-content">
               {{'enquiryError'|translate}}
            </div>
        </div>
    </div>
</div>