import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { UsersService } from '../users.service';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../../grid-filter/custom-date-filter/custom-date-filter.component';
// import { GridTilesUserViewComponent } from '../components/grid-tiles-user-view/grid-tiles-user-view.component';
// import { GridData } from '../../../../shared/models/common-model';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import * as moment_ from 'moment';
//import { DashboardService } from '../../../../modules/dashboard/dashboard.service';
import { UtilityService } from '../../services/utility.service';
import { CommonService } from 'src/app/services/common.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-my-user',
  templateUrl: './my-user.component.html',
  styleUrls: ['./my-user.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MyUserComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public gridConfig: GridOptions;
  rowData;
  private columnDefs;
  gridInitialized: boolean;
  subscription: any;
  subscribedflag: Boolean = false;
  public businessNames: any = [];
  public businessRoleData: any;
  public isSelected: boolean = false;
  public infracoAdmin: boolean = false;
  isSearched: Boolean = false;
  isTextSearch: Boolean = false;
  isReset:Boolean = false;
  isValidUserName: Boolean = false;
  selectedCarrier: String = '';
  userNameInvalidMessage = '';
  options: GlobalConfig;
  moment = moment_;
  roleList: Array<String> = [];
  type = ['success', 'error', 'info', 'warning'];
  // roles = [];
  userRole = [];
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public userNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyUserName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxuserName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divUserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'userName',
    tabindex: '',
    maxlength: '64',
    headerText: 'UserName',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'userName',
    formControlName: 'userName',
    isDisabled: false,
    showValidationIcon: true
  };

  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Search Bussiness name  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return to select the list under'
  };
  public aemLabelAuthoring: any;
  // GridData;
  public filteredRowData: any = [];
  public searchForm: FormGroup;

  constructor(private dataSvc: UsersService, private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private commonService: CommonService,
    private loader: LoadingServiceService,
    // private dashBoardSvc: DashboardService, 
    private titleService: Title,
    private utility: UtilityService, private userService: UsersService,
    private sharedService: SharedService) {
    this.titleService.setTitle('Amplitel My User');
    this.userRole = (this.commonService.getroleDetails()).roleList;
    // const userRoleDetails = this.dashBoardSvc.getroleDetails();
    // if (userRoleDetails.appList.indexOf('tw-hub') === -1 || userRoleDetails.roleList.length === 0 ||
    //   userRoleDetails.appList.length !== userRoleDetails.roleList.length ||
    //   userRoleDetails.roleList.length !== userRoleDetails.cidnList.length) {
    //   this.router.navigateByUrl('/access-denied');
    // } else  {
    //  this.getAccessDetails();
    // }
    this.options = this.toastr.toastrConfig;
    this.getLabels();
  }

  // getAccessDetails() {
  //   if (this.userService.validUserRoles.length === 0) {
  //     return new Promise((resolve, reject) => {
  //       this.userService.getValidRoleDetails().then(res => {
  //         if (this.userService.validUserRoles.length === 0) {
  //           this.router.navigateByUrl('/access-denied');
  //         } else {
  //           this.options = this.toastr.toastrConfig;
  //           this.getRoles();
  //         }
  //         resolve(true);
  //       }).catch((err) => {
  //         reject(false);
  //       });
  //     });
  //   } else {
  //     this.options = this.toastr.toastrConfig;
  //     this.getRoles();
  //   }
  // }

  // getRoles() {
  //   return new Promise((resolve, reject) => {
  //     this.userService.getAllRoles().then(res => {
  //       this.roles = res['roles'];
  //       this.getLabels();
  //       resolve(true);
  //     }).catch((err) => {
  //       reject(false);
  //     });
  //   });
  // }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.loader.setLoader(false);
    this.searchForm = this.fb.group({
      businessTypes: [''],
      userName: ['', [Validators.pattern(this.utility.getEmailRegEx())]],
    });
    this.searchForm.get('userName').valueChanges.subscribe(val => {
      this.userNameInvalidMessage = '';
      if (val) {
        this.isReset = true;
      }
      if (this.searchForm.controls['userName'].valid && val) { 
        this.isValidUserName = true;
      } else {  
        this.isValidUserName = false;
      }
      this.searchByUserBtnValidation();
    });
    this.searchForm.get('businessTypes').valueChanges.subscribe(val => {
      if (val) {
        this.isSelected = true;
        this.searchForm.controls['userName'].reset();
        this.userNameInvalidMessage = '';
        this.isTextSearch = false;
        this.isReset = true;
      }
    });
  }

  searchByUserBtnValidation() { 
    if (this.isValidUserName) {
      this.isTextSearch = true;
    } else { 
      this.isTextSearch = false;
    }
  }

  clearBusinessName($event) {
    this.searchForm.controls['businessTypes'].setValue('');
    this.isSelected = false;
    this.searchByUserBtnValidation();
  }


  getLabels() {
    return new Promise((resolve, reject) => {
      const roles = [];
      const cidnList = [];
      let userRoles = this.dataSvc.getUserRoles();
      userRoles.forEach(item => {
        roles.push(item.split(':')[1].toLowerCase());
      });
      //  this.infracoAdmin = roles.some(val => this.infracoRoles.indexOf(val) > -1) ? true : false;
      if (!this.userRole.includes('ampliteladmin')) {
        //   this.dataSvc.getValidRoleDetails();
        //  this.createGrid({ 'data': { 'roleList': this.dataSvc.validUserRoles.join() } }, '');
        this.createGrid({ 'data': {} }, '');
        this.successMsg();
      } else if (this.userRole.includes('ampliteladmin')) {
        // const params = { 'infraco': this.infracoAdmin };
        this.dataSvc.getBusinessNamesMyUser(null).then(res => {
          this.businessRoleData = res;
          this.businessRoleData.BusinessNameDetails.forEach(entry => {
            if (Object.keys(entry).length > 0) {
              this.businessNames.push(entry['Business name'].trim());
            }
          });
          if (this.businessNames.length > 0) {
            this.businessNames = this.businessNames.sort();
            this.businessNames.splice(0, 0, 'All');
            this.gridInitialized = true;
          } else {
            this.router.navigateByUrl('/error');
          }
          this.successMsg();
          resolve(true);
        }).catch(() => {
          reject(false);
        });
      }
      this.dataSvc.getWorkListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.userNameModel.headerText = this.aemLabelAuthoring.form.userName.headerText;
        this.userNameModel.placeholderCaption = this.aemLabelAuthoring.form.userName.placeholderCaption;
        this.userNameModel.validationMessage = this.aemLabelAuthoring.form.userName.validationMessage;
        resolve(true);
      }).catch((error) => {
        reject(false);
      });
    });
  }


  createGrid(payload, value) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload, value);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      this.router.navigateByUrl('/error');
    });
    this.dataSvc.existingUserData = '';
  }

  successMsg() {
    if (this.dataSvc.createuserFormData) {
      let successMsg: string;
      if (this.dataSvc.createuserFormData.page === 'Create') {
        successMsg = 'You have successfully created a new user.';
      } else if (this.dataSvc.createuserFormData.page === 'Modify') {
        successMsg = 'You have successfully modified an existing user.';
      }
      this.showToasterMessage(successMsg, '', this.options, 'success');
      this.dataSvc.createuserFormData = '';
    }
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridHeader().then(() => {
        this.columnDefs = this.dataSvc.gridHeaderDataObj.headers;
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData(params, userSearch) {
    const param = params || null;
    if (userSearch) {
      userSearch.src_screen = 'my_user';
    }    
    return new Promise((resolve, reject) => {
      if (userSearch && userSearch.userName) {
        this.userService.getUserData(userSearch, '').then((result) => {
          if (result && result.status === 200 && result.data) {
            const DATA = result['data']['wholesale-users'];
            this.getRowData(DATA);
          } else {
            this.rowData = [];
          }
          resolve(true);
        }).catch((err) => {
          this.rowData = [];
          reject(err);
        });
      } else {
        //    const userType = { 'infraco': this.infracoAdmin };
        const userType = '';
        this.dataSvc.getGridData(param, userType).then(() => {
          this.getRowData(this.dataSvc.gridDataObj);
          resolve(true);
        }).catch((err) => {
          this.rowData = [];
          reject(err);
        });

      }

    });
  }

  getRowData(data) {
    this.rowData = data;
    const DATA_ROW = [];
    for (let i = 0; i < this.rowData.length; i++) {
      const SELECT_ROW = this.rowData[i];
      if (SELECT_ROW.roles) {
        const USER_ROLE = SELECT_ROW.roles;
        SELECT_ROW.roles = [];
        for (let j = 0; j < USER_ROLE.length; j++) {
          const TEMP = JSON.parse(JSON.stringify(SELECT_ROW));
          TEMP.roles = USER_ROLE[j].value;
          TEMP.businessName = USER_ROLE[j].businessName;
          //  TEMP.appName = USER_ROLE[j].appName.toUpperCase();
          if (TEMP.businessName) {
            DATA_ROW.push(TEMP);
          }
        }
      }
    }
    this.rowData = DATA_ROW.length > 0 ? DATA_ROW : this.rowData;
  }


  resetGrid(payload) {
    this.gridInitialized = false;
    this.createGrid(payload, '');
  }

  createGridConfig() {
    const userRoles = [];
    const userBusinesses = [];
    const apps = [];
    const filterRoleOptions = [];
    const filterAppOptions = [];
    const filterBusinessOptions = [];
    this.rowData.forEach(val => {
      if (userRoles.indexOf(val.roles) === -1) {
        userRoles.push(val.roles);
        filterRoleOptions.push({ 'elementData': val.roles });
      }
      if (userBusinesses.indexOf(val.businessName) === -1) {
        userBusinesses.push(val.businessName);
        filterBusinessOptions.push({ 'elementData': val.businessName });
      }
      // if (apps.indexOf(val.appName) === -1) {
      //   apps.push(val.appName);
      //   filterAppOptions.push({ 'elementData': val.appName });
      // }
    });
    this.columnDefs[3].customFilter = filterBusinessOptions;
    // this.columnDefs[4].customFilter = filterAppOptions;
    this.columnDefs[4].customFilter = filterRoleOptions;
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    // this.gridConfig.enableFilter = true;
    // this.gridConfig.enableSorting = true;
    // Disable row selection on click.
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesUserViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onSelectionChanged(arg) {
    const selectedRows = arg.gridApi.getSelectedRows();
    this.navigateToModify(selectedRows[0]);
  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  navigateToCreateUser() {
    this.router.navigate(['manageUsers/createUser']);
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  download() {
    const data = [];
    const dataHeaders = ['Username', 'First Name', 'Last Name', 'Business name', 'Role', 'Phone Number'];
    //  const now = this.moment().format('YYYYMMDDHHmmss');
    const now = this.moment().format('YYYYMMDD');
    const carriers = [];
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    } else {
      if (this.rowData && this.rowData.length) {
        dataToBeDownloaded = this.rowData;
      }
    }
    dataToBeDownloaded.forEach(selectedRow => {
      data.push({
        'Username': selectedRow.userName,
        'First Name': selectedRow.name.givenName,
        'Last Name': selectedRow.name.familyName,
        'Business name': selectedRow.businessName,
        // 'App Name': selectedRow.appName.toUpperCase(),
        'Role': selectedRow.roles,
        'Phone Number': selectedRow.phoneNumbers[0].value
      });
      if (carriers.indexOf(selectedRow.businessName) === -1) {
        carriers.push(selectedRow.businessName);
      }
    });
    if (!this.infracoAdmin) {
      this.selectedCarrier = carriers.length === 1 ? carriers[0] : 'All';
    }
    new Angular5Csv(data, this.selectedCarrier + ' Registered Users ' + now, { headers: dataHeaders });
  }


  searchBusinessData(form) {
    this.gridInitialized = false;
    this.isSearched = true;
    let cidnList = [];
    const businessNameList = this.businessRoleData.BusinessNameDetails;
    if (form.form.value.businessTypes === 'All') {
      this.createGrid({ 'data': { 'roleList': 'all' } }, '');
    } else if (form.form.value.userName) {
      // API call - search by userName
      this.createGrid({ 'data': { 'roleList': 'all' } }, form.form.value);

    } else {
      cidnList = businessNameList.filter(key =>
        key['Business name'] === form.form.value.businessTypes).map(key => key['CIDN']);
      this.createGrid({ 'data': { 'roleList': cidnList[0] + ':' } }, '');
    }
    this.selectedCarrier = form.form.value.businessTypes;
  }

  resetSearch() {   
    this.isSearched = false;
    this.isTextSearch = false;
    this.isValidUserName = false;
    this.searchForm.reset();
    this.searchForm.markAsUntouched();
    this.isSelected = false;
    this.gridInitialized = false;
    this.isReset = false;
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.autoDismiss=true;
    this.options.timeOut=10000;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }

  navigateToModify(data) {
    data.src_screen = 'modify_user';
    this.dataSvc.getUserData(data, 'user-list')
      .then(res => {
        if (res.status === 200) {
          this.dataSvc.existingUserData = res['data']['wholesale-users'][0];
          this.router.navigate(['manageUsers/modifyUsers']);
        }
      })
      .catch((err) => {
      });
  }




}
