import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-uploads-action-items-renderer',
  templateUrl: './uploads-action-items-renderer.component.html',
  styleUrls: ['./uploads-action-items-renderer.component.less']
})
export class MyUploadsActionsRendererComponent {

  @Input() public status: any;
  // linkCopied: string ="Copy"
  private params;
  fileDesc:string;

  agInit(params: any): void {
    this.status = params.value;
    this.params = params;
    this.fileDesc = 'download '+ params.data.fileType + ' of ' + params.data.fileDescription;
  }

  downloadDocument(){
    this.params.clicked(this.params.data, "download");
  }

}
