import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { CustomerService } from '../customer.service';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../../grid-filter/custom-date-filter/custom-date-filter.component';
// import { GridData } from '../../../../shared/models/common-model';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import * as moment_ from 'moment';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
// import { DashboardService } from '../../../../modules/dashboard/dashboard.service';
// import { GridTilesCustomerViewComponent } from '../components/grid-tiles-customer-view/grid-tiles-customer-view.component';
// import { UsersService} from '../../users/users.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerListComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public gridConfig: GridOptions;
  rowData;
  private columnDefs;
  gridInitialized: boolean;
  subscription: any;
  subscribedflag: Boolean = false;
  options: GlobalConfig;
  moment = moment_;
  roleList: Array<String> = [];
  type = ['success', 'error', 'info', 'warning'];
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  public aemLabelAuthoring: any;
  constructor(private dataSvc: CustomerService,
    private router: Router,
    private toastr: ToastrService,
    private loader: LoadingServiceService,
    private titleService: Title,
    private sharedService: SharedService
    // private dashBoardSvc: DashboardService, private userService: UsersService
  ) {
    this.titleService.setTitle('Amplitel Customers');
    //  const userRoleDetails = this.dashBoardSvc.getroleDetails();
    // this.userService.getValidRoleDetails();
    // if (userRoleDetails.appList.indexOf('tw-hub') === -1 || userRoleDetails.roleList.length === 0 ||
    //   userRoleDetails.appList.length !== userRoleDetails.roleList.length ||
    //   userRoleDetails.roleList.length !== userRoleDetails.cidnList.length) {
    //   this.router.navigateByUrl('/access-denied');
    // } else {
    this.getAccessDetails();
    // }
    this.createGrid(null);
  }

  getAccessDetails() {
    // if (this.userService.validUserRoles.length === 0) {
    //   return new Promise((resolve, reject) => {
    //     this.userService.getValidRoleDetails().then(res => {
    //       if (this.userService.validUserRoles.length === 0) {
    //         this.router.navigateByUrl('/access-denied');
    //       } else {
    //         this.options = this.toastr.toastrConfig;
    //         this.getLabels();
    //         this.createGrid(null);
    //       }
    //       resolve(true);
    //     }).catch((err) => {
    //       reject(false);
    //     });
    //   });
    // } else {
    this.options = this.toastr.toastrConfig;
    this.getLabels();
    //   this.createGrid(null);
    // }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getCustomerListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.successMsg();
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  successMsg() {
    if (this.dataSvc.createCustomerFormData) {
      let successMsg: string;
      if (this.dataSvc.createCustomerFormData.page === 'Create') {
        successMsg = 'You have successfully created a new customer.';
      } else if (this.dataSvc.createCustomerFormData.page === 'Modify') {
        successMsg = 'You have successfully modified existing customer.';
      }
      this.showToasterMessage(successMsg, '', this.options, 'success');
      this.dataSvc.createCustomerFormData = '';
    }
  }

  createGrid(payload) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      this.router.navigateByUrl('/error');
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridHeader().then(() => {
        this.columnDefs = this.dataSvc.gridHeaderDataObj.headers;
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData(params) {
    const param = params || null;
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridData(param).then(() => {
        this.rowData = this.dataSvc.gridDataObj;
        for (let i = 0; i < this.rowData.length; i++) {
          const selectedRow = this.rowData[i];
          for (const key in selectedRow) {
            if (!selectedRow[key] || (selectedRow[key] && selectedRow[key] === 'null')) {
              selectedRow[key] = '';
            }
          }
        }
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }
  public counter = 0;
  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onSelectionChanged(arg) {
    const selectedRows = arg.gridApi.getSelectedRows();
    this.navigateToModify(selectedRows[0]);
  }

  onFilterSelected(filteredData) {
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  navigateToCreateUser() {
    this.router.navigate(['manageCustomers/createCustomer']);
   // location.reload();
  }

  download() {
    const data = [];
    const dataHeaders = ['Business Name', 'CIDN', 'Status', 'Created Date', 'Created By', 'Modified Date', 'Modified By'];
    // const now = this.moment().format('YYYYMMDDHHmmss');
    const now = this.moment().format('YYYYMMDD');
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    } else {
      if (this.rowData && this.rowData.length) {
        dataToBeDownloaded = this.rowData;
      }
    }

    dataToBeDownloaded.forEach(selectedRow => {
      data.push({
        'Business Name': selectedRow.businessName,
        'CIDN': selectedRow.cidn,
        'Status': selectedRow.status,
        'Created Date': selectedRow.createdDate,
        'Created By': selectedRow.createdBy,
        'Modified Date': selectedRow.modifiedDate,
        'Modified By': selectedRow.modifiedBy
      });
    });

    new Angular5Csv(data, 'Registered Customers ' + now, { headers: dataHeaders });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss=true;
    this.options.timeOut=10000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }

  navigateToModify(data) {
    this.dataSvc.getCustomerData(data).then(res => {
      this.dataSvc.setCreateCustomerData(res.data);
      this.router.navigate(['manageCustomers/modifyCustomers']);
    })
      .catch((err) => {
        this.showToasterMessage(err.error.message, '', this.options, 'error');
      });
  }
  ngOnDestroy() {
    // this.toastr.clear();
  }
}
