import { map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, isDevMode } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'tw-core-ui';
@Injectable()
export class DataService {
  public enquiryUrl: string;
  public env: any = environment;
  public headers: HttpHeaders;
  private apiURL = {
    enquiryUrl: 'service-management/tickets'
  };

  constructor(@Inject(HttpClient) private http: HttpClient, private router: Router,
    private authService: AuthService) {
    const queryUrl = this.env.apiPoint;
    this.enquiryUrl = queryUrl + this.apiURL['enquiryUrl'];
  }

  public submitEnquiryDetails(param: any): Observable<HttpResponse<Object>> {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '');
    return this.submitEnquiry(param);
  }
  submitEnquiry(param: any) {
    const resp = this.http.post?.<HttpResponse<Object>>(
      this.enquiryUrl,
      param, { headers: this.headers, observe: 'response' })
      .pipe(map((response) => {
        if (response) {
          return response;
        }
      })).pipe(catchError((err: Error) => {
        return throwError(err);
      }))
    if (resp) {
      return resp;
    }
  }
}



