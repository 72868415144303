import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filetype-renderer-uploads-listing',
  templateUrl: './filetype-renderer-uploads-listing.component.html',
  styleUrls: ['./filetype-renderer-uploads-listing.component.less']
})
export class FiletypeRendererUploadsListingComponent {

  @Input() public filetype: any;
  fileDesc:string;
  imgPath:string;
  list: string[] = ['pdf','csv'];

  agInit(params: any): void {
    this.filetype = params.value;
    this.fileDesc = params.value + ' file of '+ params.data.fileDescription;
    if(this.filetype && this.list.includes(this.filetype.replace(/\s+/g, '').trim())){
      this.imgPath="../../../assets/images/documents/icon-"+this.filetype.replace(/\s+/g, '').trim()+'.svg';
    }
    else this.imgPath="../../../assets/images/documents/icon-default.svg";
      
  }
    
}
