import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-acknowledge-page',
  templateUrl: './acknowledge-page.component.html',
  styleUrls: ['./acknowledge-page.component.less']
})
export class AcknowledgePageComponent implements OnInit {
  private env: any = environment;
  focusableElement: HTMLElement;

  constructor(private router: Router, private titleService: Title, private sharedService: SharedService) {
    {
      this.titleService.setTitle('Amplitel - Acknowledgement');
    }
  }
  ngOnInit(): void {
    this.sharedService.focusOnElement('header_logo');
  }

  onAcknowledge() {
    this.router.navigate([]).then(result => { window.open(this.env.amsUrl, '_blank'); });
  }
  cancel() {
    this.router.navigateByUrl('/');
  }

  cancelTab() {
    this.sharedService.focusOnElement('header_logo');
  }

}
