import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';
import { ProfileData } from '../header/profile.model';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private env: any = window['environment'];
    private userObj: any = window['userManagerObj'];
    public profileAuthoringDataObj: ProfileData;
    private aemDefaultUri = {
        profileLabels: 'profile-authoring-data'
      };
    constructor(private http: HttpClient, private authService: AuthService) { }
  
  public getProfileDetails() {

      const profile = {
        'roles': this.authService.user ? this.authService.user.profile.roles : window['environment']['roles'],
        'contactName': this.authService.contactName() ? this.authService.contactName() : window['environment']['contactNameUI'],
        'contactEmail': this.authService.user ? this.authService.user.profile.username : window['environment']['email'],
        'userInitials': '',
        'targetUrl': this.userObj ? this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri : '',
      };
      
      // const profile = {
      //   'roles': [
      //     { "type": "cidn:tower-portal", "value": "10013:enquiryonlyuser" },
      //     { "type": "cidn:role", "value": "3804562469:enquiryplusamsuser" },
      //     { "type": "cidn:tower-portal", "value": "10012:ampliteladmin" },
      //     { "type": "cidn:tower-portal", "value": "9999999999:customeradmin" },
      //     { "type": "cidn:tower-portal", "value": "765140:billviewuser" },
      //     { "type": "cidn:tower-portal", "value": "987543109:amplitelbilladmin" }
      //   ],
      //   'contactName': 'Ayush Sahu',
      //   'contactEmail': 'ayush.sahu@team.telstra.com',
      //   'userInitials': 'AS',
      //   'targetUrl': ''
      // };
      profile.userInitials = profile.contactName.toUpperCase().split(' ').map(n => n[0]).join('');
      return profile;
  }

  public getProfileModel() {
    return new Promise((resolve, reject) => {
        if (!this.profileAuthoringDataObj) {
          this.http.get<any>(this.env.aemEndPoint + this.aemDefaultUri.profileLabels)
          .subscribe(
            res => {
              this.profileAuthoringDataObj = res.data.content;
              resolve(this.profileAuthoringDataObj);
            },
            err => {
              this.profileAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
          } else {
            resolve(this.profileAuthoringDataObj);
          }
      });
  }

  
}


