<div class="spinner" role="alert" aria-live="polite" aria-busy="true">
    <svg id="red-icon" xmlns="http://www.w3.org/2000/svg" width="46" height="23.435" viewBox="0 0 46 23.435">
        <path d="M393.2,921.7,381.915,910.42v-.87L393.2,898.267h23.435l11.283,11.283v.87L416.632,921.7l-11.717-11.717Z"
            transform="translate(-381.915 -898.267)" />
    </svg>
    <svg id="black-icon" xmlns="http://www.w3.org/2000/svg" width="46" height="23.435" viewBox="0 0 46 23.435">
        <path d="M393.2,921.7,381.915,910.42v-.87L393.2,898.267h23.435l11.283,11.283v.87L416.632,921.7l-11.717-11.717Z"
            transform="translate(-381.915 -898.267)" />
    </svg>
    <div class="loading-msg">Loading...</div>
</div>