export class UtilityService {

    private readonly nameRegEX = /^[a-zA-Z0-9'& -]+$/;
    private readonly stateRegEX = /^[a-zA-Z]+$/;
    private readonly emailRegEx = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/;
    private readonly serviceIdRegex = /^[A-Za-z0-9\s]{0,}$/;
    private readonly numberRegEx = /^(\+)?[0-9]{10,17}$/;
    private readonly shortNumberRegEx = /^(\+)?[0-9]{1,5}$/;
    private readonly domainRegEx = /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/;
    private readonly macAddressRegEx = /([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/gm;
    private zipCodeRegEx = /^(\+)?[0-9]{4,}$/;
    private readonly alternateNumberRegEx = /^(\+)?[0-9]{10}$/;
    private readonly callDiversionNumberRegEx = /^0[0-9]{9}$/;
    private readonly onlynumberRegEx = /^[0-9]*$/;
    private readonly onlynumberdotRegEx = /^[0-9.]*$/;
    private readonly numberDotCommaRegEx = /^[0-9.,]*$/;
    private readonly latitudeRegex: RegExp = /^((\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,14})?)))$/;  
    private readonly longitudeRegex: RegExp = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,14})?))$/;
    // private readonly ColoNameRegEX = /^[a-zA-Z0-9& -]+$/;
    private readonly ColoNameRegEX = /^[^']*$/;
 

    constructor() { }
  
    getNameRegEx(): RegExp {
      return this.nameRegEX;
    }
    getColoNameRegEx(): RegExp {
      return this.ColoNameRegEX;
    }
    getEmailRegEx(): RegExp {
      return this.emailRegEx;
    }
    getNumberRegEx(): RegExp {
      return this.numberRegEx;
    }
    getShortNumberRegEx(): RegExp {
      return this.shortNumberRegEx;
    }
    getDomainRegEx(): RegExp {
      return this.domainRegEx;
    }
    getServiceNumberRegEx(): RegExp {
      return this.serviceIdRegex;
    }
    getMACAddressRegEx(): RegExp {
      return this.macAddressRegEx;
    }
    getZipCodeRegEx(): RegExp {
      return this.zipCodeRegEx;
    }
    getAlternateNumberRegEx(): RegExp {
      return this.alternateNumberRegEx;
    }
    getCallDiversionNumberRegEx(): RegExp {
      return this.callDiversionNumberRegEx;
    }
    getStreetNumberRegEx(): RegExp {
      return this.onlynumberRegEx;
    }
    getIndexRegex():RegExp {
      return this.onlynumberdotRegEx;
    }

    getPriceRegex(): RegExp {
      return this.numberDotCommaRegEx;
    }

    getSubAddrNumberRegEx(): RegExp {
      return this.onlynumberRegEx;
    }
    getStateRegEx(): RegExp {
      return this.stateRegEX;
    }
    getLatitudeRegEx(): RegExp {
      return this.latitudeRegex;
    }
    getLongitudeRegEx(): RegExp {
      return this.longitudeRegex;
    }
    scrollToElement(target) {
      const targetElement = document.getElementById(target);
      setTimeout(() => {
        this.animate(
          document.scrollingElement || document.documentElement,
          'scrollTop',
          '',
          window.pageYOffset,
          targetElement.offsetTop + 530,
          500,
          true
        );
      }, 0);
    }
    animate(elem, style, unit, from, to, time, prop) {
      if (!elem) {
        return;
      }
      const start = new Date().getTime(),
        timer = setInterval(function () {
          const step = Math.min(1, (new Date().getTime() - start) / time);
          if (prop) {
            elem[style] = from + step * (to - from) + unit;
          } else {
            elem.style[style] = from + step * (to - from) + unit;
          }
          if (step === 1) {
            clearInterval(timer);
          }
        }, 25);
      if (prop) {
        elem[style] = from + unit;
      } else {
        elem.style[style] = from + unit;
      }
    }
  
  }
  