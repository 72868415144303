import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomTextField, CustomDropDown, CustomDatePicker } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { CustomerService } from '../customer.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.less'],
})
export class CreateCustomerComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: any;
  searchfromclearDate: any;
  searchtoclearDate: any;
  currentDate;
  dateFormat = 'dd/mm/yyyy';
  today = new Date();
  CurrentDate = this.today;
  toDatecount = 0;
  fromDatecount = 0;
  fromDate;
  toDate;
  endDate;
  searchfromDate: any = '';
  optionsVendorServiceType: any[] = [];
  optionsCompanyType: any[] = [];
  startDate;
  searchToDateFlag = false;
  todayformattedDate = (new Intl.DateTimeFormat('en-US').format(this.CurrentDate));
  formattedDate = {
    date: this.todayformattedDate
  };
  frommaxDate: any;
  istoDate: Boolean = false;
  fromflag: Boolean = false;
  public tominDate: any;
  moment = moment;
  tomaxDate: any;
  maxDate;
  searchtoDate: any = '';
  //CreateCustomerData;
  type = ['success', 'error', 'info', 'warning'];
  options: GlobalConfig;
  customerRegex = /^[^,`=~#!<>+@]+$/;
  //cidnRegex = /^[0-9]+$/;
  cidnRegex = /^[0-9]{10}$/g;
  abnRegex = /^\d{11}$/;
  msoIdRegex = /^[^,`=~#!<>+@]+$/;
  public associatedCompanyData = [];
  public tableColAssociated: Array<string> = [];
  public isAddAssociatedVisible: boolean = true;
  businessNames = [];
  setAMSAppNo: any;
  companyNameDropdown = [];

  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public associatedCompanyDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'Associated Company Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };
  public amsAppAccessDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'AMS App Access Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };


  public businessNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter Business Name',
    id: 'businessName',
    tabindex: '',
    maxlength: '50',
    headerText: 'Business Name',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter values except ,`=~#!<>+@',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'businessName',
    formControlName: 'businessName',
    isDisabled: false,
    showValidationIcon: true,
  };
  public vendorServiceTypeModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessCidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessCidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'vendorServiceType',
    tabindex: '',
    maxlength: '5',
    headerText: 'Vendor Service Type',
    headerTextAccessible: 'Vendor Service Type',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'vendorServiceType',
    formControlName: 'vendorServiceType',
    isDisabled: false,
    showValidationIcon: true
  };
  public cidnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter CIDN',
    id: 'cidn',
    tabindex: '',
    maxlength: '10',
    headerText: 'CIDN',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter only numeric values',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'cidn',
    formControlName: 'cidn',
    isDisabled: false,
    showValidationIcon: true
  };

  public spidModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter SPID',
    id: 'spid',
    tabindex: '',
    maxlength: '5',
    headerText: 'SPID',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter SPID in numeric format, SPID must be upto 5 digits',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'spid',
    formControlName: 'spid',
    isDisabled: false,
    showValidationIcon: true
  };

  public startDatePicker: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'fromDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: true,
    name: 'fromDate',
    formControlName: 'vendorValidFrom',
    isDisabled: false,
    isWeekendsDisabled: false,
  };

  public completeDatePicker: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'toDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: true,
    name: 'toDate',
    formControlName: 'vendorValidTo',
    isDisabled: false,
    isWeekendsDisabled: false,
  };

  public statusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public parentCompanyModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Parent Company Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  // public companyTypeModel: CustomDropDown = {
  //   isDisabled: false,
  //   accessibleHeaderId: 'Company Type Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  // };

  public customerTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Customer Type Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public vendorStatusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Vendor Status Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public vendorSiteAccessModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Vendor Site Access Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public abnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessAbn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessAbn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserAbnValidationErrMsg',
    placeholderCaption: 'Enter ABN',
    id: 'abn',
    tabindex: '',
    maxlength: '11',
    headerText: 'ABN',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'abn',
    formControlName: 'abn',
    isDisabled: false,
    showValidationIcon: false
  };
  public msoIdModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessMsoId',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessMsoId',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserMsoIdValidationErrMsg',
    placeholderCaption: 'Enter MSO Id',
    id: 'msoid',
    tabindex: '',
    maxlength: '15',
    headerText: 'MSOID',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'msoid',
    formControlName: 'msoid',
    isDisabled: false,
    showValidationIcon: false
  };

  public createCustomerForm: FormGroup;

  constructor(private fb: FormBuilder,
    private customerService: CustomerService,
    private loader: LoadingServiceService,
    private toastr: ToastrService,
    private router: Router,
    private userService: UsersService,
    private utility: UtilityService,
    private titleService: Title,
    private sharedService: SharedService,
    private commonService: CommonService,
  ) {
    this.titleService.setTitle('Amplitel Create a Customer');
    this.options = this.toastr.toastrConfig;
  }
  public statusItems = [
    "Active",
    "Inactive"
  ];

  public associatedForDropdown = [
    "AMS",
    "SE",
    "AMS, SE"
  ]
  public amsAppOption = [
    "Yes",
    "No"
  ]
  vendorServiceType = ['SAR Vendor', 'EB Vendor', 'DG Vendor', 'Maintenance Vendor', 'TSSR Vendor', 'Construction Vendor', 'Project Partner'];
  @ViewChild('fromDatePicker') fromDatePicker: DatePickerDirective;
  @ViewChild('toDatePicker') toDatePicker: DatePickerDirective;
  parentCompany = ['First Suitable Solution and Stop', 'All Solutions'];
  companyType = ['Asset Owner', 'Infrastructure Owner', 'Licensee', 'Software Provider', 'Vendor'];
  customerType = ['MNO', 'Non-MNO'];
  vendorSiteAccess = ['Accredited', 'Banned from site', 'Unaccredited'];
  public vendorStatus = [
    "Active",
    "Inactive"
  ];
  op = ['Amplitel']
  startPlaceholder = 'Select date';
  isAsteriskRequired: boolean = false;
  isAsteriskRequiredVendor: boolean = false;
  vendorField: boolean = false;
  companyTypeOption: any;
   filterCompany = [...this.companyType];

  ngOnInit() {
    this.currentDate = this.getCurrentDateTimeInAEST();
    this.getCurrentDateTimeInAEST()
    this.sharedService.focusOnElement('header_logo');
    this.createForm();

    this.setBusinessNameDd();

    ////In case Admin tries to update company 'Amplitel' then he should not be able to select 'Vendor'
    // as a value for company_type attribute. Need to add validation to restrict the same.

    this.createCustomerForm.get('parentCompany')?.valueChanges.subscribe(option=>{
      if(option === 'Amplitel') {
        this.filterCompany = this.companyType.filter(type => type !== 'Vendor');
        if(this.createCustomerForm.get('companyType')?.value === 'Vendor'){
          this.createCustomerForm.get('companyType')?.setValue('');
        }
      } 
       else {
        this.filterCompany = [...this.companyType];
      }
    })

  }

  getCurrentDateTimeInAEST() {
    const timeZone = 'Australia/Sydney';
    const now = moment.tz(timeZone);
    const day = now.date();
    const month = now.month() + 1;
    const year = now.year();
    return { year, month, day };
  }

  createForm() {
    this.createCustomerForm = this.fb.group({
      businessName: ['', [Validators.required, Validators.pattern(this.customerRegex)]],
      cidn: ['', [Validators.required, Validators.pattern(this.cidnRegex)]],
      spid: ['00', [Validators.required, Validators.pattern(this.utility.getShortNumberRegEx())]],
      status: [undefined, [Validators.required]],
      abn: ['', [Validators.pattern(this.abnRegex)]],
      msoid: ['', [Validators.pattern(this.msoIdRegex)]],
      parentCompany: [''],
      companyType: ['', [Validators.required]],
      customer_type: [''],
      vendor_status: [''],
      vendorValidFrom: [''],
      vendorValidTo: [''],
      vendorSiteAccessStatus: [''],
      vendorServiceType: [''],
      companyId: [undefined],
      associatedFor: [undefined],
      allowAMSAppAccess: [undefined],
    });
    this.getLabels();
  }

  getLabels() {
    this.customerService.getCreateMyUserModel().then((data: any) => {
      this.aemLabelAuthoring = data;
      this.statusItems = this.aemLabelAuthoring.form.statusItems;
      this.businessNameModel.headerText = this.aemLabelAuthoring.form.businessName;
      this.businessNameModel.placeholderCaption = this.aemLabelAuthoring.form.businessNamePlaceholder;
      this.businessNameModel.validationMessage = this.aemLabelAuthoring.form.businessNameValidationMessage;
      this.businessNameModel.requiredText = this.aemLabelAuthoring.form.businessNameRequiredMessage;
      this.spidModel.headerText = this.aemLabelAuthoring.form.spid;
      this.spidModel.placeholderCaption = this.aemLabelAuthoring.form.spidPlaceholder;
      this.spidModel.validationMessage = this.aemLabelAuthoring.form.spidValidationMessage;
      this.spidModel.requiredText = this.aemLabelAuthoring.form.spidRequiredMessage;
      this.cidnModel.headerText = this.aemLabelAuthoring.form.cidn;
      this.cidnModel.placeholderCaption = this.aemLabelAuthoring.form.cidnPlaceholder;
      this.cidnModel.validationMessage = this.aemLabelAuthoring.form.cidnValidationMessage;
      this.cidnModel.requiredText = this.aemLabelAuthoring.form.cidnRequiredMessage;
      this.abnModel.headerText = this.aemLabelAuthoring.form.abn;
      this.abnModel.placeholderCaption = this.aemLabelAuthoring.form.abnPlaceholder;
      this.abnModel.validationMessage = this.aemLabelAuthoring.form.anbValidationMessage;
      this.msoIdModel.headerText = this.aemLabelAuthoring.form.msoid;
      this.msoIdModel.placeholderCaption = this.aemLabelAuthoring.form.msoidPlaceholder;
      this.msoIdModel.validationMessage = this.aemLabelAuthoring.form.msoidValidationMessage;
      this.tableColAssociated = this.aemLabelAuthoring.form.associatedTableHeader;


    }).catch((error) => {
    });
  }

  onSubmit(): any {
    let errMsgs: string = 'Unable to create/update the customer account in Enquiry System, please contact Level-1 support team';
    // this.loader.setLoader(true);
    const associatedEntry = this.associatedCompanyData.map(item => ({
      companyId: item.cidn,
      associatedFor: item.associatedFor.toLowerCase(),
      allowAMSAppAccess: item.allowAMSAppAccess,
    }))
    const DATA = this.createCustomerForm.value;
    console.log(DATA,'data')
    this.customerService.createCustomer(DATA, associatedEntry).then((response) => {
      // this.loader.setLoader(false);
      this.customerService.createCustomerFormData = this.createCustomerForm.value;
      this.customerService.createCustomerFormData['page'] = 'Create';
      this.router.navigate(['manageCustomers/customers']);
    }).catch((err) => {
      if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
        this.showToasterMessage(this.aemLabelAuthoring.form.errMsg, '', this.options, 'error');
      } else {
        const msg = err.error.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      }
    });
  }

  cancelCreate() {
    this.toastr.clear();
    this.createCustomerForm.reset();
    this.createCustomerForm.markAsUntouched();
    this.router.navigate(['manageCustomers/customers']);
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 10000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  minMaxformatter(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,// as getMonth() count starts from 0
      day: date.getDate()
    };
  }


  onToDateChange($event) {
    this.searchToDateFlag = true;
    this.toDate = $event.date;
    // Configure highlighting
    if (this.toDate) {
      this.toDate = this.toDate.replace(/\//g, '-');
      this.toDate = this.toDate.split('-').reverse().join('-');
      this.searchtoDate = this.moment(this.toDate).isValid();
      if (this.toDatecount < 1) {
        this.searchtoDate = '';
      }
      this.toDatecount++;
      this.toDate = this.moment(this.toDate).format('DD-MMM-YYYY');
      // this.toDate = new Date(this.toDate);
      const setMinFromdate = this.toDate;
      if (this.toDatecount > 1 && (this.searchToDateFlag) && this.searchtoDate) {
        this.searchtoDate = this.moment(this.toDate).format('DD/MM/YYYY');
      }
      if (setMinFromdate < this.today && setMinFromdate.getFullYear() !== this.today.getFullYear()) {
        this.frommaxDate = {
          year: setMinFromdate.getFullYear(),
          month: setMinFromdate.getMonth() + 1,
          day: setMinFromdate.getDate() - 1
        };
      } else if (setMinFromdate < this.today && setMinFromdate.getFullYear() === this.today.getFullYear()) {
        this.frommaxDate = {
          year: setMinFromdate.getFullYear(),
          month: setMinFromdate.getMonth() + 1,
          day: setMinFromdate.getDate()
        };
      } else {
        this.frommaxDate = {
          year: this.today.getFullYear(),
          month: this.today.getMonth() + 1,
          day: this.today.getDate()
        };
      }
    }

    let parseDate = new Date(this.toDate)
    const payloadToDate = this.moment(parseDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss');
    console.log(payloadToDate, 'payload')
    this.createCustomerForm.get('vendorValidTo')?.setValue(payloadToDate);

  }

  onFromDateChange($event) {
    this.fromDate = $event.date;
    this.searchfromDate = this.moment(this.fromDate, 'DD/MM/YYYY').format('DD MMM YYYY');
    let date = new Date(this.searchfromDate);
    this.tominDate = this.minMaxformatter(date);
    let dateAdded = new Date(date.setDate(date.getDate() + 30));
    this.maxDate = this.minMaxformatter(dateAdded);
    console.log(this.maxDate, dateAdded)
    this.fromDate = this.fromDate.replace(/\//g, '-');
    this.fromDate = this.fromDate.split('-').reverse().join('-');
    this.fromDate = this.moment(this.fromDate).format('DD-MMM-YYYY');
    this.searchtoclearDate = this.fromDate;
    this.searchtoDate = '';
    this.completeDatePicker.isDisabled = false;
    let parseDate = new Date(this.fromDate)
    const payloadFromDate = this.moment(parseDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss');
    console.log(payloadFromDate, 'payload')
    this.createCustomerForm.get('vendorValidFrom')?.setValue(payloadFromDate);
  }


  selectionChanged(event) {
    this.optionsVendorServiceType = event;
  }

  selectionChangedCompanyType(event) {
    const optionsCompanyType = event.map((item: any) => item.value);
    console.log(optionsCompanyType, event, 'company')

    //both
    if (optionsCompanyType.includes('Vendor') && optionsCompanyType.includes('Asset Owner')) {
      this.createCustomerForm.get('customer_type')?.setValidators([Validators.required]);
      this.isAsteriskRequired = true;


      this.vendorField = true;
      this.createCustomerForm.get('vendor_status')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorValidFrom')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorValidTo')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorSiteAccessStatus')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorServiceType')?.setValidators([Validators.required]);
      this.isAsteriskRequiredVendor = true;


    }
    //only asset
    else if (optionsCompanyType.includes('Asset Owner')) {
      this.createCustomerForm.get('customer_type')?.setValidators([Validators.required]);
      this.isAsteriskRequired = true;
      this.isAsteriskRequiredVendor = false;
      this.vendorField = false;
    }
    //only vendor
    else if (optionsCompanyType.includes('Vendor')) {
      this.vendorField = true;
      this.createCustomerForm.get('vendor_status')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorValidFrom')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorValidTo')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorSiteAccessStatus')?.setValidators([Validators.required]);
      this.createCustomerForm.get('vendorServiceType')?.setValidators([Validators.required]);
      this.isAsteriskRequiredVendor = true;
      this.isAsteriskRequired = false;
    }

    else {
      this.createCustomerForm.get('customer_type')?.clearValidators();
      this.createCustomerForm.get('customer_type')?.updateValueAndValidity();
      this.createCustomerForm.get('vendor_status')?.clearValidators();
      this.createCustomerForm.get('vendor_status')?.updateValueAndValidity();
      this.createCustomerForm.get('vendorValidFrom')?.clearValidators();
      this.createCustomerForm.get('vendorValidFrom')?.updateValueAndValidity();
      this.createCustomerForm.get('vendorValidTo')?.clearValidators();
      this.createCustomerForm.get('vendorValidTo')?.updateValueAndValidity();
      this.createCustomerForm.get('vendorSiteAccessStatus')?.clearValidators();
      this.createCustomerForm.get('vendorSiteAccessStatus')?.updateValueAndValidity();
      this.createCustomerForm.get('vendorServiceType')?.clearValidators();
      this.createCustomerForm.get('vendorServiceType')?.updateValueAndValidity();
      this.isAsteriskRequired = false;
      this.isAsteriskRequiredVendor = false;
      this.vendorField = false;
    }
  }

  ngOnDestroy() {
    // this.toastr.clear();
  }

  openAssociatedBoxModel() {
    this.isAddAssociatedVisible = true;
  }
  roleChange(e) { }

  addAssociatedCompany() {
    if (this.associatedCompanyData.length === 0) {
      this.addedAssociateData();
      return;
    }

    const isAlreadyAdded = this.associatedCompanyData.some(item => {
      return item.companyId == this.createCustomerForm.value.companyId
    });

    if (!isAlreadyAdded) {
      this.addedAssociateData();
    } else {
      this.showToasterMessage(
        this.aemLabelAuthoring.form.multipleBusinessMessage,
        '',
        this.options,
        'error'
      );
    }
  }

  addedAssociateData() {
    
    let data = this.businessNames.filter(x=>{
      return x['Business name']==this.createCustomerForm.value.companyId;
    });
    let cidn = data.length?data[0].CIDN:'';
    this.associatedCompanyData.push({
      cidn: cidn,
      companyId: this.createCustomerForm.value.companyId,
      associatedFor: this.createCustomerForm.value.associatedFor,
      allowAMSAppAccess: this.createCustomerForm.value.allowAMSAppAccess,
    });
    this.closeBox();
  }


  removeAssociateRow(index) {
    this.associatedCompanyData.splice(index, 1);
  }


  isAssociatedSaveBtnDisabled() {
    return !(this.createCustomerForm.get('companyId')?.value && this.createCustomerForm.get('associatedFor')?.value && this.createCustomerForm.get('allowAMSAppAccess')?.value)
  }

  closeBox() {
    this.isAddAssociatedVisible = false;
    this.createCustomerForm.controls['companyId'].reset();
    this.createCustomerForm.controls['associatedFor'].reset();
    this.createCustomerForm.controls['allowAMSAppAccess'].reset();
  }

  setBusinessNameDd() {
    this.loader.setLoader(true)
    //const params = { infraco: 'false' };
    this.userService
      .getAllBusinessNamesForAdmin('').subscribe(res => {
        const businessRoleData: any = res.data;
        console.log(businessRoleData)
        this.businessNames = businessRoleData.BusinessNameDetails;
        this.businessNames.forEach((element) => {
          this.companyNameDropdown.push(element['Business name']);
        });
        this.loader.setLoader(false)
      });


  }

  onAssociatedCompanyChanged(value) {
    if (value === 'SE') {
      this.createCustomerForm.value.allowAMSAppAccess = 'No';
      this.setAMSAppNo = 'No';
      this.amsAppAccessDropDownModel.isDisabled = true;
    } else {
      this.createCustomerForm.value.allowAMSAppAccess = '';
      this.setAMSAppNo = '';
      this.amsAppAccessDropDownModel.isDisabled = false;
    }
  }
}



