import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomProgressBar, CustomTextField, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import * as moment_ from 'moment-timezone';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { TicketSummaryService } from '../services/ticket-summary.service';
import { AttachmentDetails, AttachmentMetaData, CustomFileUploadCaseDetails } from '../file-uploads.model';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'app-ticket-summary',
  templateUrl: './ticket-summary.component.html',
  styleUrls: ['./ticket-summary.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TicketSummaryComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('fileUploadInput') fileUploadInput;
  tabName: string;
  @ViewChild('tooltipTicketSummary1') tooltip;
  public aemLabelAuthoring: any;
  public historyDet = [];
  public loggedDateconv;
  currentMoment = moment_;
  showread = false;
  altName: any;
  altEmail: any;
  altNumber: any;
  ticketSummaryData: any;  
  updateDetailsData: any;
  updatedDateConv: any;
  EditContactName: boolean = false;
  EditContactEmail: boolean = false;
  EditContactNumber: boolean = false;
  disablecancel: boolean = false;
  editclicked: boolean = false;
  state: any;
  serviceDetails: any;
  history = [];
  moment = moment_;
  selectedTimeZone: string = "Australia/Victoria";
  options: GlobalConfig;
  cidn: any;
  enquiryType: any;
  ticketId: String;
  ticketDetails: any;
  createdDate: any;
  updatedDate: any;
  showDetails: Boolean;
  statusIsOnHold: Boolean;
  statusIsInProgress: Boolean;
  statusIsClosed: Boolean = false;
  shortdesp: any;
  statusIsResolved: Boolean;
  statusIsNew: Boolean;
  statusIsAssigned: Boolean;
  showStatus: Boolean;
  enquirySubtype: any;
  loggedby;
  formSubmitted: boolean = false;
  disagreeFormSubmitted: boolean = false;
  public closeTicketForm: FormGroup;
  public reopenForm: FormGroup;

  closureTicketSubmitRequired: boolean;
  closuresubTitle = "Provide other detail as to why you're closing this enquiry";
  agreeClicked: boolean;
  selectedTab = 0;
  onRefresh: boolean = false;
  public progressBarModel: CustomProgressBar = {
    statusLabel: '',
    showCompact: true,
    focus: false
  };
  public bandwidthTooltipContentModel: TooltipContent = {
    content: 'Total upload size is 8MB. Allowed File Extensions  xls, xlsx, csv, doc, docx, pdf, jpeg, jpg, png, ico, bmp, pptx, zip, txt, eml, msg, json',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  fileuploadObj: CustomFileUploadCaseDetails = {
    id: 'addCommentTextArea',
    formControlNameDescription: 'fileUploadComments',
    attachmentObj: [],
    fileUploadLabelAuthoring: {
      fileSizeLimit: '',
      fileSizeLimitMessage: '',
		  fileZeroMessage:'',
      fileSizeLimitMessageCombined: '',
      fileLimitMessage: '',
      invalidExtensionMessage: '',
      validExtensions: [],
      fileInfo: '',
      fileuploadDragText: '',
      fileuploadDragTextViewTicket: '',
      fileuploadNoFileText: '',
      ticketSummaryErrMsg: ''
    }
  };
  fieldIds = ['contactName', 'contactMobile', 'contactEmail'];
  primaryName: string = sessionStorage.getItem('contactName') ? sessionStorage.getItem('contactName') : '';
  isModalOpen: boolean = false;
  isDisagreeModalOpen: boolean = false;
  commentForm: FormGroup;
  validError: string;
  fileArr: File[];
  attachBoolean: boolean = false;
  sysTicketId: string;

  constructor(private router: Router, private titleService: Title,
    private ticketSummaryService: TicketSummaryService,
    private toastr: ToastrService, private activeRoute: ActivatedRoute,   
    private utility: UtilityService,
    private translate:TranslateService,
    private sharedService: SharedService) {
    this.titleService.setTitle('Enquiry Summary');
    this.translate.addLangs(["en","fr","cn","hi"]);
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    this.getLabels();
    this.options = this.toastr.toastrConfig;

    this.closeTicketForm = new FormGroup({
      closure: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
    });
    this.reopenForm = new FormGroup({
      disagree: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
    });
    this.commentForm = new FormGroup({
      comment: new FormControl('', [Validators.required, this.noWhitespaceValidator])
    });
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.sharedService.focusOnElement('header_logo');
  }

  contactForm = new FormGroup({
    contactName: new FormControl('', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
    contactMobile: new FormControl('', [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]),
    contactEmail: new FormControl('', [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]),
  });
  public ContactNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblContactName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxContactName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Name',
    id: 'contactName',
    tabindex: '0',
    maxlength: '100',
    headerText: 'Name',
    headerTextAccessible: 'Name',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactName',
    formControlName: 'contactName',
    isDisabled: false,
    showValidationIcon: false
  };
  public ContactNumberModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblContactNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxContactNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Contact Number',
    id: 'contactMobile',
    tabindex: '0',
    maxlength: '17',
    headerText: 'Contact Number',
    headerTextAccessible: 'Contact Number',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactMobile',
    formControlName: 'contactMobile',
    isDisabled: false,
    showValidationIcon: false
  };
  public emailModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblemail',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxemail',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Email Address',
    id: 'contactEmail',
    tabindex: '0',
    maxlength: '100',
    headerText: 'Email Address',
    headerTextAccessible: 'Email Address',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactEmail',
    formControlName: 'contactEmail',
    isDisabled: false,
    showValidationIcon: false
  };

  public getAriaLabel() {
    return ('Write your short comment below' + '. Character limit: ' + 300);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.ticketSummaryService.getTicketSummaryDetails().then(() => {
        this.aemLabelAuthoring = this.ticketSummaryService.ticketSummaryObj;
        this.fileuploadObj.fileUploadLabelAuthoring = this.aemLabelAuthoring.fileUpload;
        this.getServiceDetails();
      }).catch((err) => {
        reject(err);
      })
    });
  }

  browseFolder() {
    (<HTMLElement>document.getElementById('browseFileCreateTicket')).click();
  }

  onChangeFile(file: any) {
    let fileToUpload: FileList;
    if (file && file.item) {
      fileToUpload = file;
    } else if (file && file.files) {
      fileToUpload = file.files;
    }
    if (fileToUpload) {
      this.fileArr = Array.from(fileToUpload);
      Array.from(this.fileArr).forEach(fileItem => {
        const attachmentMetaData: AttachmentMetaData = new AttachmentMetaData();
        attachmentMetaData.validationError = '';
        attachmentMetaData.attachmentDetails = new AttachmentDetails();
        this.validateAndCreateFile(fileItem, attachmentMetaData);
        if (attachmentMetaData.attachmentDetails.fileName !== '' && this.validError === '') {
          if (this.fileuploadObj.attachmentObj.length >= 1) {
            this.ValidateMultipleAttach(this.fileuploadObj.attachmentObj, attachmentMetaData);
          }
          else {
            this.fileuploadObj.attachmentObj.push(attachmentMetaData);
          }
        }
      });
      this.fileUploadInput.nativeElement.value = '';
    }
  }

  getFilesCount(): number {
    return this.fileuploadObj.attachmentObj.length;
  }

  clearFolderSelection(value: AttachmentMetaData) {
    this.clearFileFields(value);
    this.fileUploadInput.nativeElement.value = '';
  }
 
  editcontactfunct() {
    this.EditContactName = true;
    this.EditContactEmail = true;
    this.EditContactNumber = true;
    this.editclicked = true;
  }
  canceleditcontact() {
    this.EditContactName = false;
    this.EditContactEmail = false;
    this.EditContactNumber = false;
  }
  validateAndCreateFile(fileItem: File, attachmentMetaData: AttachmentMetaData) {
    this.validError = '';
    const fileExtension = fileItem.name.split('.')[fileItem.name.split('.').length - 1].toUpperCase();
    attachmentMetaData.attachmentDetails.size = fileItem.size.toString();
    attachmentMetaData.attachmentDetails.fileName = fileItem.name;
    attachmentMetaData.isUploadDone = '';
    attachmentMetaData.canShowProgressBar = true;
    if ((<any>this.fileuploadObj.fileUploadLabelAuthoring.validExtensions).indexOf(fileExtension) === -1) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.invalidExtensionMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.invalidExtensionMessage;
    } else if (fileItem.size > (<number><any>this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimit)) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
    } else if (fileItem.size === 0) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileZeroMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileZeroMessage;  
    } else {
      if (fileExtension === 'MSG') {
        attachmentMetaData.attachmentDetails.ContentType = 'message/rfc822';
      } else {
        attachmentMetaData.attachmentDetails.ContentType = fileItem.type;
      }
    }
    if (!attachmentMetaData.validationError) {
      attachmentMetaData.attachmentDetails.file = fileItem;
      if (attachmentMetaData.attachmentDetails.fileName) {
        attachmentMetaData.isUploadDone = '3bb07ff9-5204-4b55-90d4-bcf8a93defd5';
        // setTimeout(() => {
        attachmentMetaData.canShowProgressBar = false;
        // }, 1000);
      };
      //}
    }
  }

  ValidateMultipleAttach(Arr, attachmentMetaData: AttachmentMetaData) {
    let fileSize: number = 0;
    this.validError = '';
    Arr.map(val => {
      fileSize += parseInt(val.attachmentDetails.size);
    });
    fileSize += parseInt(attachmentMetaData.attachmentDetails.size);
    if (fileSize >= 8388608) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessageCombined;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessageCombined;
    }
    if (Arr.length >= 10) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileLimitMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileLimitMessage;
    }
    if (this.validError === '') {
      this.fileuploadObj.attachmentObj.push(attachmentMetaData);
    }
  }

  keyEnterEvent(evt: KeyboardEvent, value) {
    const KEY_ENTER = 13;
    const keyCode = evt.which;
    const validKeys = [KEY_ENTER];
    if (validKeys.indexOf(keyCode) >= 0) {
      evt.preventDefault();
      evt.stopPropagation();
      this.clearFolderSelection(value);
    }
  }

  clearFileFields(value: AttachmentMetaData) {
    this.validError = '';
    this.fileuploadObj.attachmentObj.splice(this.fileuploadObj.attachmentObj.indexOf(value), 1);
  }

  clearAllAttachments() {
    this.validError = '';
    this.fileuploadObj.attachmentObj = [];
  }

  getServiceDetails() {
    const routeParams = this.activeRoute.snapshot.params;
    if (routeParams) {
      this.cidn = routeParams.cidn;
      this.ticketId = routeParams.ticketId;
    }
    const serviceDetailsData = this.ticketSummaryService.getServiceDetailsData();

    if (!serviceDetailsData) {
      this.sysTicketId = sessionStorage.getItem('sysTicketId') ? sessionStorage.getItem('sysTicketId') : '';
      return new Promise((resolve, reject) => {
        this.ticketSummaryService.getServiceDetails(this.sysTicketId).then((res) => {
          this.serviceDetails = res['result'];
          this.serviceDetailsDataFormat(this.serviceDetails);
          resolve(true);
        }).catch(() => {
          this.router.navigateByUrl('/error');
          reject(false);
        });
      });
    } else {
      this.sysTicketId = serviceDetailsData.sysTicketId;
      sessionStorage.setItem('sysTicketId', this.sysTicketId);
      this.serviceDetails = serviceDetailsData;
      this.serviceDetailsDataFormat(serviceDetailsData);
    }    
  }

  serviceDetailsDataFormat(detailsData) {
    this.serviceDetails = detailsData;
    if (this.serviceDetails) {
      this.shortdesp = this.serviceDetails.ticketShortDesc;
      this.enquiryType = this.serviceDetails.ticketCategory;
      this.enquirySubtype = this.serviceDetails.ticketSubCategory;
      this.ticketDetails = this.serviceDetails.ticketDetails;
      this.loggedDateconv = this.serviceDetails.createdDate;
      this.createdDate = this.loggedDateconv ? this.currentMoment(this.loggedDateconv).format('DD MMM YYYY HH:mm') : '';
      this.updatedDateConv = this.serviceDetails.updatedDate;
      this.updatedDate = this.updatedDateConv ? this.currentMoment(this.updatedDateConv).format('DD MMM YYYY HH:mm') : '';
      this.history = this.serviceDetails.history;
      this.state = this.serviceDetails.state;
      this.altName = this.serviceDetails.secContactName;
      this.altEmail = this.serviceDetails.secContactEmail;
      this.altNumber = this.serviceDetails.secContactNumber;
      this.loggedby = this.serviceDetails.contactName;
      this.historyDet = [];
      for (let i = 0; i < this.history.length; i++) {
        this.historyDet.push(this.history[i]);
        if (this.historyDet[i].comment > 250) {
          this.showread = true;
        }
      }
      for (let i = 0; i < this.historyDet.length; i++) {
        this.historyDet[i].date = this.historyDet[i].date ? this.currentMoment(this.historyDet[i].date).format('DD MMM YYYY HH:mm') : '';
        this.historyDet[i].author = this.historyDet[i].author ? this.historyDet[i].author.toUpperCase().split(' ').map(n => n[0]).join('').slice(0, 2) : '';
      }

      if (this.state) {
        this.showStatus = true;
      }
      if (this.state && this.state.toLowerCase() == "on hold") {
        if (this.serviceDetails.subStatus.toLowerCase() === "waiting for customer input") {
          this.statusIsOnHold = true;
        } else {
          this.statusIsOnHold = false;
        }
      }
      if (this.state && this.state.toLowerCase() == "in progress") {
        this.statusIsInProgress = true;
      }
      if (this.state && this.state.toLowerCase() == "closed") {
        this.statusIsClosed = true;
        this.commentForm.get('comment').disable();
        this.contactForm.get('contactName').disable();
        this.contactForm.get('contactEmail').disable();
        this.contactForm.get('contactMobile').disable();
      }
      if (this.state && this.state.toLowerCase() == "resolved") {
        this.statusIsResolved = true;
      }
      if (this.state && this.state.toLowerCase() == "new") {
        this.statusIsNew = true;
      }
      if (this.state && this.state.toLowerCase() == "assigned") {
        this.statusIsAssigned = true;
      }
    }
  }
  // formatDate(inputDate) {
  //   if (inputDate) {
  //     let outputDate;
  //     const date = inputDate && (inputDate !== "null") ? inputDate.split(' ') : '';
  //     if (date.length > 1) {
  //       const dateArray = date[0].split('-');
  //       const timeArray = date[1].split(':');
  //       outputDate = this.moment(new Date(dateArray[0], dateArray[2] - 1, dateArray[1])).tz(this.selectedTimeZone).format('DD MMM YYYY') + ' ' + this.moment(new Date(timeArray[0], timeArray[2] - 1, timeArray[1])).tz(this.selectedTimeZone).format('HH:mm ') + this.moment(this.moment(new Date(dateArray[0], dateArray[2] - 1, dateArray[1])).tz(this.selectedTimeZone).format('DD MMM YYYY')).tz(this.selectedTimeZone).format('z');
  //     } else {
  //       outputDate = this.moment(inputDate).tz(this.selectedTimeZone).format('DD MMM YYYY HH:mm ') + this.moment(this.moment(inputDate).tz(this.selectedTimeZone).format('DD MMM YYYY HH:mm ')).tz(this.selectedTimeZone).format('z');
  //     }
  //     return outputDate;
  //   }
  // }

  onSubmit() {
    // this.commentForm.get('comment').setValue((<HTMLInputElement>document.getElementById('comment')).value.trim());
    const enquiryValues = this.commentForm.value;
    this.commentForm.reset();
    let fileList = false;
    if (this.fileuploadObj.attachmentObj.length !== 0) {
      this.attachBoolean = true;
      this.validError = '';
      fileList = true;
      this.fileuploadObj.attachmentObj.forEach((singleAtt, i, array) => {
        this.ticketSummaryService.createSingleAttachment([singleAtt.attachmentDetails.file], this.serviceDetails.sysTicketId).then(res => {
          if (i === array.length - 1) {
            this.attachBoolean = false;
            this.showToasterMessage(this.aemLabelAuthoring.form.successToastrMsgAttach, '', this.options, 'success');
          }
        }).catch((err) => {
          if (i === array.length - 1) {
            this.attachBoolean = false;
            this.showToasterMessage(this.aemLabelAuthoring.form.errorToastrMsgAttach, '', this.options, 'error');
          }
        });
      });
    }
    this.fileuploadObj.attachmentObj = [];

    if (enquiryValues.comment !== '' && enquiryValues.comment != null) {
      enquiryValues.comment = `||Author:${this.primaryName}||\n` + enquiryValues.comment;
    }
      let payload;
      if (fileList) {
         payload = {
          "contactEmail": this.serviceDetails.contactEmail,
          "contactName": this.serviceDetails.contactName,
          "ticketNumber": this.serviceDetails.ticketNumber,
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "",
          "notes": enquiryValues.comment,
          "isAttachmentPresent":"true"
        };
      } else {
        payload = {
          "contactEmail": this.serviceDetails.contactEmail,
          "contactName": this.serviceDetails.contactName,
          "ticketNumber": this.serviceDetails.ticketNumber,
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "",
          "notes": enquiryValues.comment,
          "isAttachmentPresent":"false"
        };
      }
      if(this.serviceDetails.secContactName && this.serviceDetails.secContactEmail && this.serviceDetails.secContactNumber){
        payload.isAlternvContDtlsPresent = "true"
      } else {
        payload.isAlternvContDtlsPresent = "false"
      }

      return new Promise((resolve, reject) => {
        this.ticketSummaryService.updateNotes(payload, this.serviceDetails.sysTicketId).then((res:any) => {
          if(res.code ===200){
            this.showToasterMessage(this.aemLabelAuthoring.form.successMsg, '', this.options, 'success');
          }         
          this.updateDetailsData = res['result'];
          this.serviceDetailsDataFormat(this.updateDetailsData);
          resolve(true);
        }).catch((err) => {
          this.showToasterMessage(this.aemLabelAuthoring.form.errorCommentMsg, '', this.options, 'error');
          reject(false);
        });
      });
    // }
  }

  onClosureTicketSubmit() {
    if (!this.closeTicketForm.valid) {
      this.formSubmitted = true;
      this.sharedService.focusOnElement('popup-error-msg-id');
    } else {
      this.formSubmitted = false;
      this.closeTicketForm.get('closure').setValue((<HTMLInputElement>document.getElementById('closure')).value.trim());
      const enquiryValues = this.closeTicketForm.value;
      this.closeTicketForm.reset();
      this.isModalOpen = false;
      enquiryValues.closure = `||Author:${this.primaryName}||\n` + enquiryValues.closure;
      let payload;

      if (this.fileuploadObj.attachmentObj.length !== 0) {
        payload = {
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "3",
          "notes": enquiryValues.closure,
          "isAttachmentPresent":"true"
        };
      } else {
        payload = {
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "3",
          "notes": enquiryValues.closure,
          "isAttachmentPresent":"false"
        };
      }
      
      return new Promise((resolve, reject) => {
        this.ticketSummaryService.updateNotes(payload, this.serviceDetails.sysTicketId).then((res) => {
          this.updateDetailsData = res['result'];
          this.sharedService.focusOnElement('closeEnquiryBtn');
          this.showToasterMessage(this.aemLabelAuthoring.form.successCloseMsg, '', this.options, 'success');
          this.serviceDetailsDataFormat(this.updateDetailsData);
          resolve(true);
        }).catch(() => {
          this.sharedService.focusOnElement('closeEnquiryBtn');
          this.showToasterMessage(this.aemLabelAuthoring.form.errorMsg, '', this.options, 'error');
          reject(false);
        });
      });
    }
  }

  onDisagreeSubmit() {
    if (!this.reopenForm.valid) {
      this.disagreeFormSubmitted = true;
      this.sharedService.focusOnElement('disagree-error-msg-id');
    } else {
      this.disagreeFormSubmitted = false;
      this.reopenForm.get('disagree').setValue((<HTMLInputElement>document.getElementById('disagree')).value.trim());
      const enquiryValues = this.reopenForm.value;
      this.reopenForm.reset();
      this.isDisagreeModalOpen = false;
      enquiryValues.disagree = `||Author:${this.primaryName}||\n` + enquiryValues.disagree;
      let payload;
      if (this.fileuploadObj.attachmentObj.length !== 0) {
        payload = {
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "10",
          "notes": enquiryValues.disagree,
          "isAttachmentPresent":"true"
        };
      } else {
        payload = {
          "secContactName": "",
          "secContactNumber": "",
          "secContactEmail": "",
          "state": "10",
          "notes": enquiryValues.disagree,
          "isAttachmentPresent":"false"
        };
      }
      
      return new Promise((resolve, reject) => {
        this.ticketSummaryService.updateNotes(payload, this.serviceDetails.sysTicketId).then((res) => {
          this.updateDetailsData = res['result'];
          this.showToasterMessage(this.aemLabelAuthoring.form.successReopenMsg, '', this.options, 'success');
          this.serviceDetailsDataFormat(this.updateDetailsData);
          this.sharedService.focusOnElement('disagreeBtn');
          resolve(true);
        }).catch(() => {
          this.showToasterMessage(this.aemLabelAuthoring.form.errorMsg, '', this.options, 'error');
          this.sharedService.focusOnElement('disagreeBtn');
          reject(false);
        });
      });
    }
  }
  contactSubmit() {
    this.EditContactName = false;
    this.EditContactEmail = false;
    this.EditContactNumber = false;
    this.disablecancel = true;
    // this.contactForm.get('disagree').setValue((<HTMLInputElement>document.getElementById('disagree')).value.trim());
    const enquiryValues = this.contactForm.value;
    this.contactForm.reset();
    let payload;
    if (this.fileuploadObj.attachmentObj.length !== 0) {
      payload = {
        "secContactName": enquiryValues.contactName,
        "secContactNumber": enquiryValues.contactMobile,
        "secContactEmail": enquiryValues.contactEmail,
        "state": '',
        "notes": '',
        "isAttachmentPresent":"true"
      };
    } else {
      payload = {
        "secContactName": enquiryValues.contactName,
        "secContactNumber": enquiryValues.contactMobile,
        "secContactEmail": enquiryValues.contactEmail,
        "state": '',
        "notes": '',
        "isAttachmentPresent":"false"
      };
    }
    
    return new Promise((resolve, reject) => {
      this.ticketSummaryService.updateNotes(payload, this.serviceDetails.sysTicketId).then((res) => {
        this.updateDetailsData = res['result'];
        this.showToasterMessage(this.aemLabelAuthoring.form.successAlternateMsg, '', this.options, 'success');
        this.serviceDetailsDataFormat(this.updateDetailsData);
        resolve(true);
      }).catch(() => {
        this.showToasterMessage(this.aemLabelAuthoring.form.errorMsg, '', this.options, 'error');
        reject(false);
      });
    });
  }

  myTickets() {
    this.router.navigate(['tickets/mytickets']);
  }
  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'ticket-custom';
    this.options.disableTimeOut = false;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.options.enableHtml = true;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  tabClick(index: any) {
    const TEXT_LABEL = index.tab.textLabel;
    switch (TEXT_LABEL) {
      case 'History':
        this.tabName = 'history';
        break;
      case 'Contacts':
        this.tabName = 'contacts';
        break;
    }
  }

  changeEvent() {
    this.showDetails = !this.showDetails
  }
  
  closeTicket() {
    this.isModalOpen = true;
    this.focusOnElement('closeEnquiry');
    this.sharedService.trapFocusInModal('closeEnquiry', 'submit-btn');
  }

  closeModal() {
    this.isModalOpen = false;
    this.sharedService.focusOnElement('closeEnquiryBtn');
  }
  disagreeCloseTicket() {
    this.isDisagreeModalOpen = true;
    this.focusOnElement('closeDiclaimer');
    this.sharedService.trapFocusInModal('closeDiclaimer', 'disagreesubmit-btn');
  }
  disagreeCloseModal() {
    this.isDisagreeModalOpen = false;
    this.sharedService.focusOnElement('disagreeBtn');
  }
  focusOnElement(id: string) {
    setTimeout(() => {
      const focusableElement = document.getElementById(id);
      focusableElement.focus();
    }, 10);
  }

  // ngOnDestroy(){
  //   this.toastr.show('');
  // }
}