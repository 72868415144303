<div class="page-bg">
<div class="container-fluid page-container modify-user" *ngIf="aemLabelAuthoring">
    <h1 class="page-header" id="modifyUserhead">{{aemLabelAuthoring.pageHeader.modifypageHeaderLabel1}} {{createUserData.name.givenName}}
        {{createUserData.name.familyName}}'s {{aemLabelAuthoring.pageHeader.modifypageHeaderLabel2}}</h1>
        <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
        <div class="wrapper">
        <h2 class="create-myuser-header" automationType="label" automationId="lblChoosePlan">
            {{aemLabelAuthoring.pageHeader.pageSubHeaderLabel}}
        </h2>
        <div class="alert-existing-user" *ngIf="existingUserAlert && createUserData.page === 'Create'" role="alert">
            {{aemLabelAuthoring.form.existingUser}} 
            <!-- <img src="/assets/images/close-btn.png" (click)="closeNotification()"> -->
            <!-- <button type="button" class="close closebtn" aria-label="Close" (click)="closeNotification()">
                <i class="icon-icon-ui-cross icon-xs closeicon"></i>
            </button> -->
        </div>
        <form [formGroup]="modifyUserForm" id="modifyUser" #createUserTicket="ngForm">
            <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
            <div class="form-group">
                <div class="row">
                    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 userNameWidth">
                        <app-cui-textfield [(model)]="userNameModel" [parentFormGroup]="modifyUserForm">
                        </app-cui-textfield>
                    </div>
                </div>
                <div class="row ml-0 pl-0 col-xs-12 col-sm-8 col-md-8 col-lg-8" aria-live="assertive">
                    <span role="alert" *ngIf="domainErrorMessage"
                        class="help-block errorMsg">{{domainErrorMessage}}</span>
                </div>
                <div class="row modify-user-details">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <app-cui-textfield [ngClass]="{'disabledropDown': firstNameModel.isDisabled}"
                            [(model)]="firstNameModel" [parentFormGroup]="modifyUserForm">
                        </app-cui-textfield>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <app-cui-textfield [(model)]="lastNameModel" [parentFormGroup]="modifyUserForm">
                        </app-cui-textfield>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <app-cui-textfield [(model)]="phoneModel" [parentFormGroup]="modifyUserForm"
                            (blurred)="onBlurEvent()">
                        </app-cui-textfield>
                    </div>
                </div>
                <div aria-live="polite" role="alert" *ngIf="flag">
                    <p class="warning-msg">
                        <i aria-hidden="true" class="warning-icon"></i>{{aemLabelAuthoring.form.phoneNumberAlert}}</p>
                </div>                
                <table class="table" *ngIf="rowData.length > 0">
                    <caption class="sr-only">User role details</caption>
                    <tr><th *ngFor="let col of tableCol">{{col}}</th></tr>
                    <tr *ngFor="let row of rowData; let i = index">
                        <td> {{row.business}} </td>
                        <!-- <td> {{row.app}} </td> -->
                        <td> {{row.role}} </td>
                        <td> {{row.amsRole}} </td>
                        <td> <button type="button" class="secondary-btn xl-btn p-0 m-0 remove-btn" (click)="removeRow(i)">
                                <span class="icon-icon-ui-cross" aria-hidden="true"></span>{{row.button}}
                            </button> </td>
                    </tr>
                </table>                
                <div *ngIf="isVisible" class="addRole">
                    <button type="button" id="addrole" class="secondary-btn addroleBtn m-0 p-0" (click)="addRole()">
                        <app-cui-svg-icon aria-hidden="true" [icon]="'icon-circlePlus'" class="svg-icon">
                        </app-cui-svg-icon>{{aemLabelAuthoring.form.addRole}}
                    </button>
                </div>
                <div class="row borderBox" *ngIf="!isVisible">
                    <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4">
                        <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblbusinessName"
                            id="businessNameHeader" attr.aria-label={{aemLabelAuthoring.form.businessType}}>
                            {{aemLabelAuthoring.form.businessType}}
                        </label>
                        <app-cui-searchable-dropdown #businessTypes name="businessTypes"
                            [formControlName]="'businessTypes'" [model]="businessNameDropDownModel"
                            [labelName]="aemLabelAuthoring.form.businessType"
                            [placeholder]="aemLabelAuthoring.form.businessPlaceHolder" [isRequired]=true
                            [items]=businessNames automationType="dropdown" automationId="drpdwnbusinessType"
                            [noAutoComplete]=false id="businessTypes" class="user-dropDown"
                            (ngModelChange)='businessChange($event)'
                            [ngClass]="{'invalid-form-control': (modifyUserForm.controls.businessTypes?.invalid && modifyUserForm.controls.businessTypes?.touched)}"
                            required>
                        </app-cui-searchable-dropdown>
                        <div class="row ml-0" aria-live="assertive">
                            <span role="alert"
                                *ngIf="modifyUserForm.controls.businessTypes?.invalid && modifyUserForm.controls.businessTypes?.touched"
                                class="help-block errorMsg">
                                {{businessNameRequiredMsg}}
                            </span>
                        </div>
                        <span class="asterisk_input"> </span>
                    </div>
                    <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <label class="usertypeLbl" automationType="label" automationId="lblusertype" id="appNameHeader"
                            attr.aria-label={{aemLabelAuthoring.form.userType}}>
                            {{aemLabelAuthoring.form.userType}}
                        </label>
                        <app-cui-searchable-dropdown name="AppName" [formControlName]="'AppName'"
                            [model]="appNameDropDownModel" [labelName]="aemLabelAuthoring.form.userType"
                            [placeholder]="aemLabelAuthoring.form.userTypePlaceholder" [isRequired]=true
                            [items]=AppNameOptions automationType="dropdown" automationId="drpdwnAppName"
                            [noAutoComplete]=true id="appName" class="user-dropDown"
                            (ngModelChange)='userSelect($event)'
                            [ngClass]="{'invalid-form-control': (modifyUserForm.controls.AppName?.invalid && modifyUserForm.controls.AppName?.touched)}"
                            required>
                        </app-cui-searchable-dropdown>
                        <div class="row ml-0" aria-live="assertive">
                            <span role="alert"
                                *ngIf="modifyUserForm.controls.AppName?.invalid && modifyUserForm.controls.AppName?.touched"
                                class="help-block errorMsg">
                                {{userTypeMessage}}
                            </span>
                        </div>
                        <span class="asterisk_input"> </span>
                    </div> -->
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                        <label class="usertypeLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="roleHeader"
                            attr.aria-label={{aemLabelAuthoring.form.role}}>
                            {{aemLabelAuthoring.form.role}}
                        </label>
                        <app-cui-searchable-dropdown name="userRole" [formControlName]="'userRole'"
                            [model]="roleDropDownModel" [labelName]="aemLabelAuthoring.form.role"
                            [placeholder]="aemLabelAuthoring.form.rolePlaceholder" [isRequired]=true [items]=roleOptions
                            automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false 
                            class="user-dropDown"
                            (ngModelChange)='roleChange($event)'
                            [ngClass]="{'invalid-form-control': (modifyUserForm.controls.userRole?.invalid && modifyUserForm.controls.userRole?.touched)}"
                            required>
                        </app-cui-searchable-dropdown>
                        <div class="row ml-0" aria-live="assertive">
                            <span role="alert"
                                *ngIf="modifyUserForm.controls.userRole?.invalid && modifyUserForm.controls.userRole?.touched"
                                class="help-block errorMsg">
                                {{roleRequiredMsg}}
                            </span>
                        </div>
                        <span class="asterisk_input"> </span>
                    </div>
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                        <label class="statusLbl checkLbl asteriskdropdown" automationType="label" attr.aria-label="AMS Role" for="dropdownListId">
                          AMS Role
                        </label>
                        <ngx-select [items]="AMSRoleList" [aria-haspopup]="listbox" [optionValueField]="rolesLabel" #selectg 
                           [keepSelectedItems]="false" role="combobox" id="dropdownListId"
                          aria-label="label for the select the AMS Role"   [aria-expanded]="false"
                          [aria-controls]="'dropdownListId'" attr.aria-label="Role"
                          tabIndex="0" [formControlName]="'AMSRole'" [label]="'Role'" placeholder="Select AMS Roles"
                          [formControlName]="AMSRole" [multiple]=" true" (selectionChanges)="selectionChanged($event)"></ngx-select>
                          <div class="row ml-0" aria-live="assertive">
                            <span role="alert"
                                *ngIf="modifyUserForm.controls.AMSRole?.invalid && modifyUserForm.controls.AMSRole?.touched"
                                class="help-block errorMsg">
                                {{AMSroleRequiredMsg}}
                            </span>
                        </div>
                        <span class="asterisk_input"> </span>

                      </div>
                    <div class="button-container myuser-button-container col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <button type="button" class="red-primary-btn xl-btn create-mobile-view-submit mr-3"
                            (click)="onSave()">{{aemLabelAuthoring.form.save}}
                        </button>
                        <button type="button" class="secondary-btn xl-btn addroleCancel"
                            (click)="cancelRole()">{{aemLabelAuthoring.form.cancel}}
                        </button>
                    </div>
                </div>
                <div>
                    <div class="button-container myuser-button-container">
                        <button type="button" class="red-primary-btn xl-btn mr-3"
                            (click)="onSubmit(createUserTicket)">{{aemLabelAuthoring.form.submit}}</button>
                        <button type="button" class="red-secondary-btn xl-btn"
                            (click)="cancelCreate()">{{aemLabelAuthoring.form.cancel}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>