import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { CommonModule,DatePipe } from '@angular/common';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { 
  SpinnerModule, TwCoreUIFormModule
   } from 'tw-core-ui';
import { GridFilterModule } from "../grid-filter/grid-filter.module";
import { AgGridModule } from 'ag-grid-angular';

import { CompanyNotificationsRoutingModule } from './company-notifications-routing.module';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { AddPreferenceComponent } from './add-preference/add-preference.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
import { CompanyNotificationService } from "./company-notification.service";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertBoxAdminNotificationPrefComponent } from './alert-box-admin-notification-pref/alert-box-admin-notification-pref.component';
import { NgbDropdownModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomersModule } from '../customers/customers.module';

@NgModule({
  declarations: [NotificationPreferencesComponent, AddPreferenceComponent, AlertBoxAdminNotificationPrefComponent],
  entryComponents:[AlertBoxAdminNotificationPrefComponent],
  imports: [
    NgbDropdownModule,
    NgbPopoverModule,
    CommonModule,  
    CompanyNotificationsRoutingModule,
    CustomersModule,
    TwCoreUIFormModule,
    NgbModule,
    NgxSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    AgGridModule.withComponents([
      CustomTextFilterComponent,
      CheckboxFilterComponent,
      CustomDateFilterComponent
    ]),
    GridFilterModule,
    SpinnerModule
  ],
  providers: [CompanyNotificationService, DatePipe, ToastrService],
  exports:[NotificationPreferencesComponent], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CompanyNotificationsModule { }
