<div class="page-bg">
    <div class="row content-row">
    </div>
      <div class="row content-row">
        <div class="col-sm-7 col-md-7 col-lg-7 popupMessage">
        <div>
          <p>{{'disclaimer1'|translate}}
            <span><a class="footer-nav-links" id="ackPopup" 
              href="https://www.amplitel.com.au/terms-use" rel="noopener noreferrer" target="_blank" 
               role="link">terms of use</a> </span>
            maintained on our  
            <span><a class="footer-nav-links" href="https://www.amplitel.com.au" rel="noopener noreferrer" target="_blank" 
               role="link">website.</a></span>
          </p>
          <p>{{'disclaimer2'|translate}}
          </p>
          <p>{{'disclaimer3'|translate}}
            <span><a class="footer-nav-links" href="https://www.amplitel.com.au/terms-use" rel="noopener noreferrer" target="_blank" 
               role="link">terms of use</a> </span>
            and agree to be bound by them.
          </p>
        </div>
        <div class="d-flex align-items-baseline">
          <button type="button" id="submit-btn" 
            tabindex="0"
            (click)="onAcknowledge()"
            class="red-primary-btn acknowledge-submit-btn">
            {{'Acknowledge'|translate}}</button>

          <button tabindex="0" id="cancelDisclaimer" class="red-primary-btn cancelButton"
          (click)="cancel()" 
          (keydown.Enter)="cancelTab()"
          role="button">{{'Cancel'|translate}}</button>
        </div>            
        </div>
    </div>
</div>