import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enquiry-error',
  templateUrl: './enquiry-error.component.html',
  styleUrls: ['./enquiry-error.component.less']
})
export class EnquiryErrorComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Amplitel Support Error');
  }

  ngOnInit(): void {
  }

}
