<div class="page-bg">
<div class="container-fluid page-container top-alignment customerList">
    <h1 class="page-header" id="customerList">Customers</h1>
    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <div  class="grid-container" *ngIf="gridConfig && gridInitialized;else spinner">
    <div class="downloadSection" (click)="download()" *ngIf = "rowData.length > 0">      
     
      <button type="button" id="download_icon" class="secondary-btn download xl-btn p-0 m-0 download">
        <!-- <div  class="twssp-sprite svg-icon sprite-m icon-download"> </div>  -->
        <app-cui-svg-icon [icon]="'icon-download'" class="svg-icon"></app-cui-svg-icon>
        Download</button>
    </div>
    <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)" (buttonClicked)="navigateToCreateUser()"
                      (filtersOnChanged)="onFilterSelected($event)">
    </app-cui-ag-grid>
  </div>
  <ng-template #spinner>
    <div class="grid-container" *ngIf="!gridInitialized">
      <div class="spin-container">
          <app-spinner-svg></app-spinner-svg>      
      </div>
    </div>
  </ng-template>
</div>
</div>