import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-worklist-status-renderer',
  templateUrl: './worklist-status-renderer.component.html',
  styleUrls: ['./worklist-status-renderer.component.less']
})
export class WorklistStatusRendererComponent {

  @Input() public status: any;

  agInit(params: any): void {
      this.status = params.value;
  }

}
