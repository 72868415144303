import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enquiry-success',
  templateUrl: './enquiry-success.component.html',
  styleUrls: ['./enquiry-success.component.less']
})
export class EnquirySuccessComponent implements OnInit {

  constructor(private titleService: Title) { 
    this.titleService.setTitle('Amplitel Support Success');
  }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
