import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  copyRight:string;
 
  constructor(private translate:TranslateService) { }
 
  ngOnInit(): void {
    this.translate.get('copyRight').subscribe(res=>{
      this.copyRight = res.replace('{0}',new Date().getFullYear());
    })
  }
}
