import { CheckEntity } from './checkbox-filter.model';
import { Component, ViewChild, ViewContainerRef, ElementRef, HostListener } from '@angular/core';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import { TwCoreUIUtilService } from 'tw-core-ui';
@Component({
    selector: 'app-custom-checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    styleUrls: ['./checkbox-filter.component.less']
})

export class CheckboxFilterComponent implements IFilterAngularComp {
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    public text: String = '';
    public selectedFilters: String = '';
    public customFilterObj;
    filtersArray: Array<CheckEntity> = [];


    public elementRef;

    @ViewChild('input', {read: ViewContainerRef}) public input;
    categories = {};

    @HostListener('document:click', ['$event'])
    clickout(event) {
      // Satish: this is incorrectly handled
      this.retainCheckBoxFilterValues(event);
    }

    constructor(
      myElement: ElementRef,
      private util: TwCoreUIUtilService,
      private viewContainerRef: ViewContainerRef) {
      this.elementRef = myElement;
    }

    agInit(params: IFilterParams): void {
        this.params = params;
        this.customFilterObj = params.colDef;
        this.filtersArray = this.params.colDef['customFilter'];
        this.valueGetter = params.valueGetter;
      }


    isFilterActive(): boolean {
        return this.text !== null && this.text !== undefined && this.text !== '';
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return this.text.toLowerCase()
            .split('^')
            .some((filterWord) => {
                return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
            });
    }

    selectCheckbox() {
      let disablebutton = true;
      this.filtersArray.forEach(el => {
        if (true === el.Checked) {
          disablebutton = false;
        }
      });
      (<HTMLInputElement> document.getElementById('btnApply')).disabled = disablebutton;
    }

    onApplyFilters() {
      let focusableElement;
      focusableElement=document.getElementById('download_icon');
      if(focusableElement){
        focusableElement.focus();
      }
     
      this.text = '';
      this.filtersArray.forEach(el => {
        if (true === el.Checked) {
          this.text += el.elementData + '^';
        }
      });
      this.text = this.text.substring(0, this.text.length - 1);
      this.params.filterChangedCallback();
      document.getElementById('qa-banner').click();
      this.viewContainerRef
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainerRef.element.nativeElement);
    }

    getModel(): any {
        return {value: this.text};
    }

    setModel(model: any): void {
        this.text = model ? model.value : '';
    }

  closeFilter(event) {
    // TODO: use agGrid custom filter callback method instead of below hack
    if (event === 'keypress') {
      this.closedByKeyPress();
    } else {
      this.clearCheckboxFilter(event);
    }
    document.getElementById('qa-banner').click();
    this.viewContainerRef
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainerRef.element.nativeElement);
  }

  clearCheckboxFilter(event) {
    this.selectedFilters = '';
    this.filtersArray.forEach(el => {
      if (true === el.Checked) {
        this.selectedFilters += el.elementData + '^';
      }
    });
    this.selectedFilters = this.selectedFilters.substring(0, this.selectedFilters.length - 1);
    if (this.text !== this.selectedFilters) {
      this.filtersArray = this.returnJsonSelected(this.params.colDef['customFilter']);
    }
  }

  closedByKeyPress() {
      const query = this.customFilterObj.colId;
      const elementToFocus = (<HTMLElement>document.querySelector(`div[col-id=${query}] span.ag-icon.ag-icon-menu`));
      elementToFocus.focus();
  }

  returnJsonSelected (data: any) {
    const uniqueJSON = [];
    for ( let i = 0; i < data.length; i++) {
        if (this.text.search( data[i]['elementData']) === -1 ) {
          uniqueJSON.push({'elementData': data[i]['elementData'], 'Checked' : false});
        } else {
          uniqueJSON.push({'elementData': data[i]['elementData'], 'Checked' : true});
        }
    }
    return uniqueJSON;
  }
  retainCheckBoxFilterValues(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
        if (clickedComponent === this.elementRef.nativeElement) {
            inside = true;
        }
        clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.clearCheckboxFilter(event);
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // this.util.bringFocusToElement(window.event, 'focusableTypeFilter', false);
  }
}
