<div class="page-bg">
  <div class="container-fluid page-container top-alignment customerList" *ngIf="aemLabelAuthoring">
      <h1 class="page-header" id="Billing">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
      <div  aria-live="polite" role="alert"><span class="toastrContainer" toastContainer></span></div>
      <div class="row searchBox" *ngIf="roles.includes('amplitelbilladmin')">
        <label class="billing-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
        <form [formGroup]="searchForm" id="userList" #userList="ngForm" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <div class="row form-group">
                <div class="col-xs-12 col-sm-9 col-md-7 col-lg-4">
                  <label class="checkLbl asteriskdropdown" automationType="label" automationId="lblbusinessName" id="businessNameHeader"
                    attr.aria-label={{aemLabelAuthoring.form.searchBox.header}}>
                    {{aemLabelAuthoring.form.searchBox.header}}
                  </label>
                  <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'" [model]="businessNameDropDownModel"
                    [labelName]="aemLabelAuthoring.form.searchBox.header" [placeholder]="aemLabelAuthoring.form.searchBox.searchPlaceholder"
                    [isRequired]=true [items]=businessNames automationType="dropdown" automationId="drpdwnbusinessType"
                    [noAutoComplete]=false id="businessTypes" class="user-dropDown" required>
                  </app-cui-searchable-dropdown>
                  <span class="asterisk_input"> </span>
                </div>
              <div class="col-xs-12 col-sm-3 col-md-5 col-lg-8">
                <button type="button" [disabled]="!isSelected" class="red-primary-btn xl-btn cancel-xl"
                  (click)="searchBusinessData(userList)">{{aemLabelAuthoring.form.searchBox.search}}
                </button>
                <button type="button" [disabled]="!isSelected" class="secondary-btn reset-search1" (click)="resetSearch()">
                  {{aemLabelAuthoring.form.searchBox.reset}}
                </button>
              </div>            
          </div>
        </form>
      </div>
    </div>
    <div class="container-fluid page-container top-alignment user-list" *ngIf="aemLabelAuthoring">
      <div class="grid-container listUsers" *ngIf="gridConfig && gridInitialized;else spinner">
        <app-cui-ag-grid [model]="gridModel" (filtersOnChanged)="onFilterSelected($event)">
        </app-cui-ag-grid>
      </div>
      <ng-template #spinner>
        <div class="grid-container" *ngIf="(!gridInitialized && isSearched)">
          <div class="spin-container spinner-overlay">
           <app-spinner-svg></app-spinner-svg>
          </div>
        </div>
      </ng-template>
    </div>
</div>
  <!-- (selectedOnChanged)="onSelectionChanged($event)" searchDropdown-->