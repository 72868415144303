<div class="page-bg">
<div class="container-fluid page-container top-alignment" *ngIf="aemLabelAuthoring">
     <div class="toastrContainer" aria-live="polite" role="alert" toastcontainer></div>
     <!-- <div class="wrapper"> -->
         <!-- <div class="" *ngIf="aemLabelAuthoring"> -->
              <h1 class="page-header" id="CompanyNotification">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
              <div class="notification">
                   <div class="grid-container listUsers">
                         <h2>{{gridData.labelAuthoring.headerPageCount}}</h2>
                         <span>{{aemLabelAuthoring.pageHeader.pageSubHeadingContent}}</span>
                   </div>
                   <label [hidden]="hideDropdown" class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
                   <div class="row" [hidden]="hideDropdown">
                        <div class="col-xs-12 col-sm-9 col-md-5 col-lg-4">
                             <label class="checkLbl" automationType="label" automationId="lblrole" role="tabpanel"
                                  id="businessNameHeader" [attr.aria-label]="aemLabelAuthoring.form.businessNameModel">
                                  {{aemLabelAuthoring.form.businessNameModel}}
                             </label>
                             <app-cui-searchable-dropdown name="businessName" [model]="businessNameModel"
                                  [labelName]="aemLabelAuthoring.form.businessNameModel"
                                  [(ngModel)]="selectedBusinessName"
                                  [placeholder]="aemLabelAuthoring.form.businessNamePlaceholder"
                                  [items]="businessNameItems" id="businessName"
                                  (ngModelChange)='modelValueChange($event)' class="user-dropDown">
                             </app-cui-searchable-dropdown>
                        </div>
                        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                             <button type="button" class="red-primary-btn xl-btn cancel-xl"
                                  (click)="getGridData()" [disabled]="disableButton">
                                  {{aemLabelAuthoring.form.grid.search}}
                              </button>
                        </div>
                        <div class="col-lg-5 col-md-4"></div>
                   </div>
                   <div class="grid-container-list listUsers" *ngIf="gridInitialized;else spinner">
                        <!-- <div class="downloadSection" (click)="download()" *ngIf="rowData && rowData.length > 0">
                             <div class="twssp-sprite download sprite-m"></div><button type="button"
                                  class="secondary-btn xl-btn p-0 m-0">{{$any(aemLabelAuthoring).form.grid.download}}
                             </button>
                        </div> -->
                        <div>
                             <app-cui-ag-grid [model]="gridData" (buttonClicked)="navigateToAddPreference($event)"
                                  (rowClicked)="navigateToAddPreference($event, 'edit')" (gridOnReady)="gridReady($event)"
                                  (filtersOnChanged)="onFilterSelected($event)">
                             </app-cui-ag-grid>
                        </div>
                   </div>
              </div>
              <ng-template #spinner>
               <div class="grid-container" *ngIf="!gridInitialized && isSearched">
                 <div class="spin-container spinner-overlay">
                  <app-spinner-svg></app-spinner-svg>
                 </div>
               </div>
             </ng-template>
         <!-- </div> -->
    <!-- </div> -->
</div>
</div>
