
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { share } from 'rxjs/operators';
// import { GridData } from '../../../shared/models/common-model';
// import { CreateUserData } from './users.model';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

export interface amsRole{
  amsRole: string,
  amsCidn: string,
}

@Injectable()
export class UsersService {
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private env: any = window['environment'];
  adminRoles = [];
  validUserRoles = [];
  private aemDefaultUri = {
    gridHeader: 'myuser-grid-header',
    gridData: 'users',
    newgridData:'portal-users',
    users: 'users/portal-users',
    gridLabels: 'myuser',
    createUser: 'create-user',
    getUser: 'myuser-data',
    phoneUpdate: 'users/profile/',
    deletePreference: 'customer-notification-preferences'
  };

  public mockData = {
    "status": 200,
    "code": 200,
    "message": "Getting Business name and Role Details successful!",
    "time": "2024-09-12T19:11:39.980+0000",
    "correlationId": "6ec41aab-4fe9-4b20-a457-d8d9d9c7be53",
    "data": {
        "BusinessNameDetails": [
            {
                "Business name": "testCustomer78",
                "CIDN": "8778982341",
                "ABN": 11231111111,
                "MSO_ID": null,
                "companyType":"Vendor"
            },
            {
              "Business name": "OPTUS",
              "CIDN": "1111111400",
              "ABN": 11231111111,
              "MSO_ID": null,
              "companyType":"Vendor,Infrastructure Owner",

            },
            {
                "Business name": "Test-Customer5343423402",
                "CIDN": "4543542302",
                "ABN": null,
                "MSO_ID": null,
                "companyType":"Vendor"
            },
            {
                "Business name": "Test-Customer5343423401",
                "CIDN": "4543542301",
                "ABN": null,
                "MSO_ID": null,
                "companyType":"Asset Owner"
            },
            {
                "Business name": "Test-Customer5343423400",
                "CIDN": "4543542300",
                "ABN": null,
                "MSO_ID": null,
                "companyType":"Asset Owner,Infrastructure Owner"
            },
            {
                "Business name": "Test-Customer10",
                "CIDN": "6759377376",
                "ABN": 32284877661,
                "MSO_ID": "MSO_TEL_34",
                "companyType":"Infrastructure Owner"
            },
        ],
        "UIRoleMapping":{
        "roleMapping" : {
        "Vendor": ["customeragent","secustomer","enquiryplusamsuser","enquiryonlyuser"],
        "Asset Owner": ["customer","secustomer","enquiryplusamsuser","enquiryonlyuser"],
        "Infrastructure Owner": ["ampliteloperation","amplitelother"]
        },
        


        "amsRoleMapping" : {
        "enquiryplusamsuser" : "Project Partner,Field Operative",
        "ampliteloperation": "Billing,Colo Coordinator,Property Management,Property Team,Commercial Team,Management,Commercial Management",
        "amplitelother":"Business Admin,Data Correction,SA Coordinator,MIS,Build Coordinator,Help Desk,Operations"
        }
      },

        "RoleDetails": [
            {
                "Test-Customer10": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer11": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer01": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer06": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "testCustomer78": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer764812717": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer53434234": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "testcust78k": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "testCusto78": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer2": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer5": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer764812710": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer3412343111": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customerbf4321": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer5343423400": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer5343423401": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer6": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer341234cvadfsd": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer764812711": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                },
                "Test-Customer5343423402": {
                    "loggedInUserRole": "ampliteladmin",
                    "hubEntitlement": "ampliteladmin",
                    "adrEntitlement": "ampliteladmin"
                }
            }
        ]
    },
    "request": {
        "params": {}
    }
}

public validateUser = {
  "status": 200,
  "code": 200,
  "message": "Search Users successful",
  "time": "2024-09-17T04:24:59.723+0000",
  "correlationId": "d6cb44ca-d2c3-4ac7-b49a-ac13b98c00f1",
  "data": {
      "wholesale-users": [
          {
              "id": "44b76b42-2969-48aa-b403-5665ff40749f",
              "userName": "manojkumar.surisetti@team.telstra.com",
              "name": {
                  "familyName": "Kumar",
                  "givenName": "Manoj"
              },
              "active": true,
              "emails": [
                  {}
              ],
              "phoneNumbers": [
                  {
                      "value": "1221212132"
                  }
              ],
              "suid": "4eab30f1-7822-456b-9c1f-fbb0e8acc554",
              "roles": [
                  {
                      "value": "customer",
                      "appName": "acr",
                      "cidn": "1111111400",
                      "businessName": "OPTUS",
                      "amsRole": "customeragent",
                  },
                  {
                    "value": "ampliteloperation",
                    "appName": "acr",
                    "cidn": "1111111400",
                    "businessName": "OPTUS",
                    "amsRole": "Billing",
                }
              ]
          }
      ]
  },
  "request": {
      "params": {}
  }
}
  public gridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  public gridLabelAuthoringDataObj: any;
  //GridData;
  public createUserLabelAuthoringDataObj: any;
  // CreateUserData;
  payLoad: any;
  getPayload: any;
  createUserRoleObj: any = [];
  public userDataObj: any = [];
  public createuserFormData: any;
  public existingUserData: any;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.gridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params, userType) {
    this.payLoad = params || {
      'data': {}
    };
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && (!this.payLoad['data'].hasOwnProperty('fromLoggedDate')) && (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
      this.getPayload = '?id=' + this.payLoad['data'].id;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('fromLoggedDate') && (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && (!this.payLoad['data'].hasOwnProperty('fromLoggedDate'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && this.payLoad['data'].hasOwnProperty('fromLoggedDate')) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate + '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('fromLoggedDate') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && (!this.payLoad['data'].hasOwnProperty('id'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?fromLoggedDate=' + this.payLoad['data'].fromLoggedDate + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (!this.payLoad['data'].hasOwnProperty('id') && !this.payLoad['data'].hasOwnProperty('toLoggedDate') && !this.payLoad['data'].hasOwnProperty('fromLoggedDate')) {
      this.getPayload = false;
    }

    if (this.payLoad['data'].hasOwnProperty('roleList')) {
      this.payLoad = '?cidn=' + this.payLoad['data'].roleList.split(':')[0];
    } else {
      this.payLoad = '';
    }
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': tokenValue ? tokenValue : '',

      })
    };
    return new Promise((resolve, reject) => {
      // this.http.get<GridDataInterface>(this.env.apiPointOGW + this.aemDefaultUri.users + this.payLoad, httpOptions)
      this.http.get<GridDataInterface>(this.env.apiPoint + this.aemDefaultUri.users + this.payLoad, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['wholesale-users'];
          resolve(true);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public getWorkListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabels;
        this.http.get<any>(worklistUrl)
          /*   .takeUntil(componentDestroyed(this)) */
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getCreateMyUserModel() {
    return new Promise((resolve, reject) => {
      if (!this.createUserLabelAuthoringDataObj) {
        const createuserUrl = this.env.aemEndPoint + this.aemDefaultUri.createUser;
        this.http.get<any>(createuserUrl)
          /*   .takeUntil(componentDestroyed(this)) */
          .subscribe(
            res => {
              this.createUserLabelAuthoringDataObj = res.data.content;
              resolve(this.createUserLabelAuthoringDataObj);
            },
            err => {
              this.createUserLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.createUserLabelAuthoringDataObj);
      }
    });
  }

  public createUser(params,amsRoleObj:amsRole, roleDetails) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': params.userName,
        'amsRole': amsRoleObj.amsRole,
        'amsCidn': amsRoleObj['amsCidn'],
        'name': {
          'givenName': params.firstName,
          'familyName': params.lastName
        },
        'phone': params.phoneNumber,
        'role': roleDetails
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + this.aemDefaultUri.users, this.payLoad, httpOptions)
      // this.http.post<any>(this.env.apiPointOGW + this.aemDefaultUri.users, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public getUserData(val, page?) {
    this.sendSpinnerFlag.next(true);
    //  this.getValidRoleDetails();
    if (page === 'profile' || page === 'enquiry' || page === 'my-orders') {
      const roles = this.getUserRoles();
      this.payLoad = val.userName ? '/profile/' + val.userName + `?src_screen=${val.src_screen}`: '';
    } else {
      this.payLoad = val.userName ? '/' + val.userName + `?src_screen=${val.src_screen}` : '';
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };

    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          if (err.status === 404) {
            resolve(err);
          } else {
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        });
    });
  }
  public getBusinessNamesMyUser(params) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = params ? '/db/allCustomer' : '/db';
   // this.payLoad = params && params.infraco ? '/db/allCustomer' : '/db';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res.data);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
   getAllBusinessNamesForAdmin(params):Observable<any> {
    this.sendSpinnerFlag.next(true);
    this.payLoad = params ? '/db/allCustomer' : '/db';
   // this.payLoad = params && params.infraco ? '/db/allCustomer' : '/db';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions).pipe(share())
  }

  public getBusinessNames(params) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = params && params.infraco ? '/db/allCustomer' : '/db';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res.data);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getUserRoles() {
    let roles = [];
    let user_roles: any = this.authService.user ? this.authService.user.profile.roles : this.env.roles;
    if (Array.isArray(user_roles)) {
      user_roles.forEach(item => roles.push(item.value));
    } else {
      roles.push(user_roles.value);
    }
    return roles;
  }

  public getValidRoleDetails() {
    if (this.validUserRoles.length === 0) {
      return new Promise((resolve, reject) => {
        this.getAllRoles().then(res => {
          this.adminRoles = res['roles'];
          this.validUserRoles = [];
          const roles = this.getUserRoles();
          roles.forEach((role, index) => {
            if (this.adminRoles.indexOf(role.split(':')[1].toLowerCase()) > -1) {
              this.validUserRoles.push(role);
            }
          });
          resolve(true);
        }).catch((err) => {
          reject(false);
        });
      });
    } else {
      this.validUserRoles = this.validUserRoles;
    }
  }
  public modifyUser(params, userData) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': userData.userName,
        'name':userData.name,
        'amsRole':userData.amsRole.amsRole,
        'amsCidn':userData.amsRole.amsCidn,
        'phone':userData.phoneNumbers.length?userData.phoneNumbers[0].value:'',
        'operations': params
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.gridData + '/' + this.aemDefaultUri.newgridData + '/' + userData.id;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public modifyPhoneNumber(params, userData) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': userData.userName,
        'operations': params
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.phoneUpdate + userData.id;
    // const url = this.env.apiPointOGW + this.aemDefaultUri.phoneUpdate + userData.id;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public deleteUserPreference(params, userData) {
    this.sendSpinnerFlag.next(true);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      }),
      body: {
        'data': {
          'userName': userData.userName,
          'operations': params
        }
      },
    };
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(this.env.apiPoint + this.aemDefaultUri.deletePreference, options)
        .subscribe(res => {
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  // public getAdminRoles() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get<GridHeaderInterface>(this.env.aemEndPoint + 'role-list')
  //       .subscribe(res => {
  //         resolve(res['data']);
  //       }, err => {
  //         reject(err);
  //         return observableThrowError(err || 'Server error');
  //       });
  //   });
  // }
  public getAllRoles() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + 'role-list')
        .subscribe(res => {
          resolve(res['data']);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public getAMSRoles() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + 'AMS-role-list')
        .subscribe(res => {
          resolve(res['data']);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
}
