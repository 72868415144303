<div class="page-bg">
<div class="container-fluid page-container top-alignment searchInfracoAdmin" *ngIf="aemLabelAuthoring" [ngClass]="{'nonInfraco': !infracoAdmin}">
    <h1 class="page-heading" id="myUsers">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
    <span *ngIf="userRole.includes('ampliteladmin')" class="message">{{aemLabelAuthoring.pageHeader.pageSubHeadingContent}}</span>
    <div class="row searchBox" *ngIf="userRole.includes('ampliteladmin')">
      <form [formGroup]="searchForm" id="userList" #userList="ngForm" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="row form-group">
          <div class="col-xs-12 col-sm-11 col-md-9 col-lg-4 searchDropdown">
            <div class="row">
              <div class="col-xs-12 col-sm-11 col-md-9 col-lg-9">
                <label class="checkLbl asteriskdropdown" automationType="label" automationId="lblbusinessName" id="businessNameHeader" role="tabpanel"
                  attr.aria-label={{aemLabelAuthoring.form.searchBox.header}}>
                  {{aemLabelAuthoring.form.searchBox.header}}
                </label>
                <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'" [model]="businessNameDropDownModel"
                  [labelName]="aemLabelAuthoring.form.searchBox.header" [placeholder]="aemLabelAuthoring.form.searchBox.searchPlaceholder"
                  [isRequired]=true [items]=businessNames automationType="dropdown" automationId="drpdwnbusinessType"
                  [noAutoComplete]=false id="businessTypes" class="user-dropDown" required>
                </app-cui-searchable-dropdown>
                <!-- <span class="asterisk_input"> </span> -->
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <button type="button" [disabled]="!isSelected" class="red-primary-btn xl-btn create-mobile-view-cancel cancel-xl"
                (click)="searchBusinessData(userList)">{{aemLabelAuthoring.form.searchBox.search}}
              </button>
            </div>
            </div>
          </div>
          <div class="button-container myuser-button-container col-xs-12 col-sm-11 col-md-12 col-lg-7">
           <div class="row">
           <div class="col-xs-12 col-sm-11 col-md-6 col-lg-6 userSearch">
            <app-cui-textfield 
                [(model)]="userNameModel"
                [parentFormGroup]="searchForm"
                [ngClass]="{'invalidUserName': userNameInvalidMessage}" (keyup)="clearBusinessName($event)">
            </app-cui-textfield>
            <span role="alert" *ngIf="userNameInvalidMessage" class="help-block errorMsg">{{userNameInvalidMessage}}</span>           
          </div>
          <div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 m-0 p-0">
            <button type="button" [disabled]="!isTextSearch" class="red-primary-btn searchbtn1"
              (click)="searchBusinessData(userList)">{{aemLabelAuthoring.form.searchBox.search}}
            </button>
            <button type="button" class="secondary-btn reset-search1" [disabled]="!isReset" (click)="resetSearch()">
                {{aemLabelAuthoring.form.searchBox.reset}}
              </button>
          </div>
          </div>
          </div>
        </div>
      </form>
    </div>
    <div class="row ml-0 pl-0 col-xs-12 col-sm-8 col-md-8 col-lg-8 displayInlineBlock" aria-live="assertive">
      <span role="alert" *ngIf="userNameInvalidMessage" class="help-block errorMsg">User name is invalid.</span>
    </div>
  </div>
  <div class="container-fluid page-container top-alignment user-list" *ngIf="aemLabelAuthoring">
    <div class="grid-container listUsers" *ngIf="gridConfig && gridInitialized;else spinner">
      <!-- <div class="downloadSection" (click)="download()" *ngIf = "rowData.length > 0">
        <div class="twssp-sprite download sprite-m"></div><button type="button" class="secondary-btn xl-btn p-0 m-0">{{$any(aemLabelAuthoring).form.grid.download}}
        </button>
      </div> -->
      <div class="downloadSection" (click)="download()" *ngIf = "rowData.length > 0">       
       <button tabindex="0" id="download_icon" type="button" class="secondary-btn xl-btn p-0 m-0 download"> 
          <!-- <div class="twssp-sprite svg-icon sprite-m icon-download"></div>  -->
          <app-cui-svg-icon [icon]="'icon-download'" class="svg-icon"></app-cui-svg-icon>
          Download</button>
      </div>
      <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)" (buttonClicked)="navigateToCreateUser()"
                       (filtersOnChanged)="onFilterSelected($event)">
      </app-cui-ag-grid>
    </div>
    <ng-template #spinner>
      <div class="grid-container " *ngIf="(!gridInitialized && isSearched)">
        <div class="spin-container spinner-overlay">
         <app-spinner-svg></app-spinner-svg>
        </div>
      </div>
    </ng-template>
  </div>
  </div>