import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-svg',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerSVGComponent implements OnInit {

  constructor() { }

  public counter = 0;
  ngOnInit() {
    setInterval(() => {
      this.counter++;
      if (this.counter % 2 === 0) {
        this.flipSVG();
      } else {
        this.unflipSVG();
      }
    }, 500);
  }
  flipSVG() {
    if ((<HTMLElement>document.querySelector('#red-icon'))) {
      (<HTMLElement>document.querySelector('#red-icon')).style.fill = 'black';
    }
    if ((<HTMLElement>document.querySelector('#black-icon'))) {
      (<HTMLElement>document.querySelector('#black-icon')).style.fill = '#DC031B';
    }
  }
  unflipSVG() {
    if ((<HTMLElement>document.querySelector('#red-icon'))) {
      (<HTMLElement>document.querySelector('#red-icon')).style.fill = '#DC031B';
    }
    if ((<HTMLElement>document.querySelector('#black-icon'))) {
      (<HTMLElement>document.querySelector('#black-icon')).style.fill = 'black';
    }
  }

}
