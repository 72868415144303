import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AccessinfoService } from './accessinfo.service';

@Component({
  selector: 'accessinfo',
  templateUrl: './accessinfo.component.html',
  styleUrls: ['./accessinfo.component.less']
})
export class AccessInfoComponent implements OnInit {
  public aemLabelAuthoring:any;
  modalVisible:boolean=false;
  modalTitle:string="";
  oneCard:boolean=false;
  newCustomer:boolean=false;
  newCustomerLg:boolean=false;

  // siteAccess = "Site <a href='hbh.com'> Access </a> Process"
  // siteAccess = "Site <a href='hbh.com> Access</a> Process";
  constructor(private titleService:Title, private accessinfoService:AccessinfoService) {
    this.titleService.setTitle('Amplitel Site-Access');
   }

  ngOnInit(): void {
    this.accessinfoService.getJSON().subscribe(data => {
      this.aemLabelAuthoring = data;
    });
  }
  resourcesModal(modalTitle){
    this.modalVisible = true;
    this.modalTitle = modalTitle;

    if(modalTitle === 'One Card'){
      this.oneCard = true;
      this.newCustomer = false;
    }
    else if(modalTitle === 'New Customer Induction & Onboarding'){
      this.newCustomer = true;
      this.oneCard = false;
      this.newCustomerLg = true;
    }
  }

  close() {
    this.modalVisible = false;
  }
}
