import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(public router: Router, private commonService: CommonService) { }

  private isSubmitted: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private enquirySubmit: Boolean;
  getEnquirySubmitted(): Observable<Boolean> {
    return this.isSubmitted.asObservable();
  }

  setEnquirySubmitted(submitted: Boolean) {
    this.isSubmitted.next(submitted);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const routVal = route && route.data && route.data.path ? route.data.path : null;
    this.getEnquirySubmitted().subscribe(submitted => this.enquirySubmit = submitted);
    const roles = (this.commonService.getroleDetails()).roleList;
    if ((routVal === 'success' || routVal === 'error') && this.enquirySubmit) {
      return true;
    } else if ((routVal === 'createCustomer') && roles.includes('ampliteladmin')) {
      return true;
    } else if ((routVal === 'customers') && roles.includes('ampliteladmin')) {
      return true;
    } else if ((routVal === 'modifyCustomers') && roles.includes('ampliteladmin')) {
      return true;
    } 
    else if ((routVal === 'createUser') && (roles.includes('ampliteladmin') ||
      roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'myUser') && (roles.includes('ampliteladmin') ||
      roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'modifyUsers') && (roles.includes('ampliteladmin') ||
      roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'companynotifications') && (roles.includes('ampliteladmin') ||
      roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'company-notifications-add-preference') && (roles.includes('ampliteladmin') ||
    roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'company-notifications-edit-preference') && (roles.includes('ampliteladmin') ||
    roles.includes('customeradmin'))) {
      return true;
    } else if ((routVal === 'billing') && (roles.includes('billviewuser') ||
    roles.includes('amplitelbilladmin'))) {
    return true;
    }  
    else {
      this.router.navigateByUrl('/');
      return true;
    }
  }
}
