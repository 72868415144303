import { Component, ViewChild, ViewContainerRef, ElementRef, HostListener} from '@angular/core';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import { TwCoreUIUtilService } from 'tw-core-ui';

@Component({
  selector: 'app-custom-text-filter',
  templateUrl: './custom-text-filter.component.html',
  styleUrls: ['./custom-text-filter.component.less']
})
export class CustomTextFilterComponent implements IFilterAngularComp  {
  private params: IFilterParams;
  public customFilterObj;
  private valueGetter: (rowNode: RowNode) => any;
  public text: String = '';
  public elementRef;
  @ViewChild('filterInput')filterInput;
  @ViewChild('input', {read: ViewContainerRef}) public input;

  @HostListener('document:click', ['$event'])
    clickout(event) {
      // Satish: this is incorrectly handled
      this.retainTextFilterValues(event);
    }

    constructor(
      myElement: ElementRef,
      private util: TwCoreUIUtilService,
      private viewContainerRef: ViewContainerRef) {
      this.elementRef = myElement;
    }
    // Grid calls the below method while initializing the custom filter
    agInit(params: IFilterParams): void {
        this.params = params;
        this.customFilterObj = params.colDef;
        this.valueGetter = params.valueGetter;
    }

    // The grid calls this to know if the filter icon in the header should be shown. Return true to show.
    isFilterActive(): boolean {
        return this.text !== null && this.text !== undefined && this.text !== '';
    }

    // The grid will ask each active filter, in turn, whether each row in the grid
    // passes. If any filter fails, then the row will be excluded from the final set. The method is provided a
    // params object with attributes node (the rodNode the grid creates that wraps the data) and data
    // (the data object that you provided to the grid for that row).
    doesFilterPass(params: IDoesFilterPassParams): boolean {
      return this.text.toLowerCase()
        .split(' ')
        .every((filterWord) => {
            return this.valueGetter(params.node)?.toString().toLowerCase().indexOf(filterWord) >= 0;
      });
    }

    getModel(): any {
      return {value: this.text};
    }

    // Restores the filter state. Called either as a result of user calling
    // OR the floating filter changed (only if using floating filters).
    setModel(model: any): void {
      this.text = model ? model.value : '';
    }

    // Below is the custom method for applying filter to the grid Column
    applyFilter(newValue): void {
      let focusableElement;
      focusableElement=document.getElementById('download_icon');
      if(focusableElement){
        focusableElement.focus();
      }
     
      if (this.text !== newValue) {
          this.text = newValue;
          this.params.filterChangedCallback();
          this.closeFilter('applyFilter');
      }
    }

    // Close filter method calls the optional method of the interface

    closeFilter(flag) {
      this.clearTextFilter(flag);
      this.closedByKeyPress(flag);
      // TODO: use agGrid custom filter callback method instead of below hack
      document.getElementById('qa-banner').click();
      this.viewContainerRef
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainerRef.element.nativeElement);   
    }

    closedByKeyPress(flag) {
      if (flag === 'keypress') {
        const query = this.customFilterObj.colId;
        const element = (<HTMLElement>document.querySelector(`div[col-id=${query}] span.ag-icon.ag-icon-menu`));
        element.focus();
      } 
    }

    clearTextFilter(flag) {
      const filterActive = this.isFilterActive();
      const filterInput = this.filterInput.nativeElement;
      if (filterInput !== null) {
          if (flag !== 'applyFilter' && !filterActive) {
              this.setModel('');
              filterInput.value = '';
          } else if (flag !== 'applyFilter' && filterActive) {
              filterInput.value = String(this.text);
          }
      }
    }

    onPaste(event: ClipboardEvent) {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData ? clipboardData.getData('text') : '';
      this.validateFilter(pastedText);
    }

    validateFilter(data) {
      let flag = true;
      if (data !== '' && data !== null) {
        flag = null;
      }
      (<HTMLInputElement> document.getElementById('applyFilter')).disabled = flag;
    }

    retainTextFilterValues(event) {
      let clickedComponent = event.target;
      let inside = false;
      do {
          if (clickedComponent === this.elementRef.nativeElement) {
              inside = true;
          }
          clickedComponent = clickedComponent.parentNode;
      } while (clickedComponent);
      if (!inside) {
        this.clearTextFilter(null);
      }
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
      this.util.bringFocusToElement(window.event, this.customFilterObj.fullHeaderName, false);
    }
}
