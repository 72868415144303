import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { DatePipe } from '@angular/common';
import { LoadingServiceService } from '../services/loading-service.service';
import { catchError } from 'rxjs/operators';
import { FiletypeRendererUploadsListingComponent } from './filetype-renderer-uploads-listing/filetype-renderer-uploads-listing.component';
import { MyUploadsActionsRendererComponent } from './uploads-action-items-renderer/uploads-action-items-renderer.component';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  createCustomerData: any;
  public gridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  private env: any = window['environment'];
  public createUserLabelAuthoringDataObj: any;
  //CreateCustomerData;
  public modifyUserLabelAuthoringDataObj: any;
  // CreateCustomerData;
  public gridLabelAuthoringDataObj: any;
  public createuserFormData: any;
  getPayload: any;
  payLoad: any;

  private billingUri = {
    bills: 'bills',  // 'customer',
    business: 'users/db',
    //getCreateCustomerModel: 'createCustomer',
    billingGridHeader: 'billing-grid-header',
    billingGridLabel: 'billing-authoring-data',
    billingGridData: 'bills',
    //downloadFile: 'tw-hub/document-management/my-uploads/'
  };

  constructor(private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private loader: LoadingServiceService) { }

    public getGridHeader() {
      const map = {
        FiletypeRendererUploadsListingComponent: FiletypeRendererUploadsListingComponent,
        MyUploadsActionsRendererComponent: MyUploadsActionsRendererComponent
      };

      return new Promise((resolve, reject) => {
        this.sendSpinnerFlag.next(true);
        this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.billingUri.billingGridHeader)
          .subscribe(res => {
            res.headers.forEach((x: any) => {
              if (x.cellRendererFramework) {
                x.cellRendererFramework = map[x.cellRendererFramework];
              }
            });
            this.gridHeaderDataObj = res;
            this.sendSpinnerFlag.next(false);
            resolve(true);
          }, err => {
            reject(err);
            return observableThrowError(err || 'Server error');
          });
      });
    }

    public getGridData(params) {
      this.sendSpinnerFlag.next(true);  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
        })
      };
      const url = this.env.apiPoint + this.billingUri.bills + `/${params.data}`;
      return new Promise<any>((resolve, reject) => {
        this.http.get<any>(url, httpOptions)
          .subscribe(res => {
            this.sendSpinnerFlag.next(false);
            this.gridDataObj = res['listdata'];
            resolve(true);
          }, err => {
            this.gridDataObj = [];
            reject(err);
            //return observableThrowError(err || 'Server error');
          });
      });
    }

    public getBillingListModel() {
      return new Promise((resolve, reject) => {
        if (!this.gridLabelAuthoringDataObj) {
          const worklistUrl = this.env.aemEndPoint + this.billingUri.billingGridLabel;
          this.http.get<any>(worklistUrl)
            .subscribe(
              res => {
                this.gridLabelAuthoringDataObj = res.data.content;
                resolve(this.gridLabelAuthoringDataObj);
              },
              err => {
                this.gridLabelAuthoringDataObj = null;
                reject(err);
                return observableThrowError(err || 'Server error');
              }
            );
        } else {
          resolve(this.gridLabelAuthoringDataObj);
        }
      });
    }

    public downloadFile(filename, cidn) {
      this.sendSpinnerFlag.next(true);
      let fileURL: any = this.handleSpecialChar(filename);

      let url = this.env.apiPoint + this.billingUri.billingGridData;
      //let url = 'https://dev-towerco-billing.apps.np.sdppcf.com';
      if (cidn){
        url += `/${cidn}`+ `/${fileURL}`;
      }      
      return new Promise((resolve, reject) => {
          this.http.get(url,
              {
                headers: new HttpHeaders({
                  'Accept': '*/*',
                  'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
                }),
                observe: 'response',
                responseType: 'blob'
              }
          ).pipe(catchError((error: any): any => {
              reject(error);
              this.sendSpinnerFlag.next(false);
              return observableThrowError(error.json().error || 'Server error');
          })).subscribe((res: object) => {
              if (res) {
                resolve(res);
                this.sendSpinnerFlag.next(false);
              }
          });
      })//.catch(err => {
        //  this.sendSpinnerFlag.next(false);
    //  });
  }

  handleSpecialChar(value): string {
    value = value.replaceAll("'", "''");
    value = encodeURIComponent(value);
    enum Special {
        '(' = '%28',
        ')' = '%29',
        '-' = '%2D',
        '_' = '%5F',
        '*' = '%2A',
        '!' = '%21',
        '~' = '%7E',
        "'" = '%27'
    };
    Object.keys(Special).map(x => {
        value = value.replaceAll(x, Special[x]);
    })
    return value;
  }

}
